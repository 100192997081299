#footer {
  position: fixed !important;
}
footer a span.h6,
footer a span.h6:hover {
  color: white !important;
  font-weight: bold;
  font-size: 14px;
}

.entry-header {
  background: url(https://wp2.cursosdeofimatica.com/wp-content/uploads/2020/08/Barra-Cursos-1919x169.jpg)
    no-repeat;
}

.entry-title h2 {
  margin: 0;
  padding: 0;
  color: white;
  line-height: 72px;
  letter-spacing: -0.4px;
  font-weight: 700;
  font-size: 35px;
}

.widget_stm_recent_posts .cats_w {
  display: none;
}
