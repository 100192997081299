.logo-unit .logo {
  font-family: Montserrat;
  color: #333;
  font-size: 23px;
}
.header_top_bar,
.header_top_bar a,
.header_2_top_bar .header_2_top_bar__inner ul.header-menu li a {
  font-family: Montserrat;
  font-weight: normal;
  font-style: normal;
  color: #aaaaaa;
  font-size: 12px;
}
body.skin_custom_color
  .stm_archive_product_inner_grid_content
  .stm-courses
  li.product.course-col-list
  .product-image
  .onsale,
body.skin_custom_color
  .related.products
  .stm-courses
  li.product.course-col-list
  .product-image
  .onsale,
body.skin_custom_color
  .stm_archive_product_inner_grid_content
  .stm-courses
  li.product
  .product__inner
  .woocommerce-LoopProduct-link
  .onsale,
body.skin_custom_color
  .related.products
  .stm-courses
  li.product
  .product__inner
  .woocommerce-LoopProduct-link
  .onsale,
body.skin_custom_color
  .post_list_main_section_wrapper
  .post_list_meta_unit
  .sticky_post,
body.skin_custom_color .overflowed_content .wpb_column .icon_box,
body.skin_custom_color .stm_countdown_bg,
body.skin_custom_color #searchform-mobile .search-wrapper .search-submit,
body.skin_custom_color .header-menu-mobile .header-menu > li .arrow.active,
body.skin_custom_color .header-menu-mobile .header-menu > li.opened > a,
body.skin_custom_color mark,
body.skin_custom_color
  .woocommerce
  .cart-totals_wrap
  .shipping-calculator-button:hover,
body.skin_custom_color
  .detailed_rating
  .detail_rating_unit
  tr
  td.bar
  .full_bar
  .bar_filler,
body.skin_custom_color .product_status.new,
body.skin_custom_color
  .stm_woo_helpbar
  .woocommerce-product-search
  input[type="submit"],
body.skin_custom_color
  .stm_archive_product_inner_unit
  .stm_archive_product_inner_unit_centered
  .stm_featured_product_price
  .price.price_free,
body.skin_custom_color .sidebar-area .widget:after,
body.skin_custom_color
  .sidebar-area
  .socials_widget_wrapper
  .widget_socials
  li
  .back
  a,
body.skin_custom_color .socials_widget_wrapper .widget_socials li .back a,
body.skin_custom_color .widget_categories ul li a:hover:after,
body.skin_custom_color .event_date_info_table .event_btn .btn-default,
body.skin_custom_color .course_table tr td.stm_badge .badge_unit.quiz,
body.skin_custom_color .page-links span:hover,
body.skin_custom_color .page-links span:after,
body.skin_custom_color .page-links > span:after,
body.skin_custom_color .page-links > span,
body.skin_custom_color .stm_post_unit:after,
body.skin_custom_color .blog_layout_grid .post_list_content_unit:after,
body.skin_custom_color ul.page-numbers > li a.page-numbers:after,
body.skin_custom_color ul.page-numbers > li span.page-numbers:after,
body.skin_custom_color ul.page-numbers > li a.page-numbers:hover,
body.skin_custom_color ul.page-numbers > li span.page-numbers:hover,
body.skin_custom_color ul.page-numbers > li a.page-numbers.current:after,
body.skin_custom_color ul.page-numbers > li span.page-numbers.current:after,
body.skin_custom_color ul.page-numbers > li a.page-numbers.current,
body.skin_custom_color ul.page-numbers > li span.page-numbers.current,
body.skin_custom_color .triangled_colored_separator,
body.skin_custom_color .magic_line,
body.skin_custom_color .navbar-toggle .icon-bar,
body.skin_custom_color .navbar-toggle:hover .icon-bar,
body.skin_custom_color #searchform .search-submit,
body.skin_custom_color
  .header_main_menu_wrapper
  .header-menu
  > li
  > ul.sub-menu:before,
body.skin_custom_color .search-toggler:after,
body.skin_custom_color .modal .popup_title,
body.skin_custom_color .sticky_post,
body.skin_custom_color .btn-carousel-control:after,
.primary_bg_color,
.mbc,
.stm_lms_courses_carousel_wrapper .owl-dots .owl-dot.active,
.stm_lms_courses_carousel__term.active,
body.course_hub .header_default.header_2,
.triangled_colored_separator:before,
.triangled_colored_separator:after,
body.skin_custom_color.udemy .btn-default,
.single_instructor .stm_lms_courses .stm_lms_load_more_courses,
.single_instructor .stm_lms_courses .stm_lms_load_more_courses:hover,
.stm_lms_course_sticky_panel .stm_lms_course_sticky_panel__button .btn,
.stm_lms_course_sticky_panel .stm_lms_course_sticky_panel__button .btn:hover,
body.skin_custom_color.language_center .btn-default {
  background-color: var(--color-principal);
}
.icon_box.stm_icon_box_hover_none {
  border-left-color: var(--color-principal);
}
body.skin_custom_color ul.page-numbers > li a.page-numbers:hover,
body.skin_custom_color ul.page-numbers > li a.page-numbers.current,
body.skin_custom_color ul.page-numbers > li span.page-numbers.current,
body.skin_custom_color .custom-border textarea:active,
body.skin_custom_color .custom-border input[type="text"]:active,
body.skin_custom_color .custom-border input[type="email"]:active,
body.skin_custom_color .custom-border input[type="number"]:active,
body.skin_custom_color .custom-border input[type="password"]:active,
body.skin_custom_color .custom-border input[type="tel"]:active,
body.skin_custom_color .custom-border .form-control:active,
body.skin_custom_color .custom-border textarea:focus,
body.skin_custom_color .custom-border input[type="text"]:focus,
body.skin_custom_color .custom-border input[type="email"]:focus,
body.skin_custom_color .custom-border input[type="number"]:focus,
body.skin_custom_color .custom-border input[type="password"]:focus,
body.skin_custom_color .custom-border input[type="tel"]:focus,
body.skin_custom_color .custom-border .form-control:focus,
body.skin_custom_color .icon-btn:hover .icon_in_btn,
body.skin_custom_color .icon-btn:hover,
body.skin_custom_color .average_rating_unit,
body.skin_custom_color blockquote,
body.skin_custom_color .tp-caption .icon-btn:hover .icon_in_btn,
body.skin_custom_color .tp-caption .icon-btn:hover,
body.skin_custom_color .stm_theme_wpb_video_wrapper .stm_video_preview:after,
body.skin_custom_color .btn-carousel-control,
body.skin_custom_color
  .post_list_main_section_wrapper
  .post_list_meta_unit
  .post_list_comment_num,
body.skin_custom_color .post_list_main_section_wrapper .post_list_meta_unit,
body.skin_custom_color .search-toggler:hover,
body.skin_custom_color .search-toggler,
.stm_lms_courses_carousel_wrapper .owl-dots .owl-dot.active,
.triangled_colored_separator .triangle:before {
  border-color: var(--color-principal);
}
body.skin_custom_color .icon_box .icon i,
body.skin_custom_color .icon-btn:hover .icon_in_btn,
body.skin_custom_color .icon-btn:hover .link-title,
body.skin_custom_color .stats_counter .h1,
body.skin_custom_color .event_date_info .event_date_info_unit .event_labels,
body.skin_custom_color .event-col .event_archive_item .event_location i,
body.skin_custom_color .event-col .event_archive_item .event_start i,
body.skin_custom_color .gallery_terms_list li.active a,
body.skin_custom_color .tp-caption .icon-btn:hover .icon_in_btn,
body.skin_custom_color .teacher_single_product_page > a:hover .title,
body.skin_custom_color .sidebar-area .widget ul li a:hover:after,
body.skin_custom_color div.pp_woocommerce .pp_gallery ul li a:hover,
body.skin_custom_color div.pp_woocommerce .pp_gallery ul li.selected a,
body.skin_custom_color .single_product_after_title .meta-unit i,
body.skin_custom_color .single_product_after_title .meta-unit .value a:hover,
body.skin_custom_color .woocommerce-breadcrumb a:hover,
body.skin_custom_color #footer_copyright .copyright_text a:hover,
body.skin_custom_color .widget_stm_recent_posts .widget_media .cats_w a:hover,
body.skin_custom_color .widget_pages ul.style_2 li a:hover,
body.skin_custom_color .sidebar-area .widget_categories ul li a:hover,
body.skin_custom_color .sidebar-area .widget ul li a:hover,
body.skin_custom_color .widget_categories ul li a:hover,
body.skin_custom_color .stm_product_list_widget li a:hover .title,
body.skin_custom_color .widget_contacts ul li .text a:hover,
body.skin_custom_color .sidebar-area .widget_pages ul.style_1 li a:focus .h6,
body.skin_custom_color .sidebar-area .widget_nav_menu ul.style_1 li a:focus .h6,
body.skin_custom_color .sidebar-area .widget_pages ul.style_1 li a:focus,
body.skin_custom_color .sidebar-area .widget_nav_menu ul.style_1 li a:focus,
body.skin_custom_color .sidebar-area .widget_pages ul.style_1 li a:active .h6,
body.skin_custom_color
  .sidebar-area
  .widget_nav_menu
  ul.style_1
  li
  a:active
  .h6,
body.skin_custom_color .sidebar-area .widget_pages ul.style_1 li a:active,
body.skin_custom_color .sidebar-area .widget_nav_menu ul.style_1 li a:active,
body.skin_custom_color .sidebar-area .widget_pages ul.style_1 li a:hover .h6,
body.skin_custom_color .sidebar-area .widget_nav_menu ul.style_1 li a:hover .h6,
body.skin_custom_color .sidebar-area .widget_pages ul.style_1 li a:hover,
body.skin_custom_color .sidebar-area .widget_nav_menu ul.style_1 li a:hover,
body.skin_custom_color .widget_pages ul.style_1 li a:focus .h6,
body.skin_custom_color .widget_nav_menu ul.style_1 li a:focus .h6,
body.skin_custom_color .widget_pages ul.style_1 li a:focus,
body.skin_custom_color .widget_nav_menu ul.style_1 li a:focus,
body.skin_custom_color .widget_pages ul.style_1 li a:active .h6,
body.skin_custom_color .widget_nav_menu ul.style_1 li a:active .h6,
body.skin_custom_color .widget_pages ul.style_1 li a:active,
body.skin_custom_color .widget_nav_menu ul.style_1 li a:active,
body.skin_custom_color .widget_pages ul.style_1 li a:hover .h6,
body.skin_custom_color .widget_nav_menu ul.style_1 li a:hover .h6,
body.skin_custom_color .widget_pages ul.style_1 li a:hover,
body.skin_custom_color .widget_nav_menu ul.style_1 li a:hover,
body.skin_custom_color .see_more a:after,
body.skin_custom_color .see_more a,
body.skin_custom_color
  .transparent_header_off
  .header_main_menu_wrapper
  ul
  > li
  > ul.sub-menu
  > li
  a:hover,
body.skin_custom_color .stm_breadcrumbs_unit .navxtBreads > span a:hover,
body.skin_custom_color .btn-carousel-control,
body.skin_custom_color
  .post_list_main_section_wrapper
  .post_list_meta_unit
  .post_list_comment_num,
body.skin_custom_color
  .post_list_main_section_wrapper
  .post_list_meta_unit
  .date-m,
body.skin_custom_color
  .post_list_main_section_wrapper
  .post_list_meta_unit
  .date-d,
body.skin_custom_color .stats_counter h1,
body.skin_custom_color .yellow,
body.skin_custom_color ol li a:hover,
body.skin_custom_color ul li a:hover,
body.skin_custom_color .search-toggler,
.primary_color,
.mtc_h:hover,
body.classic_lms .header_top_bar .header_top_bar_socs ul li a:hover,
body.classic_lms .header_top_bar a:hover,
#footer .widget_stm_lms_popular_courses ul li a:hover .meta .h5.title,
body.classic_lms .stm_lms_wishlist_button a:hover i,
.classic_lms .post_list_main_section_wrapper .post_list_item_title:hover,
.stm_lms_courses__single.style_2 .stm_lms_courses__single--title h5:hover {
  color: var(--color-principal);
}
body.skin_custom_color .triangled_colored_separator .triangle,
body.skin_custom_color .magic_line:after {
  border-bottom-color: var(--color-principal);
}
body.rtl-demo
  .stm_testimonials_wrapper_style_2
  .stm_lms_testimonials_single__content:after {
  border-left-color: var(--color-principal);
}
body.skin_custom_color .blog_layout_grid .post_list_meta_unit .sticky_post,
body.skin_custom_color .blog_layout_list .post_list_meta_unit .sticky_post,
body.skin_custom_color .product_status.special,
body.skin_custom_color .view_type_switcher a:hover,
body.skin_custom_color .view_type_switcher a.view_list.active_list,
body.skin_custom_color .view_type_switcher a.view_grid.active_grid,
body.skin_custom_color
  .stm_archive_product_inner_unit
  .stm_archive_product_inner_unit_centered
  .stm_featured_product_price
  .price,
body.skin_custom_color .sidebar-area .widget_text .btn,
body.skin_custom_color
  .stm_product_list_widget.widget_woo_stm_style_2
  li
  a
  .meta
  .stm_featured_product_price
  .price,
body.skin_custom_color .widget_tag_cloud .tagcloud a:hover,
body.skin_custom_color .sidebar-area .widget ul li a:after,
body.skin_custom_color
  .sidebar-area
  .socials_widget_wrapper
  .widget_socials
  li
  a,
body.skin_custom_color .socials_widget_wrapper .widget_socials li a,
body.skin_custom_color .gallery_single_view .gallery_img a:after,
body.skin_custom_color .course_table tr td.stm_badge .badge_unit,
body.skin_custom_color .widget_mailchimp .stm_mailchimp_unit .button,
body.skin_custom_color .textwidget .btn:active,
body.skin_custom_color .textwidget .btn:focus,
body.skin_custom_color .form-submit .submit:active,
body.skin_custom_color .form-submit .submit:focus,
body.skin_custom_color .button:focus,
body.skin_custom_color .button:active,
body.skin_custom_color .btn-default:active,
body.skin_custom_color .btn-default:focus,
body.skin_custom_color .button:hover,
body.skin_custom_color .textwidget .btn:hover,
body.skin_custom_color .form-submit .submit,
body.skin_custom_color .button,
body.skin_custom_color .btn-default,
.btn.btn-default:hover,
.button:hover,
.textwidget .btn:hover,
body.skin_custom_color .short_separator,
body.skin_custom_color div.multiseparator:after,
body.skin_custom_color .widget_pages ul.style_2 li a:hover:after,
body.skin_custom_color.single-product
  .product
  .woocommerce-tabs
  .wc-tabs
  li.active
  a:before,
body.skin_custom_color.woocommerce .sidebar-area .widget .widget_title:after,
body.skin_custom_color.woocommerce
  .sidebar-area
  .widget.widget_price_filter
  .price_slider_wrapper
  .price_slider
  .ui-slider-handle,
body.skin_custom_color.woocommerce
  .sidebar-area
  .widget.widget_price_filter
  .price_slider_wrapper
  .price_slider
  .ui-slider-range,
.sbc,
.sbc_h:hover,
.wpb-js-composer
  .vc_general.vc_tta.vc_tta-tabs.vc_tta-style-classic
  li.vc_tta-tab
  > a,
.wpb-js-composer
  .vc_general.vc_tta.vc_tta-tabs.vc_tta-style-classic
  li.vc_tta-tab
  > a:hover,
#header.transparent_header .header_2 .stm_lms_account_dropdown .dropdown button,
.stm_lms_courses_categories.style_3 .stm_lms_courses_category > a:hover,
.stm_lms_udemy_course .nav.nav-tabs > li a,
body.classic_lms .classic_style .nav.nav-tabs > li.active a,
.header_bottom:after,
.sbc:hover,
body.rtl-demo
  .stm_testimonials_wrapper_style_2
  .stm_lms_testimonials_single__content {
  background-color: var(--color-principal);
}
body.skin_custom_color.woocommerce
  .sidebar-area
  .widget.widget_layered_nav
  ul
  li
  a:after,
body.skin_custom_color.woocommerce
  .sidebar-area
  .widget.widget_product_categories
  ul
  li
  a:after,
body.skin_custom_color .wpb_tabs .form-control:focus,
body.skin_custom_color .wpb_tabs .form-control:active,
body.skin_custom_color
  .woocommerce
  .cart-totals_wrap
  .shipping-calculator-button,
body.skin_custom_color .sidebar-area .widget_text .btn,
body.skin_custom_color .widget_tag_cloud .tagcloud a:hover,
body.skin_custom_color .icon_box.dark a:hover,
body.skin_custom_color .simple-carousel-bullets a.selected,
body.skin_custom_color .stm_sign_up_form .form-control:active,
body.skin_custom_color .stm_sign_up_form .form-control:focus,
body.skin_custom_color .form-submit .submit,
body.skin_custom_color .button,
body.skin_custom_color .btn-default,
.sbrc,
.sbrc_h:hover,
.vc_general.vc_tta.vc_tta-tabs,
body.skin_custom_color .blog_layout_grid .post_list_meta_unit,
body.skin_custom_color
  .blog_layout_grid
  .post_list_meta_unit
  .post_list_comment_num,
body.skin_custom_color
  .blog_layout_list
  .post_list_meta_unit
  .post_list_comment_num,
body.skin_custom_color .blog_layout_list .post_list_meta_unit,
#header.transparent_header
  .header_2
  .stm_lms_account_dropdown
  .dropdown
  button {
  border-color: var(--color-principal);
}
.header_2_top_bar__inner .top_bar_right_part .header_top_bar_socs ul li a:hover,
.secondary_color,
body.skin_custom_color.single-product
  .product
  .woocommerce-tabs
  .wc-tabs
  li.active
  a,
body.skin_custom_color.single-product
  .product
  .woocommerce-tabs
  .wc-tabs
  li
  a:hover,
body.skin_custom_color .widget_pages ul.style_2 li a:hover .h6,
body.skin_custom_color .icon_box .icon_text > h3 > span,
body.skin_custom_color
  .stm_woo_archive_view_type_list
  .stm_featured_product_stock
  i,
body.skin_custom_color
  .stm_woo_archive_view_type_list
  .expert_unit_link:hover
  .expert,
body.skin_custom_color
  .stm_archive_product_inner_unit
  .stm_archive_product_inner_unit_centered
  .stm_featured_product_body
  a
  .title:hover,
body.skin_custom_color
  .stm_product_list_widget.widget_woo_stm_style_2
  li
  a:hover
  .title,
body.skin_custom_color
  .blog_layout_grid
  .post_list_meta_unit
  .post_list_comment_num,
body.skin_custom_color .blog_layout_grid .post_list_meta_unit .date-m,
body.skin_custom_color .blog_layout_grid .post_list_meta_unit .date-d,
body.skin_custom_color
  .blog_layout_list
  .post_list_meta_unit
  .post_list_comment_num,
body.skin_custom_color .blog_layout_list .post_list_meta_unit .date-m,
body.skin_custom_color .blog_layout_list .post_list_meta_unit .date-d,
body.skin_custom_color .widget_stm_recent_posts .widget_media a:hover .h6,
body.skin_custom_color .widget_product_search .woocommerce-product-search:after,
body.skin_custom_color .widget_search .search-form > label:after,
body.skin_custom_color .sidebar-area .widget ul li a,
body.skin_custom_color .sidebar-area .widget_categories ul li a,
body.skin_custom_color .widget_contacts ul li .text a,
body.skin_custom_color .event-col .event_archive_item > a:hover .title,
body.skin_custom_color .stm_contact_row a:hover,
body.skin_custom_color .comments-area .commentmetadata i,
body.skin_custom_color
  .stm_post_info
  .stm_post_details
  .comments_num
  .post_comments:hover,
body.skin_custom_color
  .stm_post_info
  .stm_post_details
  .comments_num
  .post_comments
  i,
body.skin_custom_color
  .stm_post_info
  .stm_post_details
  .post_meta
  li
  a:hover
  span,
body.skin_custom_color .stm_post_info .stm_post_details .post_meta li i,
body.skin_custom_color
  .blog_layout_list
  .post_list_item_tags
  .post_list_divider,
body.skin_custom_color .blog_layout_list .post_list_item_tags a,
body.skin_custom_color .blog_layout_list .post_list_cats .post_list_divider,
body.skin_custom_color .blog_layout_list .post_list_cats a,
body.skin_custom_color .blog_layout_list .post_list_item_title a:hover,
body.skin_custom_color
  .blog_layout_grid
  .post_list_item_tags
  .post_list_divider,
body.skin_custom_color .blog_layout_grid .post_list_item_tags a,
body.skin_custom_color .blog_layout_grid .post_list_cats .post_list_divider,
body.skin_custom_color .blog_layout_grid .post_list_cats a,
body.skin_custom_color .blog_layout_grid .post_list_item_title:focus,
body.skin_custom_color .blog_layout_grid .post_list_item_title:active,
body.skin_custom_color .blog_layout_grid .post_list_item_title:hover,
body.skin_custom_color
  .stm_featured_products_unit
  .stm_featured_product_single_unit
  .stm_featured_product_single_unit_centered
  .stm_featured_product_body
  a
  .title:hover,
body.skin_custom_color .icon_box.dark a:hover,
body.skin_custom_color
  .post_list_main_section_wrapper
  .post_list_item_tags
  .post_list_divider,
body.skin_custom_color .post_list_main_section_wrapper .post_list_item_tags a,
body.skin_custom_color
  .post_list_main_section_wrapper
  .post_list_cats
  .post_list_divider,
body.skin_custom_color .post_list_main_section_wrapper .post_list_cats a,
body.skin_custom_color
  .post_list_main_section_wrapper
  .post_list_item_title:active,
body.skin_custom_color
  .post_list_main_section_wrapper
  .post_list_item_title:focus,
body.skin_custom_color
  .post_list_main_section_wrapper
  .post_list_item_title:hover,
body.skin_custom_color a:hover,
.secondary_color,
#header.transparent_header
  .header_2
  .header_top
  .stm_lms_categories
  .heading_font,
#header.transparent_header .header_2 .header_top .stm_lms_categories i,
.classic_lms .post_list_main_section_wrapper .post_list_cats a,
.classic_lms .post_list_main_section_wrapper .post_list_item_tags a,
body.skin_custom_color
  .single_product_after_title
  .meta-unit.teacher:hover
  .value,
.stm_lms_course_sticky_panel__teacher:before,
.stm_lms_courses__single__inner
  .stm_lms_courses__single--info_title
  a:hover
  h4 {
  color: var(--color-principal);
}
a {
  color: #2d4649;
}
body,
.normal_font,
.h6.normal_font,
body.rtl.rtl-demo
  .stm_testimonials_wrapper_style_2
  .stm_lms_testimonials_single__excerpt
  p,
.stm_product_list_widget.widget_woo_stm_style_2 li a .meta .title {
  font-family: "Open Sans";
  color: #222222;
  font-size: 16px;
}
.btn {
  font-family: "Roboto Slab";
  line-height: 14px;
  font-size: 14px;
}
.header-menu {
  font-family: "Roboto Slab";
  font-weight: 700;
  color: #222222;
}
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.nav-tabs > li > a,
.member-name,
.section-title,
.user-name,
.heading_font,
.item-title,
.acomment-meta,
[type="reset"],
.bp-subnavs,
.activity-header,
table,
.widget_categories ul li a,
.sidebar-area .widget ul li a,
.select2-selection__rendered,
blockquote,
.select2-chosen,
.vc_tta-tabs.vc_tta-tabs-position-top
  .vc_tta-tabs-container
  .vc_tta-tabs-list
  li.vc_tta-tab
  a,
.vc_tta-tabs.vc_tta-tabs-position-left
  .vc_tta-tabs-container
  .vc_tta-tabs-list
  li.vc_tta-tab
  a,
body.distance-learning .btn,
body.distance-learning .vc_btn3 {
  font-family: "Roboto Slab";
  color: #222222;
}
h1,
.h1 {
  line-height: 72px;
  letter-spacing: -0.4px;
  font-weight: 700;
  font-size: 35px;
}
h2,
.h2 {
  line-height: 38px;
  letter-spacing: -0.5px;
  font-weight: 700;
  font-size: 32px;
}
h3,
.h3 {
  line-height: 34px;
  letter-spacing: -1px;
  font-weight: 700;
  font-size: 26px;
}
h4,
.h4,
blockquote {
  line-height: 26px;
  font-weight: 700;
  font-size: 20px;
}
h5,
.h5,
.select2-selection__rendered {
  line-height: 20px;
  font-size: 14px;
}
h6,
.h6,
.widget_pages ul li a,
.widget_nav_menu ul li a,
.footer_menu li a,
.widget_categories ul li a,
.sidebar-area .widget ul li a {
  line-height: 12px;
  font-weight: 400;
  font-size: 12px;
}
#footer_top {
  background-color: var(--color-principal);
}
#footer_bottom {
  background-color: var(--color-principal);
}
#footer_bottom .widget_title h3 {
  font-weight: 700;
  color: #ffffff;
  font-size: 18px;
}
#footer_bottom,
.widget_contacts ul li .text,
.footer_widgets_wrapper .widget ul li a,
.widget_nav_menu ul.style_1 li a .h6,
.widget_pages ul.style_2 li a .h6,
#footer .stm_product_list_widget.widget_woo_stm_style_2 li a .meta .title,
.widget_pages ul.style_1 li a .h6 {
  color: #ffffff;
}
.widget_pages ul.style_2 li a:after {
  background-color: #ffffff;
}
.vc_row {
  margin-bottom: 0px !important;
}

.breadcrumbs_holder {
  margin-bottom: 0px !important;
}

.vc_row-has-fill > .vc_column_container > .vc_column-inner {
  padding-top: 0px !important;
}
#yikes-mailchimp-container-1 h3 {
  color: #015c31;
  text-align: center;
}
#yikes-easy-mc-form-1-EMAIL {
  color: #ffffff;
  border-radius: 5px;
  border-width: 0;
  background-color: #00c57e;
}
.yikes-easy-mc-submit-button,
.EMAIL-label {
  color: #015c31;
  font-weight: bold;
}
.yikes-easy-mc-submit-button {
  border-color: #015c31;
  border-radius: 5px;
}
.yikes-easy-mc-submit-button:hover {
  background-color: #00c57e;
  border-width: 0;
}

.vc_custom_1599160349848 {
  background-color: #ebebeb !important;
  background-position: 0 0 !important;
  background-repeat: no-repeat !important;
}
.vc_custom_1606336620928 {
  padding-top: 44px !important;
  padding-bottom: 15px !important;
  background-color: #ffffff !important;
}
.vc_custom_1606338135953 {
  margin-bottom: 0px !important;
  padding-top: 30px !important;
  padding-bottom: 30px !important;
  background-color: #ebebeb !important;
}
.vc_custom_1599168593152 {
  background-color: #ffffff !important;
}
.vc_custom_1606338282299 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
  background-color: #f1f6fb !important;
}
.vc_custom_1599160529651 {
  margin-bottom: 0px !important;
  padding-top: 0px !important;
}
.vc_custom_1606852709678 {
  margin-bottom: 30px !important;
}

.wpb_animate_when_almost_visible {
  opacity: 1;
}

#rev_slider_10_1_wrapper {
  height: 566.1290322580645px;
}
#rev_slider_11_2_wrapper {
  height: 424.5967741935484px;
}
#rev_slider_12_3_wrapper {
  height: 471.77419354838713px;
}
#rev_slider_13_4_wrapper {
  height: 518.9516129032259px;
}

.fancybox-margin {
  margin-right: 17px;
}

@media (max-width: 1440px) {
  .a253ce49a9d9419793ee9d3b7ffb0706e {
    height: 30pxpx !important;
  }
}

@media (max-width: 1024px) {
  .a253ce49a9d9419793ee9d3b7ffb0706e {
    height: 20pxpx !important;
  }
}

@media (max-width: 768px) {
  .a253ce49a9d9419793ee9d3b7ffb0706e {
    height: 0pxpx !important;
  }
}
