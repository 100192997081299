img.wp-smiley,
img.emoji {
  display: inline !important;
  border: none !important;
  box-shadow: none !important;
  height: 1em !important;
  width: 1em !important;
  margin: 0 0.07em !important;
  vertical-align: -0.1em !important;
  background: none !important;
  padding: 0 !important;
}

.correctly_answered label {
  color: #5cb85c !important;
}

.vue_is_disabled {
  display: none;
}

.yikes-easy-mc-form label.label-inline {
  float: left;
  width: 79%;
  padding-right: 10px;
}

/* Drop the submit button (100% width) below the fields */
@media (max-width: 768px) {
  .yikes-easy-mc-form label.label-inline {
    width: 99%;
    padding-right: 10px;
  }

  .yikes-easy-mc-form .submit-button-inline-label {
    width: 100%;
    float: none;
  }

  .yikes-easy-mc-form .submit-button-inline-label .empty-label {
    display: none;
  }
}

/* Stack all fields @ 100% */
@media (max-width: 650px) {
  .yikes-easy-mc-form label.label-inline {
    width: 100%;
  }
}
