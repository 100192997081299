@import url(https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Abel&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

div#root {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

:root {
  --color-principal: #001c61;
  --color-secundario: #ff3d0f;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto Slab", serif;
}
h1,
h2 {
  font-size: 1.8rem;
}
h3 {
  font-size: 1.4rem;
}

body {
  position: relative;
  z-index: 0;
}

.container {
  width: 1200px;
  max-width: 95vw;
  margin: auto;
}

img.img-fluid {
  width: 100%;
  height: auto;
}

.contact-form {
}
.mainLoader {
  margin: 200px auto;
  width: 100px;
}
.input-group {
  display: flex;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  justify-content: space-between;
  margin-bottom: 30px;
}

.input-group input {
  width: 100%;
  padding: 6px 12px;
  display: block;
  height: 45px;
  line-height: 1.42857143;
  border-radius: 0;
  border: 2px solid #f0f2f5;
  background: #f0f2f5;
  box-shadow: none !important;
  color: #555;
  font-family: "Open Sans", sans-serif;
}

.input-group textarea {
  width: 100%;
  padding: 6px 12px;
  display: block;

  font-family: "Open Sans", sans-serif;
  border-radius: 0;
  border: 2px solid #f0f2f5;
  background: #f0f2f5;
  box-shadow: none !important;
  color: #555;
}

.btn-primary {
  background-color: #001c61;
  background-color: var(--color-principal);
  color: #fff;
  border: none;
  box-shadow: none;
  transition: all 0.25s ease;
  text-decoration: none !important;
  padding: 18px 35px;
  font-family: "Roboto Slab", sans-serif;
  font-size: 1.3rem;
  border-radius: 20px;
  min-width: 135px;
  text-align: center;
  display: inline-block;
  text-transform: uppercase;
}

.btn-primary:hover {
  cursor: pointer;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
}

.tarjeta-curso {
  width: 350px;
  min-height: 350px;
  background: white;
  margin: auto;
  border: 1px solid #eaeaea;
  transition: ease all 0.3s;
}
.tarjeta-curso:hover {
  cursor: pointer;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
}

.tarjeta-curso h2.title {
  color: black;
  font-size: 1.2rem;
  margin: 20px auto;
  padding: 0 10px;
  text-align: center;
}

.tarjeta-curso span {
  display: block;
  text-align: center;
  color: #999;
}

.tarjeta-curso img {
  width: 100%;
}

.img-squared {
  width: 100%;
  height: 100%;
  position: relative;
}
.img-squared img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 2;
}

@media only screen and (max-width: 900px) {
  .container {
    padding: 0 20px;
  }
}

@media only screen and (max-width: 499px) and (min-width: 320px) {
  a.invisible-link div.tarjeta-curso {
    width: 240px;
    min-height: -webkit-max-content;
    min-height: max-content;
  }
}

.mobile-menu {
  display: none;
}

div#root div.container-header {
  padding: 0;
}

nav {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 77px;
  align-items: center;
  justify-content: space-between;
  background: white;
}

nav span {
  display: none;
}

nav a img {
  max-height: 55px;
}

nav span:hover {
  color: var(--color-principal);
}

nav ul {
  display: flex;
  padding: 0;
  margin: 0;
}

nav ul li {
  list-style: none;
  margin: 0;
  font-family: "Roboto Slab", serif;
  font-weight: 700;
  line-height: 40px;
  padding: 0 20px;
}

nav ul li a {
  color: black;
  transition: ease all 0.3s;
}

nav ul li a.active:after {
  content: " ";
  display: block;
  background: var(--color-principal);
  width: 100%;
  height: 3px;
  padding: 0;
}

nav ul li a:hover {
  color: var(--color-principal);
}

nav ul li.nav-btn {
  background: var(--color-principal);
  height: 40px;
  border-radius: 12px;
}

nav ul li.nav-btn a {
  color: white;
}

@media only screen and (max-width: 900px) {
  nav ul {
    display: none;
  }

  nav span {
    display: block;
  }

  .mobile-menu.open {
    display: block;
  }

  .mobile-menu ul {
    display: block;
    border-top: 4px solid #eaeaea;
  }

  .mobile-menu ul li {
    list-style: none;
    padding: 0 20px;
    background: #fafafa;
    font-family: "Roboto Slab", serif;
    font-weight: 700;
    line-height: 50px;
    border-bottom: 1px solid #eaeaea;
  }

  .mobile-menu ul li a {
    color: #666;
    transition: ease all 0.3s;
  }

  .mobile-menu ul li a:hover {
    color: var(--color-principal);
  }

  .mobile-menu ul li:hover a {
    color: var(--color-principal);
  }
}

section.section-title {
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 320px) {
  nav a img {
    max-height: 40px;
  }

  section.section-title {
    height: 90px;
  }
}

.carousel-item {
  width: 100%;
  background-image: url(/static/media/Banner-Princ-Home.1678ecad.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.banner-principal-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.banner-principal-container img {
  width: 30%;
}

.carousel-tag {
  font-size: 2.5rem;
  font-family: "Roboto Slab", serif;
  font-weight: 700;
}

.carousel-tag span.futuro {
  display: block;
  color: var(--color-principal);
}

.carousel-tag span.profesionales {
  font-size: 1rem;
}

@media only screen and (max-width: 900px) {
  .carousel-tag {
    left: 20px;
    bottom: 30%;
    font-size: 1rem;
  }
}

.carousel .slide img {
  object-fit: cover;
}

.cta-home {
  font-family: "Roboto Slab", serif;
  background: var(--color-principal);
  margin: -100px auto 0 auto;
  border-radius: 12px;
  z-index: 1;
  position: relative;
  display: flex;
  padding: 50px 30px;
  color: #171818;
  border-radius: 10px;
}

.cta-home h2 {
  flex: 1 1;
  padding: 0 20px;
  font-size: 1.8rem;
  color: white;
}
.cta-home .countdown-holder {
  flex: 1 1;
  text-align: center;
}

.cta-home .countdown-holder a {
  color: white;
  background: green;
  padding: 20px;
  border-radius: 12px;
  display: inline-block;
  width: 220px;
  text-align: center;
  margin-top: 20px;
  transition: ease all 0.3s;
}

.cta-home .countdown-holder a:hover {
  background: darkgreen;
}

.cta-home .countdown-holder a svg {
  margin-right: 20px;
}

.cta-home .countdown {
  display: flex;
  justify-content: space-around;
  text-align: center;
}
.cta-home .countdown h2 {
  padding: 0;
  color: white;
  font-size: 3rem;
}

.cta-home .countdown span {
  font-weight: 500;
  color: white;
  margin-top: 15px;
}

.container-countdown {
  width: 1200px;
  max-width: 95vw;
  margin: auto;
}

section.first-section {
  margin: 90px auto 0;
  padding: 20px;
  display: flex;
  position: relative;
  align-items: center;
}

section.first-section .content-home {
  width: 60%;
  padding-left: 40px;
}

section.first-section .content-home p,
section.first-section .content-home h3 {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

section.first-section .content-home h3 {
  font-size: 1.5rem;
}

.content-home h3 {
  color: var(--color-principal);
  font-weight: bold;
  margin-bottom: 20px;
}

.content-home h3 span {
  color: red;
  text-decoration: line-through;
}

.first-section .circle:before {
  display: block;
  width: 300px;
  height: 300px;
  background: #c9efff;
  border-radius: 50%;
  content: " ";
  position: absolute;
  z-index: -1;
  left: 100px;
  top: 20px;
}

.first-section .circle {
  flex: 2 1;
  padding: 0 0 0 140px;
  font-family: "Roboto Slab", serif;
  font-size: 1.6rem;
  font-weight: bold;
  width: 40%;
}

.first-section .circle span {
  color: #fff;
}
.first-section .content {
  flex: 3 1;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

.first-section .content p {
  margin: 10px 0;
}

.first-section .content h3 {
  color: green;
}

.first-section .content h3 span {
  color: red;
  text-decoration: line-through;
}

.cta-bg {
  width: 100%;
  height: 420px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: 50px 0;
}

.cta-bg .flex {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: none;
}

.cta-bg .text {
  font-family: "Open Sans", sans-serif;
  text-align: center;
}

.cta-bg .text h3 {
  color: var(--color-principal);
  text-align: center;
  font-weight: 700;
  margin: 20px 0;
  font-size: 2rem;
}

.cta-bg .text p {
  color: var(--color-principal);
  font-weight: 700;
  text-align: center;
  font-size: 1.5rem;
}

.cta-bg .text button {
  color: var(--color-secundario);
  font-weight: bold;
  text-transform: uppercase;
  width: -webkit-max-content;
  width: max-content;
  margin: 20px auto;
  padding: 10px 30px;
  border: 3px solid var(--color-secundario);
  transition: ease all 0.3s;
  border-radius: 30px;
  font-size: 1.3rem;
}

.cta-bg .text button:hover {
  cursor: pointer;
}

.cta-bg .moving-img {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
}

.cta-bg .moving-img img {
  width: 75%;
}

div.tarjeta-home:hover .content-home h3 {
  color: white;
}

div.tarjeta-home .content-home h3 {
  color: var(--color-principal);
}

.porque-cdo .grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 40px;
}
.tarjeta-home {
  background: white;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.14);
  min-height: 300px;
  display: flex;
  border-left: 6px solid var(--color-secundario);
  padding: 30px;
  align-items: flex-start;
  justify-content: space-between;
}

.tarjeta-home:hover {
  background: var(--color-secundario);
  cursor: pointer;
  transition: ease all 0.3s;
}

.tarjeta-home:hover p {
  color: white;
}

.tarjeta-home svg {
  flex: 1 1;
  font-size: 2rem;
  color: var(--color-principal);
  margin-right: 20px;
}

.tarjeta-home:hover svg,
.tarjeta-home:hover h3 {
  color: white;
}
.tarjeta-home .content {
  padding-left: 20px;
  flex: 10 1;
}

.tarjeta-home .content h3 {
  font-family: "Roboto Slab", serif;
  font-weight: 700;
  font-size: 1.6rem;
}

.tarjeta-home .content p {
  text-align: justify;
  margin-top: 20px;
}

.counters {
  width: 100%;
  height: 632px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: 50px 0;
}

.container-items {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  grid-row-gap: 20px;
  row-gap: 20px;
  align-items: center;
  height: 100%;
}

.counter-items {
  height: 280px;
  color: white;
  text-align: center;
}
.counter-items svg {
  margin-top: 20px;
  font-size: 4rem;
}
.counter-items h3 {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 3rem;
  font-family: "Roboto Slab", sans-serif;
  color: #fff;
}
.counter-items h4 {
  font-family: "Roboto Slab", sans-serif;
  font-size: 1.8rem;
  color: white;
}

.docentes-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

.docentes-section h2 {
  font-family: "Roboto Slab", sans-serif;
  font-size: 1.8rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
}
.docente {
  text-align: center;
}
.docente h3 {
  margin: 20px 0;
  font-family: "Roboto Slab", sans-serif;
}
.docente span {
  color: #999;
  display: block;
  margin-bottom: 20px;
}

.docentes-list img {
  width: 100%;
}

.garantia {
  margin-top: 50px;
  background: #1091d5;
  padding-top: 50px;
  color: white;
  height: -webkit-max-content;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.garantia h2 {
  font-size: 3rem;
  font-family: "Roboto Slab", sans-serif;
  color: white;
  margin-bottom: 60px;
}

.garantia ul {
  list-style: none;
  flex: 1 1;
}
.garantia .img-holder {
  width: 30%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.garantia .img-holder img {
  width: 100%;
  margin: 0;
  padding: 0;
}
.garantia ul li {
  margin-top: 50px;
  font-size: 1.3rem;
}

.garantia .container-garantia {
  display: flex;
  flex-direction: row;
  height: 100%;
  grid-gap: 40px;
  gap: 40px;
  padding: 0 40px;
}

.garantia-column-one,
.garantia-column-two {
  padding: 0 10px;
  margin-bottom: 30px;
}

div.countdown div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 426px) and (min-width: 320px) {
  .cta-home {
    flex-direction: column;
    top: 90px;
    height: 210px;
    padding: 20px 15px;
  }

  .cta-home h2 {
    font-size: 1.1rem;
  }

  .cta-home .countdown {
    height: 100%;
    align-items: flex-end;
  }

  .cta-home .countdown h2 {
    font-size: 2rem;
  }

  section.first-section {
    margin-top: 100px;
    flex-direction: column;
    padding-bottom: 0;
  }

  section.first-section .content-home {
    width: 100%;
    padding: 0;
  }

  .first-section .circle {
    padding-left: 0;
    margin: 35px auto 50px;
    width: 100%;
  }

  .first-section .circle:before {
    width: 240px;
    height: 240px;
    right: 50px;
    left: 0;
  }

  section.first-section .content-home p,
  section.first-section .content-home h3 {
    margin-bottom: 15px;
  }

  .cta-bg .moving-img img {
    display: none;
  }

  section.porque-cdo {
    width: 100%;
    padding: 0 10px;
  }

  .porque-cdo .grid {
    grid-template-columns: 1fr;
    padding: 0;
    max-width: 100%;
  }

  div.tarjeta-home .svg-inline--fa.fa-w-20 {
    width: 3rem;
    height: 20%;
    margin: 0 0 20px 0;
  }

  div.content-home {
    height: 80%;
  }

  .tarjeta-home {
    flex-direction: column;
    padding: 20px;
    align-items: center;
  }

  div.tarjeta-home .content-home h3 {
    font-size: 1.5rem;
  }

  div.content-home h3,
  div.content-home p {
    font-size: 0.8rem;
  }

  .docente {
    margin-bottom: 20px;
  }

  .container-items {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }

  .counter-items {
    height: -webkit-max-content;
    height: max-content;
  }

  .counter-items svg {
    font-size: 2rem;
  }

  .counter-items h3 {
    font-size: 2rem;
    margin-top: 0;
    margin-bottom: 0;
  }

  .counter-items h4 {
    font-size: 1rem;
  }

  .docentes-list {
    grid-template-columns: 1fr;
  }

  .docente h3 {
    font-size: 1.5rem;
    margin-bottom: 5px;
  }

  div.docente p {
    font-size: 0.8rem;
  }

  .cta-bg {
    padding-top: 0;
  }

  .cta-bg .flex {
    max-width: 100%;
    margin: 0;
    padding: 0 10px;
  }

  .cta-bg .text {
    width: 100%;
    flex: none;
  }

  .garantia {
    padding: 50px 0;
  }

  .garantia .container-garantia {
    display: flex;
    flex-direction: column;
    padding: 0 30px;
    align-items: center;
  }

  .garantia-column-one,
  .garantia-column-two {
    padding: 0;
  }

  .garantia .img-holder {
    display: none;
  }

  .garantia ul li {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 768px) and (min-width: 427px) {
  .counter-items h4 {
    font-size: 0.8rem;
  }

  section.first-section .content-home p,
  section.first-section .content-home h3 {
    font-size: 1rem;
    margin-bottom: 10px;
  }

  .first-section .circle:before {
    display: block;
    width: 250px;
    height: 250px;
    background: #c9efff;
    border-radius: 50%;
    content: " ";
    position: absolute;
    z-index: -1;
    left: 6%;
    top: 20px;
  }

  .cta-home .countdown span {
    font-size: 4vw;
  }

  section.first-section {
    margin-top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .first-section .content p {
    font-size: 1.3rem;
  }

  .first-section .content h3 {
    font-size: 1.6rem;
  }

  .first-section .circle {
    padding: 50px 0 0 25px;
    margin-bottom: 50px;
  }

  section.cta-bg {
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .tarjeta-home {
    padding: 30px 15px;
  }

  .tarjeta-home .content p {
    font-size: 1rem;
  }

  .container-items {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-row-gap: 20px;
    row-gap: 20px;
  }

  .counter-items {
    height: -webkit-max-content;
    height: max-content;
  }

  section.counters svg:not(:root).svg-inline--fa {
    margin-top: 0;
    width: 10vw;
    height: -webkit-max-content;
    height: max-content;
    margin-bottom: 5px;
    color: white;
  }

  .counter-items h3 {
    margin: 5px 0;
  }

  section.docentes-section {
    width: 100%;
    margin: 0;
  }

  .docente h3 {
    font-size: 6vw;
    margin: 10px 0;
  }

  div.docente p {
    font-size: 1.5rem;
  }

  .garantia h2 {
    font-size: 2rem;
  }

  .cta-bg .text h3 {
    font-size: 1.4rem;
  }

  .cta-bg .text p {
    font-size: 1rem;
  }

  .garantia .container-garantia {
    /* display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    height: max-content;
    padding: 0 20px 0 20px;
    grid-gap: 10px;
    margin: 0; */
    display: flex;
    flex-direction: column;
    padding: 0 100px;
    align-items: center;
  }

  .docentes-list {
    display: block;
    padding: 0 20px;
  }

  .counter-items h3 {
    font-size: 1.5rem;
  }
  .counter-items h4 {
    font-size: 1.2rem;
  }

  .porque-cdo .grid {
    grid-template-columns: 1fr;
  }

  .cta-bg .flex {
    display: block;
  }

  .cta-home {
    display: block;
    padding: 20px 10px;
    margin: 0;
    bottom: 2.5rem;
  }

  .cta-home h2 {
    padding: 0 20px;
    font-size: 1.6rem;
  }

  .cta-home .countdown h2 {
    padding: 0;
    color: white;
    font-size: 3rem;
  }

  .cta-home .countdown span {
    font-weight: 500;
  }

  .cta-home .countdown-holder {
    padding: 0 20px;
    margin-top: 20px;
  }

  .cta-home .countdown {
    justify-content: space-evenly;
    margin-bottom: 20px;
  }

  .cta-home .countdown-holder a {
    margin: 20px 0;
  }

  .first-section {
    display: block;
  }

  .first-section .circle:before {
    display: block;
    width: 300px;
    height: 300px;
    background: #c9efff;
    border-radius: 50%;
    content: " ";
    position: absolute;
    z-index: -1;
    left: 0;
    top: 20px;
  }

  .first-section .circle span {
    color: var(--color-principal);
  }

  .docente h3 {
    font-size: 3vw;
  }

  div.docente {
    margin-bottom: 40px;
  }

  section.garantia {
    height: -webkit-max-content;
    height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 50px;
  }

  ul.garantia-column-one {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  ul.garantia-column-two {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  ul.garantia-column-one,
  ul.garantia-column-two {
    margin-bottom: 0;
  }

  .garantia .img-holder {
    width: 100%;
    grid-column-start: 1;
    grid-column-end: 1;
    display: flex;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  .garantia .img-holder img {
    /* width: 100%;
    margin: 0 auto;
    padding: 0; */
    display: none;
  }

  footer .blog .entry .img {
    flex: none;
  }

  .garantia ul li {
    margin-top: 20px;
    font-size: 1.5rem;
  }

  .docentes-list img {
    width: 80%;
  }
}

@media only screen and (min-width: 769px) {
  .cta-home {
    top: 40px;
  }
}

.custom-button-group {
  width: 100px;
  color: white;
  display: flex;
  justify-content: space-between;
  margin: 20px auto 0 auto;
}

.custom-button-group button {
  cursor: pointer;
  width: 46px;
  height: 46px;
  line-height: 46px;
  color: white;
  background: var(--color-principal);
  border: none;
}

.carousel-parent {
  min-height: 350px;
  margin-top: 30px;
}

.carousel-parent h2 {
  text-align: center;
  margin: 80px 0 30px 0;
  font-family: "Roboto Slab", serif;
  font-weight: 700;
  font-size: 2rem;
}

ul.react-multi-carousel-track {
  height: 351px;
}

li.react-multi-carousel-item.react-multi-carousel-item--active {
  margin: 0 10px;
}

footer {
  width: 100%;
  height: -webkit-max-content;
  height: max-content;
  background: var(--color-principal);
  padding: 50px 0;
  color: white;
  position: relative;
  z-index: 2;
}
footer .container {
  margin-top: 30px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 80%;
}

footer h3 {
  color: white;
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 20px;
}

.acerca {
  margin-right: 30px;
}

.paginas {
  margin: 0 30px;
}

footer .paginas {
  flex: 1 1;
}

footer .paginas ul {
  padding-left: 20px;
}
footer .paginas ul li {
  margin: 5px 0;
  font-family: "Open Sans", sans-serif;
  font-size: 1.4rem;
}

footer .paginas ul li a {
  color: white;
  transition: ease all 0.3s;
  font-size: 1.2rem;
}

footer .paginas ul li a:hover {
  color: goldenrod;
}

footer .blog {
  flex: 2 1;
}

footer .blog .entries {
  display: flex;
  flex-direction: column;
}

footer .blog .entry {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
}

footer .blog .entry .img {
  margin-right: 15px;
}

footer .blog .entry .img img {
  max-width: 80px;
}

footer .blog .entry .title {
  width: 200px;
}

/*
Para las direcciones
*/

.ul_dir {
  list-style: none;
  text-align: center;
}

footer .container h3 {
  font-family: "Roboto Slab", serif;
}

.contacto a {
  color: white;
}

.numeros {
  margin: 15px 0;
}

@media only screen and (max-width: 768px) {
  footer {
    height: -webkit-max-content;
    height: max-content;
    padding: 30px 40px;
  }

  footer .blog .entries {
    grid-template-columns: 1fr;
    grid-row-gap: 15px;
    row-gap: 15px;
  }

  footer .container {
    flex-direction: column;
    padding: 0;
    margin: 0;
    width: 100%;
  }

  footer .container h3 {
    width: -webkit-max-content;
    width: max-content;
    margin: 0 auto 20px;
  }

  footer .blog {
    margin-left: 0;
    margin-top: 40px;
  }

  footer .blog,
  footer .paginas {
    width: 100%;
  }

  footer .blog .entry .img {
    flex: none;
  }

  footer .paginas ul li a {
    font-size: 1rem;
  }
}

.background-cookie {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  background: rgba(0, 0, 0, 0.8);
  -webkit-backdrop-filter: saturate(180%) blur(10px);
          backdrop-filter: saturate(180%) blur(10px);
  display: flex;
}

.modal-cookie {
  position: fixed;
  bottom: 0;
  z-index: 3;
  width: 100%;
  background-color: #edeff5;
  padding: 2%;
  border-radius: 20px 20px 0 0;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.modal-cookie__content p {
  color: black;
  font-size: 1rem;
  margin: 0 0 10px 0;
  color: #838391;
}

.modal-cookie__content p a {
  color: #afb0ba;
}

.modal-cookie__content p a:hover {
  color: #838391;
}

div.modal-cookie__buttons {
  width: 20%;
  display: flex;
  font-size: 1rem;
}

.modal-cookie button {
  height: 30px;
  width: 50%;
  margin: 0 10px;
  background-color: #0099d5;
  color: white;
  border: none;
  border-radius: 5px;
  font-weight: 600;
}

button.modal-cookie__buttons--reject {
  color: #0099d5;
  background-color: #edeff5;
  border: 1px solid #0099d5;
  font-weight: 500;
}

.modal-cookie button:hover {
  cursor: pointer;
}

.modal-cookie.true {
  display: none;
}

.modal-cookie.false {
  display: flex;
}

.ocultar-true {
  display: none !important;
}

.ocultar-fondo-true {
  display: none;
}

/*
.ocultar-false{
  display: flex;
}*/

@media only screen and (max-width: 426px) and (min-width: 320px) {
  div.modal-cookie__buttons {
    width: 80%;
  }

  .modal-cookie__content p {
    font-size: 0.8rem;
  }
}

@media only screen and (max-width: 768px) and (min-width: 426px) {
  div.modal-cookie__buttons {
    width: 50%;
  }

  .modal-cookie__content p {
    font-size: 0.9rem;
  }
}

.section-title{
    height: 168px;
    background-size: cover;
    overflow: hidden;
    display: flex;
}

.section-title h1{
    color:white;
    font-family: "Roboto Slab", sans-serif;
    font-size: 1.8rem;
    text-transform: uppercase;
    text-shadow: 1px 1px 1px rgba(0,0,0,.3);
}
@media only screen and (max-width: 900px) {
    .section-title h1{
        font-size: 1.3rem;
    }
}
.nosotros-holder {
  margin-top: 50px;
  margin-bottom: 50px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  padding: 0 10%;
}

.nosotros-holder h2 {
  margin-bottom: 20px;
  font-family: Roboto, sans-serif;
  font-size: 1.8rem;
}

.nosotros-holder p {
  margin-bottom: 1.2rem;
}

.form-nosotros ul {
  display: flex;
  background: var(--color-principal);
  color: white;
  font-family: Roboto, sans-serif;
  text-transform: uppercase;
  padding: 1% 1% 0;
  font-weight: 700;
  border: none;
}

.form-nosotros ul li {
  margin-right: 30px;
}
.form-nosotros .react-tabs__tab--selected {
  padding: 20px;
  border-radius: 0;
  border: none;
}

.react-tabs__tab-panel {
  margin-top: 30px;
}

@media only screen and (max-width: 426px) and (min-width: 320px) {
  .nosotros-holder {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .nosotros-holder p {
    font-size: 4vw;
  }

  .cuarta-seccion-nosotros #react-tabs-2 {
    margin-right: 0;
  }

  .cuarta-seccion-nosotros li#react-tabs-2,
  li#react-tabs-0 {
    text-align: center;
  }

  .form-nosotros .contact-form,
  .cuarta-seccion-nosotros p,
  .primera-seccion-nosotros {
    padding: 0 10%;
  }

  form.contact-form button.button-nosotros-form {
    min-width: 100px;
    padding: 8px 25px;
  }

  img.img-fluid {
    margin: 20px 0;
  }

  ul.react-tabs__tab-list {
    display: flex;
    justify-content: space-evenly;
  }

  li#react-tabs-2,
  li#react-tabs-0 {
    padding: 20px;
  }
}

@media only screen and (max-width: 769px) and (min-width: 426px) {
  .nosotros-holder {
    display: flex;
    flex-direction: column;
    padding: 0 10%;
    align-items: center;
  }

  .nosotros-holder p {
    font-size: 0.8rem;
  }

  .form-nosotros ul {
    display: flex;
  }

  .nosotros-holder div {
    width: 100%;
  }
}

.input-group .nombre,
.input-group .email {
  width: 47%;
}

.svg.svg-inline--fa.fa-caret-left.fa-w-6 path {
  color: white;
}

button.btn-primary.button-nosotros-form {
  background-color: var(--color-secundario);
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .input-group .nombre,
  .input-group .email {
    width: 100%;
    margin-bottom: 10px;
  }
}

.btn-inscribirse {
  margin-bottom: 10px;
}

.all-cursos {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 100px auto 50px auto;
  grid-column-gap: 30px;
  -webkit-column-gap: 30px;
          column-gap: 30px;
  grid-row-gap: 50px;
  row-gap: 50px;
}
.curso-single {
  display: grid;
  grid-template-columns: 1fr 280px;
  grid-column-gap: 50px;
  -webkit-column-gap: 50px;
          column-gap: 50px;
  margin-top: 50px;
}
.curso-single h2 {
  font-size: 2.2rem;
}
.meta-categorias {
  margin-top: 30px;
  display: flex;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  align-items: center;
}
.meta-categorias h3 {
  font-size: 0.8rem;
  font-weight: 400;
  color: #666;
}

.meta-categorias a {
  display: inline-block;
  margin-right: 5px;
  color: black;
  cursor: inherit;
}
.meta-categorias .icon {
  font-size: 2rem;
  color: var(--color-principal);
}

.single-tabs {
  width: 100%;
}

.single-tabs .react-tabs__tab {
  bottom: 0;
}
.single-tabs .react-tabs ul {
  background: #f0f4fa;
  color: #273044;
  font-family: Roboto, sans-serif;
  text-transform: uppercase;
  margin-top: 0;
  padding-top: 0;
  padding-left: 0;
  font-weight: 700;
}

.single-tabs .react-tabs ul li {
  margin-right: 30px;
}
.single-tabs .react-tabs .react-tabs__tab--selected {
  padding: 20px;
  border-radius: 0;
  border: none;
  background: white;
}

.single-tabs .react-tabs__tab-panel {
  margin-top: 30px;
}
#div-detalle {
  margin-bottom: 50px;
}
#div-detalle p {
  margin: 20px 0;
  font-family: "Open Sans", sans-serif;
  text-align: justify;
}

#div-detalle h2 {
  margin: 20px 0;
  font-family: Roboto, sans-serif;
  font-size: 1.8rem;
}
#div-detalle ul {
  margin-left: 20px;
  background: none;
}

#div-detalle ul li {
  margin: 10px 0;
  font-family: "Open Sans", sans-serif;
  text-transform: none;
  font-weight: 400;
}
.curriculum-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  background: #f0f4fa;
  color: #273044;
  margin-bottom: 3px;
  padding: 10px;
  cursor: pointer;
}
.curriculum-row .curriculum-number {
  min-width: 30px;
  text-align: left;
  color: #999;
}
.curriculum-row .curriculum-icon {
  color: var(--color-principal);
}
.curriculum-row .curriculum-label {
  flex: 1 1;
  font-family: Roboto, serif;
  font-weight: 700;
  transition: ease all 0.3s;
}

.curriculum-row .curriculum-label:hover {
  color: var(--color-principal);
}
.curriculum-row .curriculum-duration {
  color: #999;
}

.single-tabs .Collapsible {
  border: 1px solid #eaeaea;
  margin-bottom: 20px;
}

.single-tabs .Collapsible .Collapsible__trigger {
  padding: 20px 40px;
  display: block;
  cursor: pointer;
}

.single-tabs .Collapsible .Collapsible__trigger {
  font-family: Roboto, serif;
  font-weight: 700;
  transition: ease all 0.3s;
}

.single-tabs .Collapsible .Collapsible__trigger.is-open {
  border-top: 3px solid var(--color-principal);
}

.single-tabs .Collapsible .Collapsible__contentInner {
  text-align: justify;
  font-family: "Open Sans", sans-serif;
  padding: 0 40px 20px 40px;
}
iframe {
  display: none;
}
@media only screen and (max-width: 1200px) {
  .all-cursos {
    grid-template-columns: repeat(2, 1fr);
  }
}

aside a.btn-sidebar {
  display: block;
  text-align: center;
  color: white;
  background-color: #17d292 !important;
  border-radius: 10px;
  width: 100%;
  text-transform: uppercase;
  font-family: Roboto, serif;
  padding: 12px 0;
  transition: ease all 0.3s;
}

aside a.btn-sidebar:hover {
  background-color: #14b37b !important;
}

aside ul {
  margin: 20px 0;
  padding: 0;
  list-style: none;
}

aside ul li {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  align-items: center;
  border-bottom: 1px solid #eaeaea;
  font-family: Roboto, serif;
}

aside ul li .icon {
  font-size: 2rem;
}

aside ul li svg {
  color: var(--color-principal);
}

aside h2.title {
  color: var(--color-principal);
  font-size: 1.6rem;
  text-align: center;
  margin-bottom: 20px;
}

.cursos-populares {
  border: 1px solid #eaeaea;
  padding: 15px 30px 0 20px;
  margin-bottom: 50px;
}
.curso-popular-item {
  display: grid;
  grid-template-columns: 30% 70%;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  align-items: flex-start;
  margin-bottom: 20px;
}
aside .curso-popular-item .title {
  font-size: 0.9rem;
  color: black;
  text-align: left;
}
@media only screen and (max-width: 900px) {
  .all-cursos {
    grid-template-columns: repeat(1, 1fr);
  }

  .curso-single {
    display: block;
  }

  .react-tabs__tab {
    display: block;
    padding: 20px;
  }

  .single-tabs .react-tabs ul li {
    margin-right: 0;
  }

  .single-tabs .react-tabs__tab {
    bottom: 0;
  }

  .react-tabs ul li {
    margin-right: 0;
  }
}

.label-inscripcion {
  margin-bottom: 5px;
  color: #aaa;
}

.label-inscripcion::after {
  content: "*";
  color: #f13e3e;
  border-bottom: 2px dotted #f13e3e;
  margin-left: 5px;
}

.datos-personales h1 {
  margin-bottom: 20px;
}

.pago-title,
.pedido-title {
  font-size: 1.3rem;
  margin-bottom: 20px;
  color: black;
}

table.shop_table.woocommerce-checkout-review-order-table {
  margin-bottom: 25px;
}

td,
th {
  color: rgb(168 167 168);
}

span.pago-option {
  display: inline-block;
  margin-bottom: 5px;
}

.card {
  width: 100%;
  height: -webkit-max-content;
  height: max-content;
  padding: 20px;
  background-color: #f0f0ed;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.input-pago__fecha,
.input-pago {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

#ccNo,
#cvv {
  width: 100%;
  height: 45px;
  padding: 6px 12px;
  background-color: #fafafa;
  border: none;
  color: #555;
  font-family: "Open Sans", sans-serif;
}

div.input-pago__fecha {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 0;
}

.input-pago__fecha label {
  display: flex;
  flex-direction: column;
  width: 60%;
}

.input-pago__fecha input {
  width: 50%;
  height: 40px;
  font-family: "Open Sans", sans-serif;
  font-size: 0.9rem;
  margin-top: 5px;
}

span.pago-option {
  font-size: 1rem;
  font-family: "Roboto Slab";
  font-weight: 500;
}

select {
  margin-top: 5px;
  width: 30%;
  height: 40px;
}

select option {
  font-family: "Open Sans", sans-serif;
}

.mensaje-cvs {
  margin-top: 5px;
  color: #aaa;
}

.mensaje-cvs::after {
  content: "*";
  color: #f13e3e;
  border-bottom: 2px dotted #f13e3e;
  margin-left: 5px;
}

.submit-section {
  display: flex;
  flex-direction: column;
}

.submit-section img {
  width: 75%;
  margin: 0 auto 15px auto;
}

.submit-section input {
  margin: 0 auto;
}

.product-name,
.product-total,
.cart-subtotal th,
.order-total th,
span.woocommerce-Price-currencySymbol,
bdi {
  color: black;
}
.test {
  padding: 15px;
  text-align: center;
  margin-bottom: 15px;
  color: #fff;
}

.error {
  background-color: red;
}

.success {
  background-color: rgb(7, 160, 7);
}

.pending {
  background-color: yellow;
  color: black !important;
}

.acceder-holder {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0 45px;
}

@media only screen and (max-width: 768px) {
  .acceder-holder {
    grid-template-columns: 1fr;
    grid-template-rows: 2fr 1fr;
    grid-gap: 10px 0;
  }

  .datos-personales {
    grid-row-start: 1;
    grid-row-end: 2;
    height: -webkit-max-content;
    height: max-content;
  }

  .pedido {
    grid-row-start: 2;
    grid-row-end: 3;
  }

  #tcoCCForm {
    height: 1370px;
  }

  h3.pago-title,
  h3.pedido-title {
    font-size: 1.5rem;
  }

  h3.pedido-title {
    margin-bottom: 20px;
  }

  h3.pago-title {
    margin-top: 30px;
  }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  select {
    width: 60%;
  }
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .datos-personales {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .input-pago__fecha,
  .input-pago {
    margin: 0 auto 10px auto;
  }

  #ccNo,
  #cvv {
    height: 30px;
  }

  .mensaje-cvs {
    font-size: 4vw;
  }

  div.input-pago__fecha {
    flex-direction: column;
    align-items: center;
  }

  span.pago-option {
    font-size: 0.8rem;
  }

  .input-pago__fecha label {
    width: 100%;
    margin-bottom: 10px;
  }

  .input-pago__fecha label br {
    display: none;
  }

  select {
    width: 45%;
  }

  .input-pago__fecha label div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .submit-section {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }
  .submit-section img {
    width: 45%;
    margin: 0;
  }

  .submit-section input {
    width: 45%;
    margin: 0;
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.nav-tabs > li > a,
.member-name,
.section-title,
.user-name,
.heading_font,
.item-title,
.acomment-meta,
[type="reset"],
.bp-subnavs,
.activity-header,
table,
.widget_categories ul li a,
.sidebar-area .widget ul li a,
.select2-selection__rendered,
blockquote,
.select2-chosen,
.vc_tta-tabs.vc_tta-tabs-position-top
  .vc_tta-tabs-container
  .vc_tta-tabs-list
  li.vc_tta-tab
  a,
.vc_tta-tabs.vc_tta-tabs-position-left
  .vc_tta-tabs-container
  .vc_tta-tabs-list
  li.vc_tta-tab
  a,
body.distance-learning .btn,
body.distance-learning .vc_btn3 {
  font-family: "Roboto Slab";
  color: #222222;
}

footer h1,
footer h2,
footer h3,
footer h4,
footer h5,
footer h6 {
  font-family: Roboto, sans-serif;
  color: white;
}

.section-title-tipo {
  height: 650px;
  overflow: hidden;
  display: flex;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.tipo {
  position: relative;
  top: 14px;
}

.input-group {
  /* position: relative; */
  /* display: -ms-flexbox; */
  /* display: flex; */
  /* -ms-flex-wrap: wrap; */
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  /* margin-bottom: 5px; */
}

.woocommerce-checkout-review-order-table {
  width: 100%;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

.shop_table tr {
  border: 0;
}

.cursos-online {
  color: var(--color-secundario);
  font-size: 1.2rem;
  font-weight: 700;
}

.shop_table > tbody > tr td {
  padding: 30px 8px;
  vertical-align: middle;
}

.woocommerce-checkout-review-order-table th,
.woocommerce-checkout-review-order-table.shop-table > tbody > tr > td,
.woocommerce-checkout-review-order-table.shop_table td,
.woocommerce-checkout-review-order-table.shop_table th,
.woocommerce-checkout-review-order-table > tbody > tr > td {
  padding: 6px 17px;
}

.woocommerce-checkout-review-order-table td.product-name,
.woocommerce-checkout-review-order-table th {
  text-align: left;
}

.woocommerce .shop_table.order_details .product-total,
.woocommerce-checkout-review-order-table td,
.woocommerce-checkout-review-order-table th.product-total {
  text-align: right;
}

.woocommerce-checkout-review-order-table th,
.woocommerce-checkout-review-order-table.shop-table > tbody > tr > td,
.woocommerce-checkout-review-order-table.shop_table td,
.woocommerce-checkout-review-order-table.shop_table th,
.woocommerce-checkout-review-order-table > tbody > tr > td {
  padding: 6px 17px;
}

.woocommerce .shop_table.order_details > tbody > tr:nth-of-type(2n + 1),
.woocommerce-checkout-review-order-table > tbody > tr:nth-of-type(2n + 1) {
  background-color: #f2f2f2;
}

.shop_table > tbody > tr {
  border: 0;
}

.info-container {
  height: -webkit-max-content;
  height: max-content;
  display: grid;
  grid-template-columns: 1fr 2fr 3fr 3fr 2fr 1fr;
  grid-template-rows: 1fr 4fr 1fr;
  grid-gap: 50px;
  padding-top: 30px;
}

.info-container img {
  width: 100%;
  grid-column-start: 2;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 5;
  align-self: end;
}

.info-container .text {
  grid-column-start: 4;
  grid-column-end: 6;
  grid-row-start: 1;
  grid-row-end: 4;
}

.text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.text p,
.text span {
  margin: 10px 0;
}

.info-container .text .pago-anual {
  margin-bottom: 0;
  font-size: 1.8rem;
  font-weight: 700;
}

.info-container .text .precio {
  margin-top: 0;
  color: var(--color-secundario);
  font-size: 3vh;
  font-weight: 500;
}

.precio .usd {
  font-size: 2.5rem;
  font-weight: bolder;
}

.text p {
  font-size: 1.2rem;
}

.text p a {
  color: black;
  transition: 0.2s;
}

.text p a:hover {
  transition: 0.3s;
  color: rgb(0, 152, 215);
}

.man-info {
  width: 100%;
  background-image: url(/static/media/Banner-Inscripción-2.2e2eec8b.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: grid;
  grid-template-columns: 1fr 3fr 3fr 1fr;
}

.man-info__text {
  display: flex;
  flex-direction: column;
  grid-column-start: 2;
  grid-column-end: 3;
  justify-content: center;
}

.man-info__text--title {
  font-family: Roboto;
  text-transform: uppercase;
  color: var(--color-principal);
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 37px;
  margin-bottom: 10px;
}

.man-section {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.man-image {
  width: 100%;
  grid-column-start: 3;
  grid-column-end: 4;
}

.kids-courses {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
}

.cercle-blue {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: white;
  display: inline-block;
  margin-right: 5px;
}

.cercle-blue {
  background-color: rgb(0, 152, 215);
}

div.courses-logo img {
  width: 15%;
  margin-right: 10px;
}

.info-pay-section {
  display: grid;
  grid-template-columns: 1fr 3fr 3fr 1fr;
  grid-gap: 0 30px;
  margin: 50px 0;
}

.pay-includes {
  grid-column-start: 2;
  grid-column-end: 3;
}

.pay-actualizations {
  grid-column-start: 3;
  grid-column-end: 4;
}

.pay-includes,
.pay-actualizations {
  display: flex;
  flex-direction: column;
}

.pay-includes h3,
.pay-actualizations h3 {
  margin-bottom: 10px;
  color: var(--color-principal);
}

svg.svg-inline--fa.fa-check.fa-w-16 path,
svg.svg-inline--fa.fa-circle.fa-w-16 path {
  color: var(--color-principal);
}

.check-section {
  display: grid;
}

.check-text {
  display: flex;
  align-items: flex-start;
}

div.chech-text svg.svg-inline--fa.fa-w-16 {
  width: 10px;
}

.check-text p {
  margin-bottom: 4px;
  margin-left: 5px;
  font-size: 1.1vw;
}

h3 {
  margin-bottom: 30px;
}

.guarantee-section {
  background-color: #f5f5f5;
  display: grid;
  grid-template-columns: 1fr 5fr 5fr 1fr;
  padding: 30px 0;
  background-image: url(/static/media/Banner-Inscripción-3.e94c1ce5.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.conditions {
  grid-column-start: 2;
  grid-column-end: 3;
}

.guarantee {
  grid-column-start: 3;
  grid-column-end: 4;
  background-color: white;
  border: 3px solid #a2a2a2;
  border-radius: 40px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  padding: 30px 0;
  width: 33vw;
  font-family: Roboto;
  font-size: 1vw;
  margin: 0 auto;
}

.guarantee p {
  text-align: center;
  font-family: Roboto;
  line-height: 25px;
  font-weight: 400;
}

.guarantee img {
  width: 30%;
  margin-top: 20px;
}

.fa-circle {
  background-color: var(--color-principal);
  border-radius: 50%;
  width: 15px;
  height: 15px;
}

.conditions h2,
.guarantee h2 {
  margin-bottom: 20px;
  font-family: Roboto;
}

.conditions h2 {
  text-transform: uppercase;
  font-size: 1.7vw;
  font-weight: 900;
}

.guarantee h2 {
  font-size: 3vh;
  display: inline-block;
  text-align: center;
}

.container-form {
  padding: 20px 130px;
  width: 100%;
}

@media only screen and (max-width: 768px) and (min-width: 426px) {
  .info-container {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    padding: 10px 15px;
    grid-gap: 0;
  }

  .info-container img {
    grid-column-start: 1;
    grid-column-end: 2;
    margin: 0;
    width: 100%;
  }

  .info-container .text {
    grid-column-start: 2;
    grid-column-end: 3;
  }

  .section-title-tipo {
    height: 100%;
  }

  .text p {
    margin: 5px 0;
    font-size: 1.8vw;
    font-weight: 400;
  }

  .info-container .text .pago-anual {
    font-size: 2.3vw;
  }

  .precio .usd {
    font-size: 3vw;
  }

  .info-container .text .precio {
    font-size: 2vw;
  }

  .text p,
  .text span {
    margin-bottom: 0;
  }

  .man-info {
    grid-template-columns: 1fr 1fr;
    padding: 10px 20px 0 20px;
  }

  .man-info__text {
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .man-section {
    grid-column-start: 2;
    grid-column-end: 3;
  }

  .man-info__text--title {
    font-size: 2.5vw;
    line-height: 20px;
    margin-bottom: 20px;
  }

  .info-pay-section {
    grid-template-columns: 1fr 1fr;
    padding: 0 20px;
  }

  .pay-includes {
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .pay-actualizations {
    grid-column-start: 2;
    grid-column-end: 3;
  }

  .pay-includes h3,
  .pay-actualizations h3 {
    font-size: 2.3vw;
    margin-bottom: 30px;
  }

  .check-text p {
    font-size: 1.6vw;
  }

  .guarantee-section {
    grid-template-rows: 0.5fr 0.5fr;
    grid-template-columns: 1fr;
    padding: 20px 60px;
    grid-gap: 20px 0;
    background-size: contain;
    background-position-x: right;
    background-position-y: bottom;
  }

  .conditions {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .guarantee {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 2;
    width: 50vw;
    height: 50vh;
  }

  .conditions h2 {
    font-size: 2.5vw;
  }

  svg.svg-inline--fa.fa-circle.fa-w-16 {
    width: 10px;
    height: 10px;
  }

  div.conditions div.check-text p {
    font-size: 1.8vw;
    font-weight: 400;
  }

  .guarantee {
    height: -webkit-max-content;
    height: max-content;
    margin: auto auto;
    width: 70%;
    height: max-content;
    padding: 20px;
  }

  .guarantee p {
    font-size: 2vw;
    line-height: 20px;
  }

  .guarantee img {
    width: 25%;
  }
}

@media only screen and (max-width: 600px) and (min-width: 320px) {
  .container-form {
    width: 100%;
    padding: 10px 30px;
  }
}

@media only screen and (max-width: 426px) and (min-width: 320px) {
  .info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 30px;
    height: -webkit-max-content;
    height: max-content;
  }

  .info-container img {
    display: none;
  }

  .info-container .text .pago-anual {
    font-size: 8vw;
    line-height: 30px;
    margin-bottom: 5px;
  }

  .info-container .text .precio {
    font-size: 5vw;
  }

  .precio .usd {
    font-size: 6vw;
  }

  .text p,
  .text span {
    font-size: 1rem;
  }

  .man-info {
    grid-template-columns: 1fr;
    padding: 10px 30px;
  }

  .man-image {
    display: none;
  }

  .man-info__text--title {
    font-size: 5vw;
    margin-bottom: 10px;
    line-height: 25px;
  }

  .kids-courses {
    margin-bottom: 10px;
  }

  .cercle-blue {
    width: 10px;
    height: 10px;
  }

  .courses-logo {
    margin: 0;
    display: flex;
    justify-content: space-between;
  }

  .info-pay-section {
    grid-template-columns: 1fr;
    grid-template-rows: 0.5fr 0.5fr;
    grid-gap: 20px 0;
    margin: 30px 0;
    padding: 0 30px;
  }

  .pay-includes {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  .pay-actualizations {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
  }

  .pay-includes h3,
  .pay-actualizations h3 {
    font-size: 5vw;
    font-weight: 700;
    margin-bottom: 20px;
  }

  .check-text p {
    font-size: 3.5vw;
  }

  .check-text {
    align-items: center;
  }

  .text p,
  .text span {
    font-size: 3.5vw;
  }

  .guarantee-section {
    grid-template-columns: 1fr;
    grid-template-rows: 0.5fr 0.5fr;
    padding: 30px 30px;
  }

  .conditions h2 {
    font-size: 4vw;
  }

  .conditions {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .guarantee {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 2;
    margin: 20px 0;
    width: 100%;
    padding: 15px 10px;
    height: -webkit-max-content;
    height: max-content;
  }

  div.check-text svg:not(:root).svg-inline--fa {
    margin: 0;
    margin-bottom: auto;
  }

  div.guarantee h2 {
    text-align: center;
    margin: 0 0 20px 0;
  }

  .guarantee p {
    font-size: 3vw;
    font-weight: 600;
    /* text-align: left; */
    line-height: 20px;
  }

  .guarantee p br {
    display: none;
  }

  .guarantee img {
    margin: 20px 0 0 0;
  }
}

@media only screen and (max-width: 1200px) and (min-width: 769px) {
  .info-container .text .pago-anual {
    font-size: 1.4rem;
  }

  .precio .usd {
    font-size: 2rem;
  }

  .text p {
    font-size: 1rem;
  }
}

.contacto-holder{
    margin: 50px auto;
    max-width: 600px;
}
@media only screen and (max-width: 900px) {
    .contacto-holder{
        margin: 50px 20px;
    }
}
.post-title {
  margin-bottom: 30px;
}

.main-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
}

.container-publicaciones {
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 70px;
  margin: 60px 0;
  padding: 0 100px;
}

.blog-container {
  display: grid;
  grid-template-columns: 1fr 280px;
  grid-column-gap: 50px;
  -webkit-column-gap: 50px;
          column-gap: 50px;
  margin-top: 50px;
}

.blog-container main h2 {
  text-transform: uppercase;
}

.post-content p,
.post-content h2 {
  margin: 30px 0;
}

.post-meta {
  border-top: 1px solid #eaeaea;
  padding: 12px 0;
  font-size: 0.8rem;
  font-family: "Roboto Slab", serif;
}

.post-meta svg {
  margin-right: 5px;
  color: var(--color-principal);
}

.post-content {
  margin: 30px 0;
  font-family: "Open Sans", sans-serif;
}
.post-content p {
  text-align: justify;
}
.post-content h1,
.post-content h2,
.post-content h3,
.post-content h4,
.post-content h5,
.post-content h6 {
  margin: 20px 0;
  text-transform: none !important;
}

.img-squared {
  width: 83px;
  height: 50px;
  position: relative;
}
.img-squared img {
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 60px;
  object-fit: cover;
  z-index: 2;
}

.sidebar-decorator {
  height: 3px;
  width: 100%;
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(0, 152, 215, 1) 35%,
    rgba(255, 255, 255, 1) 35%,
    rgba(255, 255, 255, 1) 41%,
    rgba(0, 0, 0, 1) 41%,
    rgba(0, 0, 0, 1) 100%
  );

  margin-bottom: 30px;
}

.card-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 10px;
}

.card-container::after {
  width: 50px;
  height: 3px;
  background-color: var(--color-secundario);
  content: " ";
  margin-top: 30px;
}

.card-container::before {
  width: 700px;
  height: 1px;
  background-color: #dcdcdc;
  position: absolute;
  bottom: left;
  margin-top: 10px;
}

.post-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  width: 100%;
}

.post-item img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.sidebar-post-item {
  margin-top: 25px;
  display: grid;
  grid-template-columns: 1fr 3fr;
}

.sidebar-post-item:hover h2.title {
  color: var(--color-principal);
  transition: ease all 0.3s;
}
.sidebar-post-item h2.title {
  font-size: 0.9rem;
  color: black;
  text-align: left;
}

.title-blog {
  font-size: 1.5rem;
}

.fecha {
  width: 60px;
  height: 60px;
  border: 2px solid var(--color-secundario);
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: content-box;
  padding-bottom: 8px;
  margin-right: 10px;
}

.fecha__numero {
  color: var(--color-secundario);
  width: -webkit-max-content;
  width: max-content;
  height: 35px;
  margin: 0 auto;
  font-size: 2rem;
}

.fecha__iniciales {
  color: #8498df;
  width: -webkit-max-content;
  width: max-content;
  height: -webkit-max-content;
  height: max-content;
  margin: 0 auto;
}

.content-container {
  display: grid;
  grid-template-columns: 1fr 5fr;
}

.content {
  display: flex;
  flex-direction: column;
}

.separador {
  width: 31px;
  height: 3px;
  background-color: var(--color-principal);
  margin-top: 30px;
}

.sidebar-section {
  margin: 60px 0;
}

.first-section-sidebar {
  margin-top: 0;
}

.sidebar-search {
  margin-top: 25px;
  background-color: #f0f2f5;
  height: 78px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
}

.sidebar-search input {
  border: none;
  height: 38px;
  width: 85%;
  padding-left: 20px;
}

.sidebar-search input:focus {
  outline: none;
}

.sidebar-search button {
  border: none;
  height: 38px;
  width: 10%;
  padding-right: 20px;
  padding-left: 5px;
  background-color: #fff;
}

.search-container {
  width: 70%;
  background-color: #fff;
}

.fa-search {
  color: var(--color-principal);
}

.blog {
  margin-left: 30px;
}

.font-black {
  margin-top: 20px;
  color: black;
  font-size: 1rem;
}

/*

    Pagination

*/

.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
}

.pagination li {
  margin-right: 10px;
}

.pagination > .active > a {
  background-color: #47ccde;
  border-color: #47ccde;
  color: #fff;
}
.pagination > li > a {
  border: 2px solid #47ccde;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #47ccde;
  border-color: #47ccde;
  outline: none;
}
.pagination > li > a,
.pagination > li > span {
  color: #47ccde;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}

@media only screen and (max-width: 425px) {
  .container-publicaciones {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 60px auto;
  }

  .main-section {
    grid-template-columns: 1fr;
  }

  .card-container {
    width: 85%;
    margin: 0 auto;
  }

  .title-blog {
    font-size: 1rem;
  }

  .fecha {
    width: 30px;
    height: 30px;
  }

  .fecha__numero {
    font-size: 1.2rem;
  }

  .fecha__iniciales {
    font-size: 0.8rem;
  }

  .font-black-blog {
    margin-top: 10px;
    font-size: 0.8rem;
  }
}

@media only screen and (max-width: 424px) and (min-width: 1px) {
  .blog-container {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 768px) and (min-width: 425px) {
  .blog-container {
    display: block;
  }

  .sidebar-section {
    display: none;
  }

  .main-section {
    grid-template-columns: 1fr;
    grid-gap: 50px 0;
  }

  .container-publicaciones {
    grid-template-columns: 1fr;
    padding: 0;
  }

  .pagination {
    justify-content: center;
  }

  ul.pagination li.previus a {
    font-size: 3vw;
  }

  .card-container {
    width: 85%;
    margin: 0 auto;
  }

  div div.content h2.title {
    font-size: 1.3rem;
  }
}

.breadcrumbs{
    background: #f0f0f0;
    color:#aaa;
    font-family: 'Open Sans', sans-serif;
    font-size: .7rem;
    padding: 10px 0;
    text-transform: uppercase;
}
.bc-list{
    list-style: none;
    display: flex;
    grid-column-gap: 5px;
    -webkit-column-gap: 5px;
            column-gap: 5px;
}

.bc-list li span{
    margin-left: 5px;
}

.bc-list li a{
    color:#aaa;
    text-transform: uppercase;
}

main {
  width: 100%;
}

.questions-section {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: 100px auto;
}

.section-title-questions {
  font-family: Roboto;
  /*font-size: 2vw;*/
  font-weight: 800;
  height: -webkit-max-content;
  height: max-content;
  margin-bottom: 2rem;
}

h3 {
  font-family: Roboto;
  /*font-size: 1.1vw;*/
  font-weight: 500;
  margin: 0;
  color: #0099d5;
}

.responses {
  font-family: Roboto;
  /*font-size: 0.9vw;*/
  margin: 20px 0 15px 0;
  display: none;
}

.mostrar {
  font-family: Roboto;
  /*font-size: 0.9vw;*/
  margin: 20px 0 15px 0;
  display: block;
  height: -webkit-max-content;
  height: max-content;
}

.ocultar {
  display: none;
}

.question {
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: space-between;
  /* height: 30px; */
  align-items: center;
  border: none;
  cursor: pointer;
}

.questions-button {
  background-color: white;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 15px;
  height: -webkit-max-content;
  height: max-content;
  padding: 0 15px;
}

svg.svg-inline--fa.fa-greater-than.fa-w-12 path {
  color: #0099d5;
}

.questions-button-initial-state {
  height: 40px;
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  aside {
    display: none;
  }

  .pagination {
    justify-content: center;
  }

  .section-title-questions {
    font-size: 5vw;
  }

  h3 {
    font-size: 3.6vw;
  }

  .questions-section p {
    font-size: 3.2vw;
  }

  .questions-section {
    margin: 30px auto;
    width: 90%;
  }

  .question svg.icon-preguntas {
    width: 3vw;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 426px) {
  .questions-section {
    width: 85%;
  }
}

.post-content {
	margin: 0 auto;
	font-family: "Open Sans", sans-serif;
	padding: 0 35px;
}

.has-text-align-center {
	text-align: center !important;
}
img.wp-smiley,
img.emoji {
  display: inline !important;
  border: none !important;
  box-shadow: none !important;
  height: 1em !important;
  width: 1em !important;
  margin: 0 0.07em !important;
  vertical-align: -0.1em !important;
  background: none !important;
  padding: 0 !important;
}

.correctly_answered label {
  color: #5cb85c !important;
}

.vue_is_disabled {
  display: none;
}

.yikes-easy-mc-form label.label-inline {
  float: left;
  width: 79%;
  padding-right: 10px;
}

/* Drop the submit button (100% width) below the fields */
@media (max-width: 768px) {
  .yikes-easy-mc-form label.label-inline {
    width: 99%;
    padding-right: 10px;
  }

  .yikes-easy-mc-form .submit-button-inline-label {
    width: 100%;
    float: none;
  }

  .yikes-easy-mc-form .submit-button-inline-label .empty-label {
    display: none;
  }
}

/* Stack all fields @ 100% */
@media (max-width: 650px) {
  .yikes-easy-mc-form label.label-inline {
    width: 100%;
  }
}

.logo-unit .logo {
  font-family: Montserrat;
  color: #333;
  font-size: 23px;
}
.header_top_bar,
.header_top_bar a,
.header_2_top_bar .header_2_top_bar__inner ul.header-menu li a {
  font-family: Montserrat;
  font-weight: normal;
  font-style: normal;
  color: #aaaaaa;
  font-size: 12px;
}
body.skin_custom_color
  .stm_archive_product_inner_grid_content
  .stm-courses
  li.product.course-col-list
  .product-image
  .onsale,
body.skin_custom_color
  .related.products
  .stm-courses
  li.product.course-col-list
  .product-image
  .onsale,
body.skin_custom_color
  .stm_archive_product_inner_grid_content
  .stm-courses
  li.product
  .product__inner
  .woocommerce-LoopProduct-link
  .onsale,
body.skin_custom_color
  .related.products
  .stm-courses
  li.product
  .product__inner
  .woocommerce-LoopProduct-link
  .onsale,
body.skin_custom_color
  .post_list_main_section_wrapper
  .post_list_meta_unit
  .sticky_post,
body.skin_custom_color .overflowed_content .wpb_column .icon_box,
body.skin_custom_color .stm_countdown_bg,
body.skin_custom_color #searchform-mobile .search-wrapper .search-submit,
body.skin_custom_color .header-menu-mobile .header-menu > li .arrow.active,
body.skin_custom_color .header-menu-mobile .header-menu > li.opened > a,
body.skin_custom_color mark,
body.skin_custom_color
  .woocommerce
  .cart-totals_wrap
  .shipping-calculator-button:hover,
body.skin_custom_color
  .detailed_rating
  .detail_rating_unit
  tr
  td.bar
  .full_bar
  .bar_filler,
body.skin_custom_color .product_status.new,
body.skin_custom_color
  .stm_woo_helpbar
  .woocommerce-product-search
  input[type="submit"],
body.skin_custom_color
  .stm_archive_product_inner_unit
  .stm_archive_product_inner_unit_centered
  .stm_featured_product_price
  .price.price_free,
body.skin_custom_color .sidebar-area .widget:after,
body.skin_custom_color
  .sidebar-area
  .socials_widget_wrapper
  .widget_socials
  li
  .back
  a,
body.skin_custom_color .socials_widget_wrapper .widget_socials li .back a,
body.skin_custom_color .widget_categories ul li a:hover:after,
body.skin_custom_color .event_date_info_table .event_btn .btn-default,
body.skin_custom_color .course_table tr td.stm_badge .badge_unit.quiz,
body.skin_custom_color .page-links span:hover,
body.skin_custom_color .page-links span:after,
body.skin_custom_color .page-links > span:after,
body.skin_custom_color .page-links > span,
body.skin_custom_color .stm_post_unit:after,
body.skin_custom_color .blog_layout_grid .post_list_content_unit:after,
body.skin_custom_color ul.page-numbers > li a.page-numbers:after,
body.skin_custom_color ul.page-numbers > li span.page-numbers:after,
body.skin_custom_color ul.page-numbers > li a.page-numbers:hover,
body.skin_custom_color ul.page-numbers > li span.page-numbers:hover,
body.skin_custom_color ul.page-numbers > li a.page-numbers.current:after,
body.skin_custom_color ul.page-numbers > li span.page-numbers.current:after,
body.skin_custom_color ul.page-numbers > li a.page-numbers.current,
body.skin_custom_color ul.page-numbers > li span.page-numbers.current,
body.skin_custom_color .triangled_colored_separator,
body.skin_custom_color .magic_line,
body.skin_custom_color .navbar-toggle .icon-bar,
body.skin_custom_color .navbar-toggle:hover .icon-bar,
body.skin_custom_color #searchform .search-submit,
body.skin_custom_color
  .header_main_menu_wrapper
  .header-menu
  > li
  > ul.sub-menu:before,
body.skin_custom_color .search-toggler:after,
body.skin_custom_color .modal .popup_title,
body.skin_custom_color .sticky_post,
body.skin_custom_color .btn-carousel-control:after,
.primary_bg_color,
.mbc,
.stm_lms_courses_carousel_wrapper .owl-dots .owl-dot.active,
.stm_lms_courses_carousel__term.active,
body.course_hub .header_default.header_2,
.triangled_colored_separator:before,
.triangled_colored_separator:after,
body.skin_custom_color.udemy .btn-default,
.single_instructor .stm_lms_courses .stm_lms_load_more_courses,
.single_instructor .stm_lms_courses .stm_lms_load_more_courses:hover,
.stm_lms_course_sticky_panel .stm_lms_course_sticky_panel__button .btn,
.stm_lms_course_sticky_panel .stm_lms_course_sticky_panel__button .btn:hover,
body.skin_custom_color.language_center .btn-default {
  background-color: var(--color-principal);
}
.icon_box.stm_icon_box_hover_none {
  border-left-color: var(--color-principal);
}
body.skin_custom_color ul.page-numbers > li a.page-numbers:hover,
body.skin_custom_color ul.page-numbers > li a.page-numbers.current,
body.skin_custom_color ul.page-numbers > li span.page-numbers.current,
body.skin_custom_color .custom-border textarea:active,
body.skin_custom_color .custom-border input[type="text"]:active,
body.skin_custom_color .custom-border input[type="email"]:active,
body.skin_custom_color .custom-border input[type="number"]:active,
body.skin_custom_color .custom-border input[type="password"]:active,
body.skin_custom_color .custom-border input[type="tel"]:active,
body.skin_custom_color .custom-border .form-control:active,
body.skin_custom_color .custom-border textarea:focus,
body.skin_custom_color .custom-border input[type="text"]:focus,
body.skin_custom_color .custom-border input[type="email"]:focus,
body.skin_custom_color .custom-border input[type="number"]:focus,
body.skin_custom_color .custom-border input[type="password"]:focus,
body.skin_custom_color .custom-border input[type="tel"]:focus,
body.skin_custom_color .custom-border .form-control:focus,
body.skin_custom_color .icon-btn:hover .icon_in_btn,
body.skin_custom_color .icon-btn:hover,
body.skin_custom_color .average_rating_unit,
body.skin_custom_color blockquote,
body.skin_custom_color .tp-caption .icon-btn:hover .icon_in_btn,
body.skin_custom_color .tp-caption .icon-btn:hover,
body.skin_custom_color .stm_theme_wpb_video_wrapper .stm_video_preview:after,
body.skin_custom_color .btn-carousel-control,
body.skin_custom_color
  .post_list_main_section_wrapper
  .post_list_meta_unit
  .post_list_comment_num,
body.skin_custom_color .post_list_main_section_wrapper .post_list_meta_unit,
body.skin_custom_color .search-toggler:hover,
body.skin_custom_color .search-toggler,
.stm_lms_courses_carousel_wrapper .owl-dots .owl-dot.active,
.triangled_colored_separator .triangle:before {
  border-color: var(--color-principal);
}
body.skin_custom_color .icon_box .icon i,
body.skin_custom_color .icon-btn:hover .icon_in_btn,
body.skin_custom_color .icon-btn:hover .link-title,
body.skin_custom_color .stats_counter .h1,
body.skin_custom_color .event_date_info .event_date_info_unit .event_labels,
body.skin_custom_color .event-col .event_archive_item .event_location i,
body.skin_custom_color .event-col .event_archive_item .event_start i,
body.skin_custom_color .gallery_terms_list li.active a,
body.skin_custom_color .tp-caption .icon-btn:hover .icon_in_btn,
body.skin_custom_color .teacher_single_product_page > a:hover .title,
body.skin_custom_color .sidebar-area .widget ul li a:hover:after,
body.skin_custom_color div.pp_woocommerce .pp_gallery ul li a:hover,
body.skin_custom_color div.pp_woocommerce .pp_gallery ul li.selected a,
body.skin_custom_color .single_product_after_title .meta-unit i,
body.skin_custom_color .single_product_after_title .meta-unit .value a:hover,
body.skin_custom_color .woocommerce-breadcrumb a:hover,
body.skin_custom_color #footer_copyright .copyright_text a:hover,
body.skin_custom_color .widget_stm_recent_posts .widget_media .cats_w a:hover,
body.skin_custom_color .widget_pages ul.style_2 li a:hover,
body.skin_custom_color .sidebar-area .widget_categories ul li a:hover,
body.skin_custom_color .sidebar-area .widget ul li a:hover,
body.skin_custom_color .widget_categories ul li a:hover,
body.skin_custom_color .stm_product_list_widget li a:hover .title,
body.skin_custom_color .widget_contacts ul li .text a:hover,
body.skin_custom_color .sidebar-area .widget_pages ul.style_1 li a:focus .h6,
body.skin_custom_color .sidebar-area .widget_nav_menu ul.style_1 li a:focus .h6,
body.skin_custom_color .sidebar-area .widget_pages ul.style_1 li a:focus,
body.skin_custom_color .sidebar-area .widget_nav_menu ul.style_1 li a:focus,
body.skin_custom_color .sidebar-area .widget_pages ul.style_1 li a:active .h6,
body.skin_custom_color
  .sidebar-area
  .widget_nav_menu
  ul.style_1
  li
  a:active
  .h6,
body.skin_custom_color .sidebar-area .widget_pages ul.style_1 li a:active,
body.skin_custom_color .sidebar-area .widget_nav_menu ul.style_1 li a:active,
body.skin_custom_color .sidebar-area .widget_pages ul.style_1 li a:hover .h6,
body.skin_custom_color .sidebar-area .widget_nav_menu ul.style_1 li a:hover .h6,
body.skin_custom_color .sidebar-area .widget_pages ul.style_1 li a:hover,
body.skin_custom_color .sidebar-area .widget_nav_menu ul.style_1 li a:hover,
body.skin_custom_color .widget_pages ul.style_1 li a:focus .h6,
body.skin_custom_color .widget_nav_menu ul.style_1 li a:focus .h6,
body.skin_custom_color .widget_pages ul.style_1 li a:focus,
body.skin_custom_color .widget_nav_menu ul.style_1 li a:focus,
body.skin_custom_color .widget_pages ul.style_1 li a:active .h6,
body.skin_custom_color .widget_nav_menu ul.style_1 li a:active .h6,
body.skin_custom_color .widget_pages ul.style_1 li a:active,
body.skin_custom_color .widget_nav_menu ul.style_1 li a:active,
body.skin_custom_color .widget_pages ul.style_1 li a:hover .h6,
body.skin_custom_color .widget_nav_menu ul.style_1 li a:hover .h6,
body.skin_custom_color .widget_pages ul.style_1 li a:hover,
body.skin_custom_color .widget_nav_menu ul.style_1 li a:hover,
body.skin_custom_color .see_more a:after,
body.skin_custom_color .see_more a,
body.skin_custom_color
  .transparent_header_off
  .header_main_menu_wrapper
  ul
  > li
  > ul.sub-menu
  > li
  a:hover,
body.skin_custom_color .stm_breadcrumbs_unit .navxtBreads > span a:hover,
body.skin_custom_color .btn-carousel-control,
body.skin_custom_color
  .post_list_main_section_wrapper
  .post_list_meta_unit
  .post_list_comment_num,
body.skin_custom_color
  .post_list_main_section_wrapper
  .post_list_meta_unit
  .date-m,
body.skin_custom_color
  .post_list_main_section_wrapper
  .post_list_meta_unit
  .date-d,
body.skin_custom_color .stats_counter h1,
body.skin_custom_color .yellow,
body.skin_custom_color ol li a:hover,
body.skin_custom_color ul li a:hover,
body.skin_custom_color .search-toggler,
.primary_color,
.mtc_h:hover,
body.classic_lms .header_top_bar .header_top_bar_socs ul li a:hover,
body.classic_lms .header_top_bar a:hover,
#footer .widget_stm_lms_popular_courses ul li a:hover .meta .h5.title,
body.classic_lms .stm_lms_wishlist_button a:hover i,
.classic_lms .post_list_main_section_wrapper .post_list_item_title:hover,
.stm_lms_courses__single.style_2 .stm_lms_courses__single--title h5:hover {
  color: var(--color-principal);
}
body.skin_custom_color .triangled_colored_separator .triangle,
body.skin_custom_color .magic_line:after {
  border-bottom-color: var(--color-principal);
}
body.rtl-demo
  .stm_testimonials_wrapper_style_2
  .stm_lms_testimonials_single__content:after {
  border-left-color: var(--color-principal);
}
body.skin_custom_color .blog_layout_grid .post_list_meta_unit .sticky_post,
body.skin_custom_color .blog_layout_list .post_list_meta_unit .sticky_post,
body.skin_custom_color .product_status.special,
body.skin_custom_color .view_type_switcher a:hover,
body.skin_custom_color .view_type_switcher a.view_list.active_list,
body.skin_custom_color .view_type_switcher a.view_grid.active_grid,
body.skin_custom_color
  .stm_archive_product_inner_unit
  .stm_archive_product_inner_unit_centered
  .stm_featured_product_price
  .price,
body.skin_custom_color .sidebar-area .widget_text .btn,
body.skin_custom_color
  .stm_product_list_widget.widget_woo_stm_style_2
  li
  a
  .meta
  .stm_featured_product_price
  .price,
body.skin_custom_color .widget_tag_cloud .tagcloud a:hover,
body.skin_custom_color .sidebar-area .widget ul li a:after,
body.skin_custom_color
  .sidebar-area
  .socials_widget_wrapper
  .widget_socials
  li
  a,
body.skin_custom_color .socials_widget_wrapper .widget_socials li a,
body.skin_custom_color .gallery_single_view .gallery_img a:after,
body.skin_custom_color .course_table tr td.stm_badge .badge_unit,
body.skin_custom_color .widget_mailchimp .stm_mailchimp_unit .button,
body.skin_custom_color .textwidget .btn:active,
body.skin_custom_color .textwidget .btn:focus,
body.skin_custom_color .form-submit .submit:active,
body.skin_custom_color .form-submit .submit:focus,
body.skin_custom_color .button:focus,
body.skin_custom_color .button:active,
body.skin_custom_color .btn-default:active,
body.skin_custom_color .btn-default:focus,
body.skin_custom_color .button:hover,
body.skin_custom_color .textwidget .btn:hover,
body.skin_custom_color .form-submit .submit,
body.skin_custom_color .button,
body.skin_custom_color .btn-default,
.btn.btn-default:hover,
.button:hover,
.textwidget .btn:hover,
body.skin_custom_color .short_separator,
body.skin_custom_color div.multiseparator:after,
body.skin_custom_color .widget_pages ul.style_2 li a:hover:after,
body.skin_custom_color.single-product
  .product
  .woocommerce-tabs
  .wc-tabs
  li.active
  a:before,
body.skin_custom_color.woocommerce .sidebar-area .widget .widget_title:after,
body.skin_custom_color.woocommerce
  .sidebar-area
  .widget.widget_price_filter
  .price_slider_wrapper
  .price_slider
  .ui-slider-handle,
body.skin_custom_color.woocommerce
  .sidebar-area
  .widget.widget_price_filter
  .price_slider_wrapper
  .price_slider
  .ui-slider-range,
.sbc,
.sbc_h:hover,
.wpb-js-composer
  .vc_general.vc_tta.vc_tta-tabs.vc_tta-style-classic
  li.vc_tta-tab
  > a,
.wpb-js-composer
  .vc_general.vc_tta.vc_tta-tabs.vc_tta-style-classic
  li.vc_tta-tab
  > a:hover,
#header.transparent_header .header_2 .stm_lms_account_dropdown .dropdown button,
.stm_lms_courses_categories.style_3 .stm_lms_courses_category > a:hover,
.stm_lms_udemy_course .nav.nav-tabs > li a,
body.classic_lms .classic_style .nav.nav-tabs > li.active a,
.header_bottom:after,
.sbc:hover,
body.rtl-demo
  .stm_testimonials_wrapper_style_2
  .stm_lms_testimonials_single__content {
  background-color: var(--color-principal);
}
body.skin_custom_color.woocommerce
  .sidebar-area
  .widget.widget_layered_nav
  ul
  li
  a:after,
body.skin_custom_color.woocommerce
  .sidebar-area
  .widget.widget_product_categories
  ul
  li
  a:after,
body.skin_custom_color .wpb_tabs .form-control:focus,
body.skin_custom_color .wpb_tabs .form-control:active,
body.skin_custom_color
  .woocommerce
  .cart-totals_wrap
  .shipping-calculator-button,
body.skin_custom_color .sidebar-area .widget_text .btn,
body.skin_custom_color .widget_tag_cloud .tagcloud a:hover,
body.skin_custom_color .icon_box.dark a:hover,
body.skin_custom_color .simple-carousel-bullets a.selected,
body.skin_custom_color .stm_sign_up_form .form-control:active,
body.skin_custom_color .stm_sign_up_form .form-control:focus,
body.skin_custom_color .form-submit .submit,
body.skin_custom_color .button,
body.skin_custom_color .btn-default,
.sbrc,
.sbrc_h:hover,
.vc_general.vc_tta.vc_tta-tabs,
body.skin_custom_color .blog_layout_grid .post_list_meta_unit,
body.skin_custom_color
  .blog_layout_grid
  .post_list_meta_unit
  .post_list_comment_num,
body.skin_custom_color
  .blog_layout_list
  .post_list_meta_unit
  .post_list_comment_num,
body.skin_custom_color .blog_layout_list .post_list_meta_unit,
#header.transparent_header
  .header_2
  .stm_lms_account_dropdown
  .dropdown
  button {
  border-color: var(--color-principal);
}
.header_2_top_bar__inner .top_bar_right_part .header_top_bar_socs ul li a:hover,
.secondary_color,
body.skin_custom_color.single-product
  .product
  .woocommerce-tabs
  .wc-tabs
  li.active
  a,
body.skin_custom_color.single-product
  .product
  .woocommerce-tabs
  .wc-tabs
  li
  a:hover,
body.skin_custom_color .widget_pages ul.style_2 li a:hover .h6,
body.skin_custom_color .icon_box .icon_text > h3 > span,
body.skin_custom_color
  .stm_woo_archive_view_type_list
  .stm_featured_product_stock
  i,
body.skin_custom_color
  .stm_woo_archive_view_type_list
  .expert_unit_link:hover
  .expert,
body.skin_custom_color
  .stm_archive_product_inner_unit
  .stm_archive_product_inner_unit_centered
  .stm_featured_product_body
  a
  .title:hover,
body.skin_custom_color
  .stm_product_list_widget.widget_woo_stm_style_2
  li
  a:hover
  .title,
body.skin_custom_color
  .blog_layout_grid
  .post_list_meta_unit
  .post_list_comment_num,
body.skin_custom_color .blog_layout_grid .post_list_meta_unit .date-m,
body.skin_custom_color .blog_layout_grid .post_list_meta_unit .date-d,
body.skin_custom_color
  .blog_layout_list
  .post_list_meta_unit
  .post_list_comment_num,
body.skin_custom_color .blog_layout_list .post_list_meta_unit .date-m,
body.skin_custom_color .blog_layout_list .post_list_meta_unit .date-d,
body.skin_custom_color .widget_stm_recent_posts .widget_media a:hover .h6,
body.skin_custom_color .widget_product_search .woocommerce-product-search:after,
body.skin_custom_color .widget_search .search-form > label:after,
body.skin_custom_color .sidebar-area .widget ul li a,
body.skin_custom_color .sidebar-area .widget_categories ul li a,
body.skin_custom_color .widget_contacts ul li .text a,
body.skin_custom_color .event-col .event_archive_item > a:hover .title,
body.skin_custom_color .stm_contact_row a:hover,
body.skin_custom_color .comments-area .commentmetadata i,
body.skin_custom_color
  .stm_post_info
  .stm_post_details
  .comments_num
  .post_comments:hover,
body.skin_custom_color
  .stm_post_info
  .stm_post_details
  .comments_num
  .post_comments
  i,
body.skin_custom_color
  .stm_post_info
  .stm_post_details
  .post_meta
  li
  a:hover
  span,
body.skin_custom_color .stm_post_info .stm_post_details .post_meta li i,
body.skin_custom_color
  .blog_layout_list
  .post_list_item_tags
  .post_list_divider,
body.skin_custom_color .blog_layout_list .post_list_item_tags a,
body.skin_custom_color .blog_layout_list .post_list_cats .post_list_divider,
body.skin_custom_color .blog_layout_list .post_list_cats a,
body.skin_custom_color .blog_layout_list .post_list_item_title a:hover,
body.skin_custom_color
  .blog_layout_grid
  .post_list_item_tags
  .post_list_divider,
body.skin_custom_color .blog_layout_grid .post_list_item_tags a,
body.skin_custom_color .blog_layout_grid .post_list_cats .post_list_divider,
body.skin_custom_color .blog_layout_grid .post_list_cats a,
body.skin_custom_color .blog_layout_grid .post_list_item_title:focus,
body.skin_custom_color .blog_layout_grid .post_list_item_title:active,
body.skin_custom_color .blog_layout_grid .post_list_item_title:hover,
body.skin_custom_color
  .stm_featured_products_unit
  .stm_featured_product_single_unit
  .stm_featured_product_single_unit_centered
  .stm_featured_product_body
  a
  .title:hover,
body.skin_custom_color .icon_box.dark a:hover,
body.skin_custom_color
  .post_list_main_section_wrapper
  .post_list_item_tags
  .post_list_divider,
body.skin_custom_color .post_list_main_section_wrapper .post_list_item_tags a,
body.skin_custom_color
  .post_list_main_section_wrapper
  .post_list_cats
  .post_list_divider,
body.skin_custom_color .post_list_main_section_wrapper .post_list_cats a,
body.skin_custom_color
  .post_list_main_section_wrapper
  .post_list_item_title:active,
body.skin_custom_color
  .post_list_main_section_wrapper
  .post_list_item_title:focus,
body.skin_custom_color
  .post_list_main_section_wrapper
  .post_list_item_title:hover,
body.skin_custom_color a:hover,
.secondary_color,
#header.transparent_header
  .header_2
  .header_top
  .stm_lms_categories
  .heading_font,
#header.transparent_header .header_2 .header_top .stm_lms_categories i,
.classic_lms .post_list_main_section_wrapper .post_list_cats a,
.classic_lms .post_list_main_section_wrapper .post_list_item_tags a,
body.skin_custom_color
  .single_product_after_title
  .meta-unit.teacher:hover
  .value,
.stm_lms_course_sticky_panel__teacher:before,
.stm_lms_courses__single__inner
  .stm_lms_courses__single--info_title
  a:hover
  h4 {
  color: var(--color-principal);
}
a {
  color: #2d4649;
}
body,
.normal_font,
.h6.normal_font,
body.rtl.rtl-demo
  .stm_testimonials_wrapper_style_2
  .stm_lms_testimonials_single__excerpt
  p,
.stm_product_list_widget.widget_woo_stm_style_2 li a .meta .title {
  font-family: "Open Sans";
  color: #222222;
  font-size: 16px;
}
.btn {
  font-family: "Roboto Slab";
  line-height: 14px;
  font-size: 14px;
}
.header-menu {
  font-family: "Roboto Slab";
  font-weight: 700;
  color: #222222;
}
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.nav-tabs > li > a,
.member-name,
.section-title,
.user-name,
.heading_font,
.item-title,
.acomment-meta,
[type="reset"],
.bp-subnavs,
.activity-header,
table,
.widget_categories ul li a,
.sidebar-area .widget ul li a,
.select2-selection__rendered,
blockquote,
.select2-chosen,
.vc_tta-tabs.vc_tta-tabs-position-top
  .vc_tta-tabs-container
  .vc_tta-tabs-list
  li.vc_tta-tab
  a,
.vc_tta-tabs.vc_tta-tabs-position-left
  .vc_tta-tabs-container
  .vc_tta-tabs-list
  li.vc_tta-tab
  a,
body.distance-learning .btn,
body.distance-learning .vc_btn3 {
  font-family: "Roboto Slab";
  color: #222222;
}
h1,
.h1 {
  line-height: 72px;
  letter-spacing: -0.4px;
  font-weight: 700;
  font-size: 35px;
}
h2,
.h2 {
  line-height: 38px;
  letter-spacing: -0.5px;
  font-weight: 700;
  font-size: 32px;
}
h3,
.h3 {
  line-height: 34px;
  letter-spacing: -1px;
  font-weight: 700;
  font-size: 26px;
}
h4,
.h4,
blockquote {
  line-height: 26px;
  font-weight: 700;
  font-size: 20px;
}
h5,
.h5,
.select2-selection__rendered {
  line-height: 20px;
  font-size: 14px;
}
h6,
.h6,
.widget_pages ul li a,
.widget_nav_menu ul li a,
.footer_menu li a,
.widget_categories ul li a,
.sidebar-area .widget ul li a {
  line-height: 12px;
  font-weight: 400;
  font-size: 12px;
}
#footer_top {
  background-color: var(--color-principal);
}
#footer_bottom {
  background-color: var(--color-principal);
}
#footer_bottom .widget_title h3 {
  font-weight: 700;
  color: #ffffff;
  font-size: 18px;
}
#footer_bottom,
.widget_contacts ul li .text,
.footer_widgets_wrapper .widget ul li a,
.widget_nav_menu ul.style_1 li a .h6,
.widget_pages ul.style_2 li a .h6,
#footer .stm_product_list_widget.widget_woo_stm_style_2 li a .meta .title,
.widget_pages ul.style_1 li a .h6 {
  color: #ffffff;
}
.widget_pages ul.style_2 li a:after {
  background-color: #ffffff;
}
.vc_row {
  margin-bottom: 0px !important;
}

.breadcrumbs_holder {
  margin-bottom: 0px !important;
}

.vc_row-has-fill > .vc_column_container > .vc_column-inner {
  padding-top: 0px !important;
}
#yikes-mailchimp-container-1 h3 {
  color: #015c31;
  text-align: center;
}
#yikes-easy-mc-form-1-EMAIL {
  color: #ffffff;
  border-radius: 5px;
  border-width: 0;
  background-color: #00c57e;
}
.yikes-easy-mc-submit-button,
.EMAIL-label {
  color: #015c31;
  font-weight: bold;
}
.yikes-easy-mc-submit-button {
  border-color: #015c31;
  border-radius: 5px;
}
.yikes-easy-mc-submit-button:hover {
  background-color: #00c57e;
  border-width: 0;
}

.vc_custom_1599160349848 {
  background-color: #ebebeb !important;
  background-position: 0 0 !important;
  background-repeat: no-repeat !important;
}
.vc_custom_1606336620928 {
  padding-top: 44px !important;
  padding-bottom: 15px !important;
  background-color: #ffffff !important;
}
.vc_custom_1606338135953 {
  margin-bottom: 0px !important;
  padding-top: 30px !important;
  padding-bottom: 30px !important;
  background-color: #ebebeb !important;
}
.vc_custom_1599168593152 {
  background-color: #ffffff !important;
}
.vc_custom_1606338282299 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
  background-color: #f1f6fb !important;
}
.vc_custom_1599160529651 {
  margin-bottom: 0px !important;
  padding-top: 0px !important;
}
.vc_custom_1606852709678 {
  margin-bottom: 30px !important;
}

.wpb_animate_when_almost_visible {
  opacity: 1;
}

#rev_slider_10_1_wrapper {
  height: 566.1290322580645px;
}
#rev_slider_11_2_wrapper {
  height: 424.5967741935484px;
}
#rev_slider_12_3_wrapper {
  height: 471.77419354838713px;
}
#rev_slider_13_4_wrapper {
  height: 518.9516129032259px;
}

.fancybox-margin {
  margin-right: 17px;
}

@media (max-width: 1440px) {
  .a253ce49a9d9419793ee9d3b7ffb0706e {
    height: 30pxpx !important;
  }
}

@media (max-width: 1024px) {
  .a253ce49a9d9419793ee9d3b7ffb0706e {
    height: 20pxpx !important;
  }
}

@media (max-width: 768px) {
  .a253ce49a9d9419793ee9d3b7ffb0706e {
    height: 0pxpx !important;
  }
}

@media (min-width: 768px) {
  .elementor-column-gap-default,
  .elementor-column-gap-default .elementor-row .elementor-column {
    padding: 0 15px;
  }

  .elementor-column-gap-default .elementor-row {
    margin: 0 -15px !important;
    width: calc(100% + 30px) !important;
  }

  .elementor-column-gap-default
    .elementor-row
    .elementor-column
    > .elementor-element-populated,
  .elementor-column-gap-default
    .elementor-row
    .elementor-row
    .elementor-column:first-child:last-child {
    padding: 0;
  }

  .elementor-column-gap-default
    .elementor-row
    .elementor-row
    .elementor-column:first-child {
    padding-left: 0;
  }

  .elementor-column-gap-default
    .elementor-row
    .elementor-row
    .elementor-column:last-child {
    padding-right: 0;
  }

  #main
    .elementor-section.elementor-section-boxed:not(.elementor-section-stretched)
    > .elementor-container {
    margin: 0 -15px;
  }
}

.elementor-container .stm_lms_courses_carousel__buttons .fa {
  font-weight: 900;
}

.elementor-tab-title,
.elementor-tab-content,
.elementor-tabs-content-wrapper,
.elementor-tab-title::after {
  border: 0 none !important;
}

.elementor-tabs {
  border-top: 3px solid var(--color-principal);
}

.elementor-tabs-wrapper {
  display: flex;
  margin-bottom: 30px;
}

.elementor-tab-title {
  display: inline-block;
  flex-grow: 1;
  text-align: center;
  text-transform: uppercase;
  font-size: 15px;
  font-family: Roboto Slab;
}

.elementor-tab-title:not(.elementor-active) {
  background-color: var(--color-principal);
  color: #fff;
}

.elementor-tab-title:not(.elementor-active) a:hover {
  color: #fff !important;
}

.elementor-tab-title.elementor-active {
  color: #273044;
}

.elementor-tab-content {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.stm_lms_courses_categories.style_1 .stm_lms_courses_category__image {
  background-color: rgba(0, 0, 0, 0.4);
}

.stm_lms_lazy_image img {
  height: 100%;
}

.elementor-widget-tabs.elementor-tabs-view-vertical
  .elementor-tab-desktop-title {
  -webkit-writing-mode: vertical-lr;
      -ms-writing-mode: tb-lr;
          writing-mode: vertical-lr;
  -webkit-text-orientation: mixed;
          text-orientation: mixed;
}

.elementor-widget-tabs.elementor-tabs-view-vertical .elementor-tab-content {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.elementor-editor-active .select2-container .select2-selection--single {
  height: 45px;
}

.elementor-editor-active
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 42px;
}

.elementor-editor-active
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 45px;
  width: 48px;
}

.elementor-editor-active
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  border: 0;
  line-height: 45px;
  text-align: center;
}

header#masthead .menu-item a.hfe-sub-menu-item,
header#masthead .menu-item a.hfe-menu-item {
  font-family: Roboto Slab;
}

a.hfe-sub-menu-item:hover,
a.hfe-menu-item:hover {
  color: var(--color-principal);
}

@media (max-width: 767px) {
  .stm_lms_wishlist_button a,
  .masterstudy_elementor_stm_lms_login a {
    background-color: var(--color-principal);
  }
}

#footer {
  position: fixed !important;
}
footer a span.h6,
footer a span.h6:hover {
  color: white !important;
  font-weight: bold;
  font-size: 14px;
}

.entry-header {
  background: url(https://wp2.cursosdeofimatica.com/wp-content/uploads/2020/08/Barra-Cursos-1919x169.jpg)
    no-repeat;
}

.entry-title h2 {
  margin: 0;
  padding: 0;
  color: white;
  line-height: 72px;
  letter-spacing: -0.4px;
  font-weight: 700;
  font-size: 35px;
}

.widget_stm_recent_posts .cats_w {
  display: none;
}

img.wp-smiley,
img.emoji {
  display: inline !important;
  border: none !important;
  box-shadow: none !important;
  height: 1em !important;
  width: 1em !important;
  margin: 0 0.07em !important;
  vertical-align: -0.1em !important;
  background: none !important;
  padding: 0 !important;
}

.correctly_answered label {
  color: #5cb85c !important;
}

.vue_is_disabled {
  display: none;
}

.yikes-easy-mc-form label.label-inline {
  float: left;
  width: 79%;
  padding-right: 10px;
}

/* Drop the submit button (100% width) below the fields */
@media (max-width: 768px) {
  .yikes-easy-mc-form label.label-inline {
    width: 99%;
    padding-right: 10px;
  }

  .yikes-easy-mc-form .submit-button-inline-label {
    width: 100%;
    float: none;
  }

  .yikes-easy-mc-form .submit-button-inline-label .empty-label {
    display: none;
  }
}

/* Stack all fields @ 100% */
@media (max-width: 650px) {
  .yikes-easy-mc-form label.label-inline {
    width: 100%;
  }
}

/*@import "rs6.css";*/
/* 
*{
	overflow-x: hidden !important;
}
 */
.cursos-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

.cursos-list img {
  width: 100%;
}

.cursos-list .curso {
  text-align: center;
}

.cursos-landing-section {
  margin-top: 2em;
}

.excel-info {
  width: 100%;
  background-image: url(/static/media/Banner-02-solo.efa1f687.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: grid;
  grid-template-columns: 1fr 3fr 3fr;
  grid-template-areas: "cursos personaje texto";
}

.cursos-logo {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-area: cursos;
}

.personaje-excel {
  grid-area: personaje;
}

.texto-excel {
  grid-area: "texto";
}

/*
================
Utilizar todo rs
================
*/
.rs-fullwidth-wrap {
  z-index: 0;
}

.rs-fullwidth-wrap {
  position: relative;
  width: 100%;
  height: auto;
  display: block;
  overflow: visible;
  max-width: none !important;
}

.rs-layer-wrap,
.rs-mask,
.rs-module-wrap,
.rs-module .rs-layer,
.rs-module img {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.rs-module-wrap {
  visibility: hidden;
}
.rs-module-wrap,
.rs-module-wrap * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rs-module-wrap {
  position: relative;
  z-index: 1;
  width: 100%;
  display: block;
}

.rs-fw-forcer {
  display: block;
  width: 100%;
  pointer-events: none;
}
.rs-module {
  position: relative;
  overflow: hidden;
  display: block;
}

.rs-loader.spinner0 {
  width: 40px;
  height: 40px;
  background-color: #fff;
  /*background-image: url(../assets/loader.gif);*/
  background-repeat: no-repeat;
  background-position: center center;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  margin-top: -20px;
  margin-left: -20px;
  -webkit-animation: tp-rotateplane 1.2s infinite ease-in-out;
          animation: tp-rotateplane 1.2s infinite ease-in-out;
  border-radius: 3px;
}

.rs-loader {
  top: 50%;
  left: 50%;
  z-index: 10000;
  position: absolute;
}

.rs-progress {
  visibility: hidden;
  position: absolute;
  z-index: 200;
  width: 100%;
  height: 100%;
}
.rs-slides,
.rs-slide,
.rs-slide::before {
  position: absolute;
  text-indent: 0em;
  top: 0px;
  left: 0px;
}

rs-progress-bar,
.rs-progress-bar {
  display: block;
  z-index: 20;
  box-sizing: border-box;
  background-clip: content-box;
  position: absolute;
  /* vertical-align: top; */
  line-height: 0px;
  width: 100%;
  height: 100%;
}

/*

Clases e Ids personalizadas para landing

*/

.landing_excel_1 {
  background: transparent none repeat scroll 0% 0%;
  padding: 0px;
  margin: 0px auto;
  height: 600px;
  visibility: visible;
  position: absolute;
  overflow: visible;
  width: 1263px;
  left: -47px;
}

.landing_excel_2 {
  height: 600px;
  max-height: 600px;
  margin-bottom: 0px;
  margin-top: 0px;
}

.landing_excel_3 {
  background: rgba(255, 255, 255, 0.5) none repeat scroll 0% 0%;
}

.landing_excel_4 {
  visibility: visible;
  max-height: none;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.landing_excel_5 {
  overflow: hidden;
  height: 100%;
  width: 100%;
  z-index: 20;
  opacity: 1;
  visibility: inherit;
}

.landing_excel_6 {
  -webkit-transform-origin: 50% 50% 0px;
          transform-origin: 50% 50% 0px;
  visibility: inherit;
  opacity: 1;
  -webkit-transform: perspective(600px);
          transform: perspective(600px);
}

.landing_excel_7 {
  background-color: transparent;
  background-repeat: no-repeat;
  /* background-image: url("file:///wp-content/uploads/2020/09/Fondo-Banner-Principal-1536x568.jpg"); */
  background-size: cover;
  background-position: 50% 50%;
  width: 100%;
  height: 100%;
  opacity: 1;
  -webkit-transform: translate(0px);
          transform: translate(0px);
  visibility: inherit;
  z-index: 20;
}

.landing_excel_8 {
  position: absolute;
  display: block;
  pointer-events: auto;
  left: 15.5px;
  top: 75px;
  z-index: 5;
}

.landing_excel_9 {
  position: absolute;
  display: block;
  overflow: visible;
}

.landing_excel_10 {
  z-index: 5;
  color: rgb(255, 255, 255);
  width: 690px;
  height: 523px;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  border-color: rgb(34, 34, 34);
  -webkit-transform-origin: 50% 50% 0px;
          transform-origin: 50% 50% 0px;
  opacity: 1;
  -webkit-transform: translate(0px);
          transform: translate(0px);
  visibility: visible;
}

.landing_excel_10.imagen {
  position: relative;
  height: 100%;
  width: 100%;
}

.landing_excel_11 {
  position: absolute;
  display: block;
  pointer-events: auto;
  left: 699.5px;
  top: 325px;
  z-index: 6;
}

.landing_excel_12 {
  z-index: 6;
  font-family: "Montserrat";
  height: auto;
  color: rgb(59, 59, 61);
  text-decoration: none;
  white-space: normal;
  width: 429px;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  text-align: left;
  line-height: 37px;
  letter-spacing: 0px;
  font-weight: 400;
  font-size: 18px;
  border-color: rgb(34, 34, 34);
  -webkit-transform-origin: 50% 50% 0px;
          transform-origin: 50% 50% 0px;
  opacity: 1;
  -webkit-transform: translate(0px);
          transform: translate(0px);
  visibility: visible;
}

.landing_excel_13 {
  position: absolute;
  display: block;
  pointer-events: auto;
  left: 696.5px;
  top: 162px;
  z-index: 7;
}

.landing_excel_14 {
  z-index: 7;
  font-family: Montserrat;
  width: auto;
  color: rgb(0, 0, 0);
  text-decoration: none;
  white-space: nowrap;
  height: 128px;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  text-align: left;
  line-height: 38px;
  letter-spacing: 0px;
  font-weight: 700;
  font-size: 72px;
  border-color: rgb(34, 34, 34);
  -webkit-transform-origin: 50% 50% 0px;
          transform-origin: 50% 50% 0px;
  opacity: 1;
  -webkit-transform: translate(0px);
          transform: translate(0px);
  visibility: visible;
}

.landing_excel_15 {
  position: absolute;
  display: block;
  pointer-events: auto;
  left: 702.5px;
  top: 372px;
  z-index: 6;
}

.landing_excel_16 {
  z-index: 6;
  font-family: Montserrat;
  height: auto;
  color: rgb(59, 59, 61);
  text-decoration: none;
  white-space: normal;
  width: 486px;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  text-align: left;
  line-height: 25px;
  letter-spacing: 0px;
  font-weight: 400;
  font-size: 18px;
  border-color: rgb(34, 34, 34);
  -webkit-transform-origin: 50% 50% 0px;
          transform-origin: 50% 50% 0px;
  opacity: 1;
  -webkit-transform: translate(0px);
          transform: translate(0px);
  visibility: visible;
}

.landing_excel_17 {
  position: relative;
  left: -31.5px;
  box-sizing: border-box;
  width: 1263px;
  padding-left: 31.5px;
  padding-right: 31.5px;
}

.landing_excel_18 {
  font-size: 20px;
  color: #0a0a0a;
  line-height: 1px;
  text-align: center;
  font-family: Abel;
  font-weight: 400;
  font-style: normal;
}

.landing_excel_19 {
  font-size: 20px;
  color: #000000;
  line-height: 1px;
  text-align: center;
  font-family: Abel;
  font-weight: 400;
  font-style: normal;
}

.landing_excel_20 {
  font-size: 20px;
  color: #000000;
  line-height: 1px;
  text-align: center;
  font-family: Abel;
  font-weight: 400;
  font-style: normal;
}

.landing_excel_21 {
  position: relative;
  left: -31.5px;
  box-sizing: border-box;
  width: 1263px;
  padding-left: 31.5px;
  padding-right: 31.5px;
}

.landing_excel_22 {
  font-size: 35px;
  color: #000000;
  text-align: center;
  font-family: Montserrat;
  font-weight: 700;
  font-style: normal;
}

.landing_excel_23 {
  position: relative;
  left: -31.5px;
  box-sizing: border-box;
  width: 1263px;
  padding-left: 31.5px;
  padding-right: 31.5px;
}

.landing_excel_24 {
  background: transparent none repeat scroll 0% 0%;
  padding: 0px;
  margin: 0px auto;
  height: 450px;
  visibility: visible;
  position: absolute;
  overflow: visible;
  width: 1263px;
  left: -47px;
}

.landing_excel_25 {
  height: 450px;
  max-height: 450px;
  margin-bottom: 0px;
  margin-top: 0px;
}

.landing_excel_26 {
  background: rgba(255, 255, 255, 0.5) none repeat scroll 0% 0%;
}

.landing_excel_27 {
  visibility: visible;
  max-height: none;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.landing_excel_28 {
	overflow: hidden;
	height: 100%;
	width: 100%;
	z-index: 20;
	opacity: 1;
	visibility: inherit;
	background-image: url('http://wp2.cursosdeofimatica.com/wp-content/uploads/2020/09/Banner-02-solo.jpg');
}

.landing_excel_29 {
  -webkit-transform-origin: 50% 50% 0px;
          transform-origin: 50% 50% 0px;
  visibility: inherit;
  opacity: 1;
  -webkit-transform: perspective(600px);
          transform: perspective(600px);
}

.landing_excel_30 {
  background-color: transparent;
  background-repeat: no-repeat; /*  background-image: url('file:///wp-content/uploads/2020/09/Banner-02-solo.jpg'); */
  background-size: cover;
  background-position: 50% 50%;
  width: 100%;
  height: 100%;
  opacity: 1;
  -webkit-transform: translate(0px);
          transform: translate(0px);
  visibility: inherit;
  z-index: 20;
}

.landing_excel_31 {
  position: absolute;
  display: block;
  pointer-events: auto;
  left: 32.5px;
  top: 68px;
  z-index: 8;
}

.landing_excel_32 {
  z-index: 8;
  color: rgb(255, 255, 255);
  width: 103px;
  height: 97px;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  border-color: rgb(34, 34, 34);
  -webkit-transform-origin: 50% 50% 0px;
          transform-origin: 50% 50% 0px;
  opacity: 1;
  -webkit-transform: translate(0px);
          transform: translate(0px);
  visibility: visible;
}

.landing_excel_33 {
  position: absolute;
  display: block;
  pointer-events: auto;
  left: 161.5px;
  top: 68px;
  z-index: 9;
}

.landing_excel_34 {
  z-index: 9;
  color: rgb(255, 255, 255);
  width: 108px;
  height: 102px;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  border-color: rgb(34, 34, 34);
  -webkit-transform-origin: 50% 50% 0px;
          transform-origin: 50% 50% 0px;
  opacity: 1;
  -webkit-transform: translate(0px);
          transform: translate(0px);
  visibility: visible;
}

.landing_excel_35 {
  position: absolute;
  display: block;
  pointer-events: auto;
  left: 293.5px;
  top: 68px;
  z-index: 10;
}

.landing_excel_35_5 {
  z-index: 10;
  color: rgb(255, 255, 255);
  width: 106px;
  height: 100px;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  border-color: rgb(34, 34, 34);
  -webkit-transform-origin: 50% 50% 0px;
          transform-origin: 50% 50% 0px;
  opacity: 1;
  -webkit-transform: translate(0px);
          transform: translate(0px);
  visibility: visible;
}

.landing_excel_36 {
  position: absolute;
  display: block;
  pointer-events: auto;
  left: 32.5px;
  top: 196px;
  z-index: 11;
}

.landing_excel_37 {
  z-index: 11;
  color: rgb(255, 255, 255);
  width: 109px;
  height: 103px;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  border-color: rgb(34, 34, 34);
  -webkit-transform-origin: 50% 50% 0px;
          transform-origin: 50% 50% 0px;
  opacity: 1;
  -webkit-transform: translate(0px);
          transform: translate(0px);
  visibility: visible;
}

.landing_excel_38 {
  position: absolute;
  display: block;
  pointer-events: auto;
  left: 162.5px;
  top: 196px;
  z-index: 12;
}

.landing_excel_39 {
  z-index: 12;
  color: rgb(255, 255, 255);
  width: 107px;
  height: 101px;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  border-color: rgb(34, 34, 34);
  -webkit-transform-origin: 50% 50% 0px;
          transform-origin: 50% 50% 0px;
  opacity: 1;
  -webkit-transform: translate(0px);
          transform: translate(0px);
  visibility: visible;
}

.landing_excel_40 {
  position: absolute;
  display: block;
  pointer-events: auto;
  left: 231.5px;
  top: -15px;
  z-index: 13;
}

.landing_excel_41 {
  z-index: 13;
  color: rgb(255, 255, 255);
  width: 588px;
  height: 466px;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  border-color: rgb(34, 34, 34);
  -webkit-transform-origin: 50% 50% 0px;
          transform-origin: 50% 50% 0px;
  opacity: 1;
  -webkit-transform: translate(0px);
          transform: translate(0px);
  visibility: visible;
}

.landing_excel_42 {
  z-index: 14;
  font-family: Roboto;
  height: auto;
  width: auto;
  color: rgb(0, 0, 0);
  text-decoration: none;
  white-space: nowrap;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  text-align: right;
  line-height: 34px;
  letter-spacing: 0px;
  font-weight: 700;
  font-size: 30px;
  border-color: rgb(34, 34, 34);
  -webkit-transform-origin: 50% 50% 0px;
          transform-origin: 50% 50% 0px;
  opacity: 1;
  -webkit-transform: translate(0px);
          transform: translate(0px);
  visibility: visible;
}

.landing_excel_43 {
  position: absolute;
  display: block;
  pointer-events: auto;
  left: 885.5px;
  top: 152px;
  z-index: 15;
}

.landing_excel_44 {
  z-index: 15;
  font-family: Roboto;
  height: auto;
  width: auto;
  color: rgb(0, 0, 0);
  text-decoration: none;
  white-space: nowrap;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  text-align: right;
  line-height: 20px;
  letter-spacing: 0px;
  font-weight: 500;
  font-size: 18px;
  border-color: rgb(34, 34, 34);
  -webkit-transform-origin: 50% 50% 0px;
          transform-origin: 50% 50% 0px;
  opacity: 1;
  -webkit-transform: translate(0px);
          transform: translate(0px);
  visibility: visible;
}

.landing_excel_45 {
  position: absolute;
  display: block;
  pointer-events: auto;
  left: 669.5px;
  top: 212px;
  z-index: 16;
}

.landing_excel_46 {
  z-index: 16;
  font-family: Roboto;
  color: rgb(0, 0, 0);
  text-decoration: none;
  white-space: normal;
  width: 539px;
  height: 159px;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  text-align: right;
  line-height: 20px;
  letter-spacing: 0px;
  font-weight: 400;
  font-size: 16px;
  border-color: rgb(34, 34, 34);
  -webkit-transform-origin: 50% 50% 0px;
          transform-origin: 50% 50% 0px;
  opacity: 1;
  -webkit-transform: translate(0px);
          transform: translate(0px);
  visibility: visible;
}

.landing_excel_47 {
  position: absolute;
  display: block;
  pointer-events: auto;
  left: 912.5px;
  top: 371px;
  z-index: 17;
}

.landing_excel_48 {
  z-index: 17;
  background: rgba(0, 0, 0, 0)
    linear-gradient(rgb(255, 219, 76) 0%, rgb(255, 205, 2) 100%) repeat scroll
    0% 0%;
  font-family: Roboto;
  cursor: pointer;
  color: rgb(0, 0, 0);
  text-decoration: none;
  white-space: normal;
  width: 293px;
  height: 51px;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  text-align: center;
  line-height: 50px;
  letter-spacing: 0px;
  font-weight: 500;
  font-size: 20px;
  border-color: transparent;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 10px 20px 0px;
  padding-left: 40px;
  border-radius: 5px;
  padding-right: 40px;
  -webkit-filter: blur(0px) grayscale(0%) brightness(100%);
          filter: blur(0px) grayscale(0%) brightness(100%);
  -webkit-transform-origin: 50% 50% 0px;
          transform-origin: 50% 50% 0px;
  opacity: 1;
  -webkit-transform: translate(0px);
          transform: translate(0px);
  visibility: visible;
}

.landing_excel_49 {
  position: relative;
  left: -31.5px;
  box-sizing: border-box;
  width: 1263px;
  padding-left: 31.5px;
  padding-right: 31.5px;
}

.landing_excel_50 {
  -webkit-transform: translate3d(0px, 0px, 0px);
          transform: translate3d(0px, 0px, 0px);
  transition: all 0s ease 0s;
  /*width: 3510px;*/
}

.landing_excel_51 {
  position: relative;
  left: -31.5px;
  box-sizing: border-box;
  width: 1263px;
  padding-left: 31.5px;
  padding-right: 31.5px;
}

.landing_excel_52 {
  background: transparent none repeat scroll 0% 0%;
  padding: 0px;
  margin: 0px auto;
  height: 500px;
  visibility: visible;
  position: absolute;
  overflow: visible;
  width: 1263px;
  left: -47px;
}

.landing_excel_53 {
  height: 500px;
  max-height: 500px;
  margin-bottom: 0px;
  margin-top: 0px;
}

.landing_excel_54 {
  background-color: rgb(241, 246, 251);
  background-repeat: no-repeat;
  /*background-image: url("file:///wp-content/plugins/revslider/public/assets/assets/transparent.png");*/
  background-size: cover;
  background-position: 50% 50%;
  width: 100%;
  height: 100%;
  opacity: 1;
  -webkit-transform: translate(0px);
          transform: translate(0px);
  visibility: inherit;
  z-index: 20;
}

.landing_excel_55 {
  position: absolute;
  display: block;
  pointer-events: auto;
  left: -2.5px;
  top: 33px;
  z-index: 5;
}

.landing_excel_56 {
  z-index: 5;
  color: rgb(255, 255, 255);
  width: 470px;
  height: 570px;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  border-color: rgb(34, 34, 34);
  -webkit-transform-origin: 50% 50% 0px;
          transform-origin: 50% 50% 0px;
  opacity: 1;
  -webkit-transform: translate(0px);
          transform: translate(0px);
  visibility: visible;
}

.landing_excel_57 {
  position: absolute;
  display: block;
  pointer-events: auto;
  left: 450.5px;
  top: 15px;
  z-index: 6;
}

.landing_excel_58 {
  z-index: 6;
  font-family: Roboto;
  height: auto;
  color: rgb(0, 0, 0);
  text-decoration: none;
  white-space: normal;
  width: 647px;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  text-align: left;
  line-height: 44px;
  letter-spacing: 0px;
  font-weight: 500;
  font-size: 40px;
  border-color: rgb(34, 34, 34);
  -webkit-transform-origin: 50% 50% 0px;
          transform-origin: 50% 50% 0px;
  opacity: 1;
  -webkit-transform: translate(0px);
          transform: translate(0px);
  visibility: visible;
}

.landing_excel_59 {
  color: rgb(1, 196, 126);
  text-align: left;
  line-height: 44px;
  letter-spacing: 0px;
  font-weight: 500;
  font-size: 40px;
  border-color: rgb(1, 196, 126);
}

.landing_excel_60 {
  text-align: left;
  line-height: 44px;
  letter-spacing: 0px;
  font-weight: 500;
  font-size: 40px;
  border-color: rgb(1, 196, 126);
}

.landing_excel_61 {
  position: absolute;
  display: block;
  pointer-events: auto;
  left: 453.5px;
  top: 162px;
  z-index: 7;
}

.landing_excel_62 {
  z-index: 7;
  font-family: Roboto;
  height: auto;
  width: auto;
  color: rgb(255, 16, 0);
  text-decoration: line-through;
  white-space: nowrap;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  text-align: left;
  line-height: 24px;
  letter-spacing: 2px;
  font-weight: 400;
  font-size: 22px;
  border-color: rgb(34, 34, 34);
  -webkit-transform-origin: 50% 50% 0px;
          transform-origin: 50% 50% 0px;
  opacity: 1;
  -webkit-transform: translate(0px);
          transform: translate(0px);
  visibility: visible;
}

.landing_excel_63 {
  position: absolute;
  display: block;
  pointer-events: auto;
  left: 455.5px;
  top: 194px;
  z-index: 7;
}

.landing_excel_64 {
  z-index: 7;
  font-family: Roboto;
  height: auto;
  width: auto;
  color: rgb(0, 0, 0);
  text-decoration: none;
  white-space: nowrap;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  text-align: left;
  line-height: 24px;
  letter-spacing: 2px;
  font-weight: 500;
  font-size: 22px;
  border-color: rgb(34, 34, 34);
  -webkit-transform-origin: 50% 50% 0px;
          transform-origin: 50% 50% 0px;
  opacity: 1;
  -webkit-transform: translate(0px);
          transform: translate(0px);
  visibility: visible;
}

.landing_excel_65 {
  position: absolute;
  display: block;
  pointer-events: auto;
  left: 461.5px;
  top: 246px;
  z-index: 8;
}

.landing_excel_66 {
  position: absolute;
  display: block;
  pointer-events: auto;
  left: 460.5px;
  top: 307px;
  z-index: 8;
}

.landing_excel_67 {
  position: absolute;
  display: block;
  pointer-events: auto;
  left: 459.5px;
  top: 373px;
  z-index: 8;
}

.landing_excel_68 {
  z-index: 8;
  font-family: Roboto;
  color: rgb(0, 0, 0);
  text-decoration: none;
  white-space: normal;
  width: 374px;
  height: 45px;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  text-align: left;
  line-height: 18px;
  letter-spacing: 2px;
  font-weight: 400;
  font-size: 14px;
  border-color: rgb(34, 34, 34);
  -webkit-transform-origin: 50% 50% 0px;
          transform-origin: 50% 50% 0px;
  opacity: 1;
  -webkit-transform: translate(0px);
          transform: translate(0px);
  visibility: visible;
}

.landing_excel_69 {
  position: absolute;
  display: block;
  pointer-events: auto;
  left: 852.5px;
  top: 245px;
  z-index: 8;
}

.landing_excel_70 {
  position: absolute;
  display: block;
  pointer-events: auto;
  left: 850.5px;
  top: 305px;
  z-index: 8;
}

.landing_excel_71 {
  z-index: 8;
  font-family: Roboto;
  color: rgb(0, 0, 0);
  text-decoration: none;
  white-space: normal;
  width: 393px;
  height: 33px;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  text-align: left;
  line-height: 18px;
  letter-spacing: 2px;
  font-weight: 400;
  font-size: 14px;
  border-color: rgb(34, 34, 34);
  -webkit-transform-origin: 50% 50% 0px;
          transform-origin: 50% 50% 0px;
  opacity: 1;
  -webkit-transform: translate(0px);
          transform: translate(0px);
  visibility: visible;
}

.landing_excel_72 {
  position: absolute;
  display: block;
  pointer-events: auto;
  left: 850.5px;
  top: 360px;
  z-index: 8;
}

.landing_excel_73 {
  z-index: 8;
  font-family: Roboto;
  color: rgb(0, 0, 0);
  text-decoration: none;
  white-space: normal;
  width: 393px;
  height: 32px;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  text-align: left;
  line-height: 18px;
  letter-spacing: 2px;
  font-weight: 400;
  font-size: 14px;
  border-color: rgb(34, 34, 34);
  -webkit-transform-origin: 50% 50% 0px;
          transform-origin: 50% 50% 0px;
  opacity: 1;
  -webkit-transform: translate(0px);
          transform: translate(0px);
  visibility: visible;
}

.landing_excel_74 {
  position: absolute;
  display: block;
  pointer-events: auto;
  left: 461.5px;
  top: 439px;
  z-index: 9;
}

.landing_excel_75 {
  z-index: 9;
  background-color: rgb(1, 196, 126);
  font-family: Roboto;
  height: auto;
  width: auto;
  color: rgb(255, 255, 255);
  text-decoration: none;
  white-space: nowrap;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  text-align: left;
  line-height: 50px;
  letter-spacing: 0px;
  font-weight: 500;
  font-size: 18px;
  border-color: transparent;
  padding-left: 40px;
  border-radius: 3px;
  padding-right: 40px;
  -webkit-filter: blur(0px) grayscale(0%) brightness(100%);
          filter: blur(0px) grayscale(0%) brightness(100%);
  -webkit-transform-origin: 50% 50% 0px;
          transform-origin: 50% 50% 0px;
  opacity: 1;
  -webkit-transform: translate(0px);
          transform: translate(0px);
  visibility: visible;
}

.landing_excel_76 {
  position: absolute;
  display: block;
  pointer-events: auto;
  left: 850.5px;
  top: 408px;
  z-index: 8;
}

.landing_excel_77 {
  z-index: 8;
  font-family: Verdana, Geneva, sans-serif;
  color: rgb(0, 0, 0);
  text-decoration: none;
  white-space: normal;
  width: 394px;
  height: 38px;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  text-align: left;
  line-height: 15px;
  letter-spacing: 2px;
  font-weight: 400;
  font-size: 13px;
  border-color: rgb(34, 34, 34);
  -webkit-transform-origin: 50% 50% 0px;
          transform-origin: 50% 50% 0px;
  opacity: 1;
  -webkit-transform: translate(0px);
          transform: translate(0px);
  visibility: visible;
}

.landing_excel_78 {
  position: relative;
  left: -31.5px;
  box-sizing: border-box;
  width: 1263px;
  padding-left: 31.5px;
  padding-right: 31.5px;
}

.landing_excel_79 {
  background: transparent none repeat scroll 0% 0%;
  padding: 0px;
  margin: 0px auto;
  height: 600px;
  visibility: visible;
  position: absolute;
  overflow: visible;
  width: 1263px;
  left: -47px;
}

.landing_excel_80 {
  background-color: transparent;
  background-repeat: no-repeat; /* background-image: url("file:///wp-content/uploads/2020/09/Fondo-Banner-Principal-1536x568.jpg"); */
  background-size: cover;
  background-position: 50% 50%;
  width: 100%;
  height: 100%;
  opacity: 1;
  -webkit-transform: translate(0px);
          transform: translate(0px);
  visibility: inherit;
  z-index: 20;
}

.landing_excel_81 {
  z-index: 5;
  color: rgb(255, 255, 255);
  width: 690px;
  height: 523px;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  border-color: rgb(34, 34, 34);
  -webkit-transform-origin: 50% 50% 0px;
          transform-origin: 50% 50% 0px;
  opacity: 1;
  -webkit-transform: translate(0px);
          transform: translate(0px);
  visibility: visible;
}

.landing_excel_82 {
  position: absolute;
  display: block;
  pointer-events: auto;
  left: 699.5px;
  top: 325px;
  z-index: 6;
}

.landing_excel_83 {
  z-index: 6;
  font-family: Montserrat;
  height: auto;
  color: rgb(59, 59, 61);
  text-decoration: none;
  white-space: normal;
  width: 429px;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  text-align: left;
  line-height: 37px;
  letter-spacing: 0px;
  font-weight: 400;
  font-size: 18px;
  border-color: rgb(34, 34, 34);
  -webkit-transform-origin: 50% 50% 0px;
          transform-origin: 50% 50% 0px;
  opacity: 1;
  -webkit-transform: translate(0px);
          transform: translate(0px);
  visibility: visible;
}

.landing_excel_84 {
  position: absolute;
  display: block;
  pointer-events: auto;
  left: 696.5px;
  top: 162px;
  z-index: 7;
}

.landing_excel_85 {
  z-index: 7;
  font-family: Montserrat;
  width: auto;
  color: rgb(0, 0, 0);
  text-decoration: none;
  white-space: nowrap;
  height: 128px;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  text-align: left;
  line-height: 38px;
  letter-spacing: 0px;
  font-weight: 700;
  font-size: 72px;
  border-color: rgb(34, 34, 34);
  -webkit-transform-origin: 50% 50% 0px;
          transform-origin: 50% 50% 0px;
  opacity: 1;
  -webkit-transform: translate(0px);
          transform: translate(0px);
  visibility: visible;
}

.landing_excel_86 {
  position: absolute;
  display: block;
  pointer-events: auto;
  left: 702.5px;
  top: 372px;
  z-index: 6;
}

.landing_excel_87 {
}

.landing_excel_69_5 {
  z-index: 8;
  font-family: Roboto;
  height: auto;
  color: rgb(0, 0, 0);
  text-decoration: none;
  white-space: normal;
  width: 399px;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  text-align: left;
  line-height: 18px;
  letter-spacing: 2px;
  font-weight: 400;
  font-size: 14px;
  border-color: rgb(34, 34, 34);
  -webkit-transform-origin: 50% 50% 0px;
          transform-origin: 50% 50% 0px;
  opacity: 1;
  -webkit-transform: translate(0px);
          transform: translate(0px);
  visibility: visible;
}

.landing_excel_80_5 {
  position: absolute;
  display: block;
  pointer-events: auto;
  left: 15.5px;
  top: 75px;
  z-index: 5;
}

.landing_excel_66_5 {
  z-index: 8;
  font-family: Roboto;
  height: auto;
  color: rgb(0, 0, 0);
  text-decoration: none;
  white-space: normal;
  width: 373px;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  text-align: left;
  line-height: 18px;
  letter-spacing: 3px;
  font-weight: 400;
  font-size: 14px;
  border-color: rgb(34, 34, 34);
  -webkit-transform-origin: 50% 50% 0px;
          transform-origin: 50% 50% 0px;
  opacity: 1;
  -webkit-transform: translate(0px);
          transform: translate(0px);
  visibility: visible;
}

.landing_excel_64_5 {
  z-index: 8;
  font-family: Roboto;
  height: auto;
  color: rgb(0, 0, 0);
  text-decoration: none;
  white-space: normal;
  width: 374px;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  text-align: left;
  line-height: 18px;
  letter-spacing: 2px;
  font-weight: 400;
  font-size: 14px;
  border-color: rgb(34, 34, 34);
  -webkit-transform-origin: 50% 50% 0px;
          transform-origin: 50% 50% 0px;
  opacity: 1;
  -webkit-transform: translate(0px);
          transform: translate(0px);
  visibility: visible;
}

/*
.landing_excel_lin_2249 {
  z-index: 6;
  font-family: Montserrat;
  height: auto;
  color: rgb(59, 59, 61);
  text-decoration: none;
  white-space: normal;
  width: 486px;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  text-align: left;
  line-height: 25px;
  letter-spacing: 0px;
  font-weight: 400;
  font-size: 18px;
  border-color: rgb(34, 34, 34);
  transform-origin: 50% 50% 0px;
  opacity: 1;
  transform: translate(0px);
  visibility: visible;
}
*/

.recuadro-cursos {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.recuadro-cursos img {
  width: 70%;
}

.recuadro-cursos h3 {
  font-family: Abel, "San Serif", "Helvetica Neue";
  font-weight: 300;
  font-size: 1.5rem;
  margin-top: 30px;
  transition: 0.2s;
}

.recuadro-cursos h3:hover {
  color: #48a7d4;
  cursor: pointer;
  transition: 0.2s;
}

.primera-seccion-landing-excel {
  background-image: url(/static/media/Fondo-Banner-Principal.40b9e8c8.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
  width: 100%;
  height: 600px;
  display: flex;
  padding: 0 100px;
}

.primera-seccion-landing-excel img {
  width: 100%;
  height: 80%;
  align-self: flex-end;
}

.primera-seccion-landing-excel__texto {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.primera-seccion-landing-excel__texto span {
  font-family: Montserrat, "Helvetica Neue", Helvetica, Arial;
  font-weight: 700;
  font-size: 5rem;
  line-height: 90px;
  height: -webkit-max-content;
  height: max-content;
  width: -webkit-max-content;
  width: max-content;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.primera-seccion-landing-excel__texto--microsoft {
  margin-bottom: 20px;
}

.primera-seccion-landing-excel__texto p {
  font-family: Montserrat, "Helvetica Neue", Helvetica, Arial;
  font-size: 1.2rem;
  margin-bottom: 20px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.segunda-seccion-landing-excel {
  display: flex;
  width: 100%;
  height: 350px;
  align-items: center;
  padding: 0 40px;
}

.tercera-seccion-landing-excel {
  width: 100%;
  height: -webkit-max-content;
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ebebeb;
  padding: 50px 10vw;
}

.tercera-seccion-landing-excel a {
  margin-bottom: 30px;
  font-size: 2rem;
  font-weight: bold;
  font-family: Montserrat, "Helvetica Neue", Helvetica;
  transition: 0.2s;
}

.tercera-seccion-landing-excel a:hover {
  color: var(--color-principal);
  transition: 0.2s;
}

.beneficios-contenedor {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0 50px;
  height: -webkit-max-content;
  height: max-content;
}

.beneficios-primera-parte {
  grid-column-start: 1;
  grid-column-end: 2;
}

.beneficios-segunda-parte {
  grid-column-start: 2;
  grid-column-end: 3;
}

.beneficios-tercera-parte {
  grid-column-start: 3;
  grid-column-end: 4;
}

.beneficios-segunda-parte img {
  width: 100%;
}

.beneficios-primera-parte ul li,
.beneficios-tercera-parte ul li {
  margin-bottom: 10px;
}

.beneficios-tercera-parte {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.tercera-seccion-landing-excel .beneficios-tercera-parte a {
  margin-bottom: 20px;
}

.beneficios-tercera-parte a button {
  text-transform: uppercase;
  width: 50%;
  height: 50px;
  color: white;
  background-color: var(--color-principal);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: Montserrat, "Helvetica Neue", Helvetica;
  font-weight: 500;
  transition: 0.2s;
}

.beneficios-tercera-parte a button:hover {
  -webkit-filter: saturate(65%);
          filter: saturate(65%);
  transition: 0.2s;
}

.cuarta-seccion-landing-excel {
  width: 100%;
  height: 450px;
  background-image: url(/static/media/Banner-02-solo.efa1f687.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  grid-template-columns: 1fr 3fr 3fr 1fr 3fr 1fr;
  grid-template-rows: 1fr;
}

.cuarta-seccion__logos-cursos,
.cuarta-seccion__texto {
  display: flex;
}

.cuarta-seccion__logos-cursos {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row: 1/2;
  flex-wrap: wrap;
  align-content: center;
}

.cuarta-seccion__logos-cursos img {
  width: 25%;
  margin: 10px;
  position: relative;
  z-index: 1;
}

.cuarta-seccion__foto {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row: 1/2;
  display: flex;
  align-items: flex-end;
  height: inherit;
}

.cuarta-seccion__foto img {
  width: 115%;
  position: relative;
  z-index: 0;
  right: 70px;
}

.cuarta-seccion__texto {
  grid-column-start: 4;
  grid-column-end: 6;
  grid-row: 1/2;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.cuarta-seccion__texto span {
  font-family: "Roboto", sans-serif;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
  text-align: right;
  font-size: 1.1rem;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.cuarta-seccion__texto span:nth-child(1) {
  font-size: 2rem;
  font-weight: 700;
  text-align: right;
  text-transform: uppercase;
  padding-left: 50px;
}

.cuarta-seccion__texto span:nth-child(2) {
  font-weight: bold;
  text-align: right;
  font-size: 1.1rem;
  padding-left: 120px;
}

.cuarta-seccion__texto button {
  width: 60%;
  height: 50px;
  background: linear-gradient(rgb(255, 219, 76) 0%, rgb(255, 205, 2) 100%);
  border: none;
  box-shadow: blur(0px) grayscale(0%) brightness(100%);
  border-radius: 5px;
  text-transform: uppercase;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 1.3rem;
  font-weight: 500;
  box-shadow: -10px 15px 60px -15px rgba(0, 0, 0, 0.75);
  transition: 1s;
}

.cuarta-seccion__texto button:hover {
  cursor: pointer;
  -webkit-filter: brightness(1.1);
          filter: brightness(1.1);
  transition: 0.5s;
}

.quinta-seccion-landing-excel {
  width: 100%;
  height: -webkit-max-content;
  height: max-content;
  margin-bottom: 50px;
}

.quinta-seccion-landing-excel .carousel-parent h2 {
  margin-top: 0;
}

.sexta-seccion-landing-excel {
  display: grid;
  grid-template-columns: 0.8fr 2fr 3fr 0.8fr;
  background-color: #f1f6fb;
  height: 600px;
  padding: 50px 0;
}

.sexta-seccion-landing-excel img {
  grid-column: 2 / 3;
  width: 100%;
}

.sexta-seccion-landing-excel__titulos {
  display: flex;
  flex-direction: column;
}

.sexta-seccion-landing-excel__titulos span:nth-child(1) {
  font-family: Roboto, sans-serif;
  font-size: 2.5rem;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 20px;
}

.sexta-seccion-landing-excel__titulos span:nth-child(1):not(.texto-verde) {
  padding-right: 160px;
}

.texto-verde {
  color: #01c47e;
}

.sexta-seccion-landing-excel__titulos span:nth-child(2),
.sexta-seccion-landing-excel__titulos span:nth-child(3) {
  font-family: Roboto, sans-serif;
  font-size: 1.5rem;
}

.sexta-seccion-landing-excel__titulos span:nth-child(2) {
  text-decoration: line-through;
  color: #ff1000;
}

.sexta-seccion-landing-excel__titulos span:nth-child(3) {
  font-weight: 500;
  margin-bottom: 20px;
}

.sexta-seccion-landing-excel__texto {
  grid-column: 3 / 4;
  display: flex;
  flex-direction: column;
}

.sexta-seccion-landing-excel__beneficios {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.sexta-seccion-landing-excel__beneficios--primera-seccion {
  grid-column: 1 / 2;
  margin-right: 10px;
}

.sexta-seccion-landing-excel__beneficios--segunda-seccion {
  grid-column: 2 / 3;
}

.sexta-seccion-landing-excel__beneficios--primera-seccion,
.sexta-seccion-landing-excel__beneficios--segunda-seccion {
  display: flex;
  flex-direction: column;
}

.sexta-seccion-landing-excel__beneficios--primera-seccion span,
.sexta-seccion-landing-excel__beneficios--segunda-seccion span {
  font-family: Roboto, sans-serif;
  letter-spacing: 0.2em;
  font-size: 0.8rem;
  font-weight: 500;
  margin-bottom: 10px;
}

svg.svg-inline--fa.fa-circle.fa-w-16.icono-excel-landing {
  background-color: black;
  width: 12px;
  height: 12px;
  margin-right: 5px;
}

svg.svg-inline--fa.fa-circle.fa-w-16.icono-excel-landing path {
  color: black;
  width: 15px;
  height: 15px;
}

.sexta-seccion-landing-excel__beneficios button {
  width: 80%;
  height: 50px;
  background-color: #01c47e;
  border: none;
  border-radius: 5px;
  transition: 1s;
}

.sexta-seccion-landing-excel__beneficios button:hover {
  cursor: pointer;
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
  transition: 0.5s;
}

.sexta-seccion-landing-excel__beneficios button a {
  text-transform: uppercase;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  color: #fff;
}

.septima-seccion-landing-excel {
  display: grid;
  grid-template-columns: 0.5fr 4fr 4fr 0.5fr;
  height: -webkit-max-content;
  height: max-content;
}

.septima-seccion-landing-excel div {
  grid-column: 2 / 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.septima-seccion-landing-excel div span,
.septima-seccion-landing-excel button a {
  font-family: Roboto, sans-serif;
}

.septima-seccion-landing-excel div span:nth-child(1) {
  font-size: 3.5rem;
  font-weight: bold;
  line-height: 50px;
  margin-bottom: 30px;
}

.septima-seccion-landing-excel div span:nth-child(2) {
  font-size: 1.5rem;
  font-weight: 500;
}

.septima-seccion-landing-excel img {
  grid-column: 3 / 4;
  width: 100%;
  align-self: end;
}

div.septima-seccion-landing-excel__seccion-boton {
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
}

div.septima-seccion-landing-excel__seccion-boton a {
  width: 55%;
  height: 50px;
}

div.septima-seccion-landing-excel__seccion-boton a button {
  width: 100%;
  height: 100%;
  font-family: Roboto, sans-serif;
  font-size: 1.8rem;
  font-weight: 500;
  text-transform: uppercase;
  background-color: transparent;
  border: 1px solid black;
}

div.septima-seccion-landing-excel__seccion-boton a button:hover {
  cursor: pointer;
}

@media only screen and (max-width: 425px) {
  .primera-seccion-landing-excel,
  .segunda-seccion-landing-excel,
  .tercera-seccion-landing-excel,
  .cuarta-seccion-landing-excel,
  .quinta-seccion-landing-excel,
  .sexta-seccion-landing-excel {
    width: 100%;
    height: -webkit-max-content;
    height: max-content;
    padding: 25px 30px;
  }

  .primera-seccion-landing-excel {
    padding: 10px;
  }

  .primera-seccion-landing-excel__texto p {
    font-size: 1rem;
    font-weight: 500;
  }

  .primera-seccion-landing-excel img {
    display: none;
  }

  .primera-seccion-landing-excel__texto {
    display: block;
    height: -webkit-max-content;
    height: max-content;
  }

  .primera-seccion-landing-excel__texto span {
    font-size: 2rem;
    display: flex;
    width: 100%;
    height: -webkit-max-content;
    height: max-content;
    line-height: 40px;
  }

  .segunda-seccion-landing-excel {
    flex-direction: column;
  }

  .segunda-seccion-landing-excel div {
    margin: 15px 0;
  }

  .tercera-seccion-landing-excel a {
    font-size: 1.5rem;
    text-align: center;
  }

  .beneficios-contenedor {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, -webkit-max-content);
    grid-template-rows: repeat(3, max-content);
  }

  .beneficios-primera-parte {
    grid-column: 1 / 1;
    grid-row: 1 / 2;
  }

  .beneficios-segunda-parte {
    grid-column: 1 / 1;
    grid-row: 3 / 4;
  }

  .beneficios-tercera-parte {
    grid-column: 1 / 1;
    grid-row: 2 / 3;
  }

  .tercera-seccion-landing-excel .beneficios-tercera-parte a {
    margin-top: 20px;
  }

  .cuarta-seccion-landing-excel {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, -webkit-max-content);
    grid-template-rows: repeat(2, max-content);
  }

  .cuarta-seccion__texto {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  .cuarta-seccion__foto {
    display: none;
  }

  .cuarta-seccion__logos-cursos {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    justify-content: center;
  }

  div.cuarta-seccion__texto span,
  .cuarta-seccion__texto span:nth-child(1),
  .cuarta-seccion__texto span:nth-child(2) {
    padding: 0;
    text-align: center;
  }

  .cuarta-seccion__texto span:nth-child(1) {
    font-size: 1.5rem;
  }

  .cuarta-seccion__texto span {
    font-size: 1rem;
  }

  .cuarta-seccion__texto button {
    font-size: 1rem;
    margin: 0 auto 10px;
  }

  .quinta-seccion-landing-excel .carousel-parent h2 {
    font-size: 1.5rem;
  }

  .sexta-seccion-landing-excel {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, -webkit-max-content);
    grid-template-rows: repeat(2, max-content);
  }

  .sexta-seccion-landing-excel img,
  .sexta-seccion-landing-excel__texto {
    grid-column: 1 / 2;
  }

  .sexta-seccion-landing-excel img {
    display: none;
  }

  .sexta-seccion-landing-excel__texto {
    grid-row: 1 / 2;
  }

  .sexta-seccion-landing-excel__titulos {
    text-align: center;
  }

  .sexta-seccion-landing-excel__titulos span:nth-child(1):not(.texto-verde) {
    padding: 0;
  }

  .sexta-seccion-landing-excel__titulos span:nth-child(1) {
    font-size: 1.5rem;
  }

  .sexta-seccion-landing-excel__titulos span:nth-child(2),
  .sexta-seccion-landing-excel__titulos span:nth-child(3) {
    font-size: 1.2rem;
  }

  .sexta-seccion-landing-excel__beneficios {
    display: flex;
    flex-direction: column;
  }

  .sexta-seccion-landing-excel__beneficios button {
    margin: 10px auto;
  }

  .sexta-seccion-landing-excel__beneficios button a {
    font-size: 1rem;
  }

  .septima-seccion-landing-excel {
    padding-top: 30px;
    padding-bottom: 0;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr -webkit-max-content;
    grid-template-rows: 1fr max-content;
  }

  .septima-seccion-landing-excel div {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  .septima-seccion-landing-excel div span:nth-child(1) {
    font-size: 1.5rem;
    line-height: 30px;
    text-align: center;
  }

  .septima-seccion-landing-excel div span:nth-child(2) {
    font-size: 1.2rem;
    text-align: center;
  }

  .septima-seccion-landing-excel img {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }

  div.septima-seccion-landing-excel__seccion-boton a button {
    font-size: 1rem;
  }
}

@media only screen and (min-width: 426px) and (max-width: 768px) {
  .primera-seccion-landing-excel,
  .segunda-seccion-landing-excel,
  .tercera-seccion-landing-excel,
  .cuarta-seccion-landing-excel,
  .sexta-seccion-landing-excel,
  .septima-seccion-landing-excel {
    width: 100%;
    height: -webkit-max-content;
    height: max-content;
  }

  .primera-seccion-landing-excel {
    padding: 0 20px;
    display: flex;
    height: 100%;
  }

  .primera-seccion-landing-excel img {
    display: none;
  }

  .primera-seccion-landing-excel__texto {
    width: 100%;
  }

  .primera-seccion-landing-excel__texto span {
    font-size: 3rem;
  }

  .segunda-seccion-landing-excel {
    flex-direction: column;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .tercera-seccion-landing-excel a {
    text-align: center;
  }

  .beneficios-primera-parte ul li,
  .beneficios-tercera-parte ul li {
    font-size: 1.2rem;
  }

  .beneficios-contenedor {
    display: block;
    height: -webkit-max-content;
    height: max-content;
  }

  .beneficios-segunda-parte img {
    display: none;
  }

  .tercera-seccion-landing-excel .beneficios-tercera-parte a {
    margin-top: 10px;
  }

  .beneficios-tercera-parte a button {
    font-size: 1.2rem;
  }

  .cuarta-seccion-landing-excel {
    padding: 50px 35px;
  }

  .cuarta-seccion-landing-excel {
    display: flex;
    flex-direction: column;
  }

  .cuarta-seccion__foto img {
    display: none;
  }

  .cuarta-seccion__logos-cursos {
    justify-content: center;
  }

  .cuarta-seccion__logos-cursos img {
    width: 10%;
    position: static;
  }

  .cuarta-seccion__texto span:nth-child(1),
  .cuarta-seccion__texto span:nth-child(2) {
    padding: 0;
    margin: 10px auto;
  }

  .cuarta-seccion__texto span {
    text-align: center;
  }

  .cuarta-seccion__texto button {
    margin: 0 auto;
  }

  .sexta-seccion-landing-excel {
    display: flex;
    flex-direction: column;
    padding: 50px 35px;
  }

  .sexta-seccion-landing-excel__titulos span:nth-child(1) {
    margin-bottom: 0;
  }

  .sexta-seccion-landing-excel img {
    display: none;
  }

  .sexta-seccion-landing-excel__beneficios {
    display: flex;
    flex-direction: column;
  }

  .sexta-seccion-landing-excel__beneficios button {
    margin: 10px auto;
  }

  .septima-seccion-landing-excel {
    display: flex;
    flex-direction: column;
    padding: 50px 35px 0;
  }

  .septima-seccion-landing-excel img {
    width: 50%;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .primera-seccion-landing-excel {
    padding: 0 35px;
    height: 500px;
  }

  .primera-seccion-landing-excel__texto p {
    font-size: 1rem;
  }

  .primera-seccion-landing-excel img {
    width: 75%;
    height: 75%;
  }

  .primera-seccion-landing-excel__texto {
    justify-content: center;
  }

  .primera-seccion-landing-excel__texto span {
    font-size: 3.5rem;
    margin-bottom: 0;
    line-height: 60px;
  }

  .beneficios-primera-parte ul li,
  .beneficios-tercera-parte ul li {
    font-size: 1rem;
  }

  .cuarta-seccion__texto span:nth-child(1) {
    font-size: 1.5rem;
  }

  .cuarta-seccion__texto span:nth-child(2),
  .cuarta-seccion__texto span {
    font-size: 1rem;
  }

  .cuarta-seccion__texto button {
    font-size: 1rem;
  }

  .sexta-seccion-landing-excel__titulos span:nth-child(1):not(.texto-verde) {
    padding-right: 100px;
  }

  .sexta-seccion-landing-excel__titulos span:nth-child(1) {
    font-size: 1.8rem;
  }

  .sexta-seccion-landing-excel__titulos span:nth-child(2),
  .sexta-seccion-landing-excel__titulos span:nth-child(3) {
    font-size: 1.2rem;
  }

  .sexta-seccion-landing-excel__beneficios button a {
    font-size: 1rem;
  }

  div.septima-seccion-landing-excel__seccion-boton a button {
    font-size: 1.2rem;
  }

  .septima-seccion-landing-excel {
    padding-top: 50px;
  }
}

