h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.nav-tabs > li > a,
.member-name,
.section-title,
.user-name,
.heading_font,
.item-title,
.acomment-meta,
[type="reset"],
.bp-subnavs,
.activity-header,
table,
.widget_categories ul li a,
.sidebar-area .widget ul li a,
.select2-selection__rendered,
blockquote,
.select2-chosen,
.vc_tta-tabs.vc_tta-tabs-position-top
  .vc_tta-tabs-container
  .vc_tta-tabs-list
  li.vc_tta-tab
  a,
.vc_tta-tabs.vc_tta-tabs-position-left
  .vc_tta-tabs-container
  .vc_tta-tabs-list
  li.vc_tta-tab
  a,
body.distance-learning .btn,
body.distance-learning .vc_btn3 {
  font-family: "Roboto Slab";
  color: #222222;
}

footer h1,
footer h2,
footer h3,
footer h4,
footer h5,
footer h6 {
  font-family: Roboto, sans-serif;
  color: white;
}

.section-title-tipo {
  height: 650px;
  overflow: hidden;
  display: flex;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.tipo {
  position: relative;
  top: 14px;
}

.input-group {
  /* position: relative; */
  /* display: -ms-flexbox; */
  /* display: flex; */
  /* -ms-flex-wrap: wrap; */
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
  /* margin-bottom: 5px; */
}

.woocommerce-checkout-review-order-table {
  width: 100%;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

.shop_table tr {
  border: 0;
}

.cursos-online {
  color: var(--color-secundario);
  font-size: 1.2rem;
  font-weight: 700;
}

.shop_table > tbody > tr td {
  padding: 30px 8px;
  vertical-align: middle;
}

.woocommerce-checkout-review-order-table th,
.woocommerce-checkout-review-order-table.shop-table > tbody > tr > td,
.woocommerce-checkout-review-order-table.shop_table td,
.woocommerce-checkout-review-order-table.shop_table th,
.woocommerce-checkout-review-order-table > tbody > tr > td {
  padding: 6px 17px;
}

.woocommerce-checkout-review-order-table td.product-name,
.woocommerce-checkout-review-order-table th {
  text-align: left;
}

.woocommerce .shop_table.order_details .product-total,
.woocommerce-checkout-review-order-table td,
.woocommerce-checkout-review-order-table th.product-total {
  text-align: right;
}

.woocommerce-checkout-review-order-table th,
.woocommerce-checkout-review-order-table.shop-table > tbody > tr > td,
.woocommerce-checkout-review-order-table.shop_table td,
.woocommerce-checkout-review-order-table.shop_table th,
.woocommerce-checkout-review-order-table > tbody > tr > td {
  padding: 6px 17px;
}

.woocommerce .shop_table.order_details > tbody > tr:nth-of-type(2n + 1),
.woocommerce-checkout-review-order-table > tbody > tr:nth-of-type(2n + 1) {
  background-color: #f2f2f2;
}

.shop_table > tbody > tr {
  border: 0;
}

.info-container {
  height: max-content;
  display: grid;
  grid-template-columns: 1fr 2fr 3fr 3fr 2fr 1fr;
  grid-template-rows: 1fr 4fr 1fr;
  grid-gap: 50px;
  padding-top: 30px;
}

.info-container img {
  width: 100%;
  grid-column-start: 2;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 5;
  align-self: end;
}

.info-container .text {
  grid-column-start: 4;
  grid-column-end: 6;
  grid-row-start: 1;
  grid-row-end: 4;
}

.text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.text p,
.text span {
  margin: 10px 0;
}

.info-container .text .pago-anual {
  margin-bottom: 0;
  font-size: 1.8rem;
  font-weight: 700;
}

.info-container .text .precio {
  margin-top: 0;
  color: var(--color-secundario);
  font-size: 3vh;
  font-weight: 500;
}

.precio .usd {
  font-size: 2.5rem;
  font-weight: bolder;
}

.text p {
  font-size: 1.2rem;
}

.text p a {
  color: black;
  transition: 0.2s;
}

.text p a:hover {
  transition: 0.3s;
  color: rgb(0, 152, 215);
}

.man-info {
  width: 100%;
  background-image: url("../../../images/Banner-Inscripción-2.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: grid;
  grid-template-columns: 1fr 3fr 3fr 1fr;
}

.man-info__text {
  display: flex;
  flex-direction: column;
  grid-column-start: 2;
  grid-column-end: 3;
  justify-content: center;
}

.man-info__text--title {
  font-family: Roboto;
  text-transform: uppercase;
  color: var(--color-principal);
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 37px;
  margin-bottom: 10px;
}

.man-section {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.man-image {
  width: 100%;
  grid-column-start: 3;
  grid-column-end: 4;
}

.kids-courses {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
}

.cercle-blue {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: white;
  display: inline-block;
  margin-right: 5px;
}

.cercle-blue {
  background-color: rgb(0, 152, 215);
}

div.courses-logo img {
  width: 15%;
  margin-right: 10px;
}

.info-pay-section {
  display: grid;
  grid-template-columns: 1fr 3fr 3fr 1fr;
  grid-gap: 0 30px;
  margin: 50px 0;
}

.pay-includes {
  grid-column-start: 2;
  grid-column-end: 3;
}

.pay-actualizations {
  grid-column-start: 3;
  grid-column-end: 4;
}

.pay-includes,
.pay-actualizations {
  display: flex;
  flex-direction: column;
}

.pay-includes h3,
.pay-actualizations h3 {
  margin-bottom: 10px;
  color: var(--color-principal);
}

svg.svg-inline--fa.fa-check.fa-w-16 path,
svg.svg-inline--fa.fa-circle.fa-w-16 path {
  color: var(--color-principal);
}

.check-section {
  display: grid;
}

.check-text {
  display: flex;
  align-items: flex-start;
}

div.chech-text svg.svg-inline--fa.fa-w-16 {
  width: 10px;
}

.check-text p {
  margin-bottom: 4px;
  margin-left: 5px;
  font-size: 1.1vw;
}

h3 {
  margin-bottom: 30px;
}

.guarantee-section {
  background-color: #f5f5f5;
  display: grid;
  grid-template-columns: 1fr 5fr 5fr 1fr;
  padding: 30px 0;
  background-image: url("../../../images/Banner-Inscripción-3.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.conditions {
  grid-column-start: 2;
  grid-column-end: 3;
}

.guarantee {
  grid-column-start: 3;
  grid-column-end: 4;
  background-color: white;
  border: 3px solid #a2a2a2;
  border-radius: 40px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  padding: 30px 0;
  width: 33vw;
  font-family: Roboto;
  font-size: 1vw;
  margin: 0 auto;
}

.guarantee p {
  text-align: center;
  font-family: Roboto;
  line-height: 25px;
  font-weight: 400;
}

.guarantee img {
  width: 30%;
  margin-top: 20px;
}

.fa-circle {
  background-color: var(--color-principal);
  border-radius: 50%;
  width: 15px;
  height: 15px;
}

.conditions h2,
.guarantee h2 {
  margin-bottom: 20px;
  font-family: Roboto;
}

.conditions h2 {
  text-transform: uppercase;
  font-size: 1.7vw;
  font-weight: 900;
}

.guarantee h2 {
  font-size: 3vh;
  display: inline-block;
  text-align: center;
}

.container-form {
  padding: 20px 130px;
  width: 100%;
}

@media only screen and (max-width: 768px) and (min-width: 426px) {
  .info-container {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    padding: 10px 15px;
    grid-gap: 0;
  }

  .info-container img {
    grid-column-start: 1;
    grid-column-end: 2;
    margin: 0;
    width: 100%;
  }

  .info-container .text {
    grid-column-start: 2;
    grid-column-end: 3;
  }

  .section-title-tipo {
    height: 100%;
  }

  .text p {
    margin: 5px 0;
    font-size: 1.8vw;
    font-weight: 400;
  }

  .info-container .text .pago-anual {
    font-size: 2.3vw;
  }

  .precio .usd {
    font-size: 3vw;
  }

  .info-container .text .precio {
    font-size: 2vw;
  }

  .text p,
  .text span {
    margin-bottom: 0;
  }

  .man-info {
    grid-template-columns: 1fr 1fr;
    padding: 10px 20px 0 20px;
  }

  .man-info__text {
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .man-section {
    grid-column-start: 2;
    grid-column-end: 3;
  }

  .man-info__text--title {
    font-size: 2.5vw;
    line-height: 20px;
    margin-bottom: 20px;
  }

  .info-pay-section {
    grid-template-columns: 1fr 1fr;
    padding: 0 20px;
  }

  .pay-includes {
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .pay-actualizations {
    grid-column-start: 2;
    grid-column-end: 3;
  }

  .pay-includes h3,
  .pay-actualizations h3 {
    font-size: 2.3vw;
    margin-bottom: 30px;
  }

  .check-text p {
    font-size: 1.6vw;
  }

  .guarantee-section {
    grid-template-rows: 0.5fr 0.5fr;
    grid-template-columns: 1fr;
    padding: 20px 60px;
    grid-gap: 20px 0;
    background-size: contain;
    background-position-x: right;
    background-position-y: bottom;
  }

  .conditions {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .guarantee {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 2;
    width: 50vw;
    height: 50vh;
  }

  .conditions h2 {
    font-size: 2.5vw;
  }

  svg.svg-inline--fa.fa-circle.fa-w-16 {
    width: 10px;
    height: 10px;
  }

  div.conditions div.check-text p {
    font-size: 1.8vw;
    font-weight: 400;
  }

  .guarantee {
    height: max-content;
    margin: auto auto;
    width: 70%;
    height: max-content;
    padding: 20px;
  }

  .guarantee p {
    font-size: 2vw;
    line-height: 20px;
  }

  .guarantee img {
    width: 25%;
  }
}

@media only screen and (max-width: 600px) and (min-width: 320px) {
  .container-form {
    width: 100%;
    padding: 10px 30px;
  }
}

@media only screen and (max-width: 426px) and (min-width: 320px) {
  .info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 30px;
    height: max-content;
  }

  .info-container img {
    display: none;
  }

  .info-container .text .pago-anual {
    font-size: 8vw;
    line-height: 30px;
    margin-bottom: 5px;
  }

  .info-container .text .precio {
    font-size: 5vw;
  }

  .precio .usd {
    font-size: 6vw;
  }

  .text p,
  .text span {
    font-size: 1rem;
  }

  .man-info {
    grid-template-columns: 1fr;
    padding: 10px 30px;
  }

  .man-image {
    display: none;
  }

  .man-info__text--title {
    font-size: 5vw;
    margin-bottom: 10px;
    line-height: 25px;
  }

  .kids-courses {
    margin-bottom: 10px;
  }

  .cercle-blue {
    width: 10px;
    height: 10px;
  }

  .courses-logo {
    margin: 0;
    display: flex;
    justify-content: space-between;
  }

  .info-pay-section {
    grid-template-columns: 1fr;
    grid-template-rows: 0.5fr 0.5fr;
    grid-gap: 20px 0;
    margin: 30px 0;
    padding: 0 30px;
  }

  .pay-includes {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  .pay-actualizations {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
  }

  .pay-includes h3,
  .pay-actualizations h3 {
    font-size: 5vw;
    font-weight: 700;
    margin-bottom: 20px;
  }

  .check-text p {
    font-size: 3.5vw;
  }

  .check-text {
    align-items: center;
  }

  .text p,
  .text span {
    font-size: 3.5vw;
  }

  .guarantee-section {
    grid-template-columns: 1fr;
    grid-template-rows: 0.5fr 0.5fr;
    padding: 30px 30px;
  }

  .conditions h2 {
    font-size: 4vw;
  }

  .conditions {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .guarantee {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 2;
    margin: 20px 0;
    width: 100%;
    padding: 15px 10px;
    height: max-content;
  }

  div.check-text svg:not(:root).svg-inline--fa {
    margin: 0;
    margin-bottom: auto;
  }

  div.guarantee h2 {
    text-align: center;
    margin: 0 0 20px 0;
  }

  .guarantee p {
    font-size: 3vw;
    font-weight: 600;
    /* text-align: left; */
    line-height: 20px;
  }

  .guarantee p br {
    display: none;
  }

  .guarantee img {
    margin: 20px 0 0 0;
  }
}

@media only screen and (max-width: 1200px) and (min-width: 769px) {
  .info-container .text .pago-anual {
    font-size: 1.4rem;
  }

  .precio .usd {
    font-size: 2rem;
  }

  .text p {
    font-size: 1rem;
  }
}
