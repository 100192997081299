.carousel-item {
  width: 100%;
  background-image: url("../../images/Banner-Princ-Home.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.banner-principal-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.banner-principal-container img {
  width: 30%;
}

.carousel-tag {
  font-size: 2.5rem;
  font-family: "Roboto Slab", serif;
  font-weight: 700;
}

.carousel-tag span.futuro {
  display: block;
  color: var(--color-principal);
}

.carousel-tag span.profesionales {
  font-size: 1rem;
}

@media only screen and (max-width: 900px) {
  .carousel-tag {
    left: 20px;
    bottom: 30%;
    font-size: 1rem;
  }
}
