.carousel .slide img {
  object-fit: cover;
}

.cta-home {
  font-family: "Roboto Slab", serif;
  background: var(--color-principal);
  margin: -100px auto 0 auto;
  border-radius: 12px;
  z-index: 1;
  position: relative;
  display: flex;
  padding: 50px 30px;
  color: #171818;
  border-radius: 10px;
}

.cta-home h2 {
  flex: 1;
  padding: 0 20px;
  font-size: 1.8rem;
  color: white;
}
.cta-home .countdown-holder {
  flex: 1;
  text-align: center;
}

.cta-home .countdown-holder a {
  color: white;
  background: green;
  padding: 20px;
  border-radius: 12px;
  display: inline-block;
  width: 220px;
  text-align: center;
  margin-top: 20px;
  transition: ease all 0.3s;
}

.cta-home .countdown-holder a:hover {
  background: darkgreen;
}

.cta-home .countdown-holder a svg {
  margin-right: 20px;
}

.cta-home .countdown {
  display: flex;
  justify-content: space-around;
  text-align: center;
}
.cta-home .countdown h2 {
  padding: 0;
  color: white;
  font-size: 3rem;
}

.cta-home .countdown span {
  font-weight: 500;
  color: white;
  margin-top: 15px;
}

.container-countdown {
  width: 1200px;
  max-width: 95vw;
  margin: auto;
}

section.first-section {
  margin: 90px auto 0;
  padding: 20px;
  display: flex;
  position: relative;
  align-items: center;
}

section.first-section .content-home {
  width: 60%;
  padding-left: 40px;
}

section.first-section .content-home p,
section.first-section .content-home h3 {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

section.first-section .content-home h3 {
  font-size: 1.5rem;
}

.content-home h3 {
  color: var(--color-principal);
  font-weight: bold;
  margin-bottom: 20px;
}

.content-home h3 span {
  color: red;
  text-decoration: line-through;
}

.first-section .circle:before {
  display: block;
  width: 300px;
  height: 300px;
  background: #c9efff;
  border-radius: 50%;
  content: " ";
  position: absolute;
  z-index: -1;
  left: 100px;
  top: 20px;
}

.first-section .circle {
  flex: 2;
  padding: 0 0 0 140px;
  font-family: "Roboto Slab", serif;
  font-size: 1.6rem;
  font-weight: bold;
  width: 40%;
}

.first-section .circle span {
  color: #fff;
}
.first-section .content {
  flex: 3;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

.first-section .content p {
  margin: 10px 0;
}

.first-section .content h3 {
  color: green;
}

.first-section .content h3 span {
  color: red;
  text-decoration: line-through;
}

.cta-bg {
  width: 100%;
  height: 420px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: 50px 0;
}

.cta-bg .flex {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: none;
}

.cta-bg .text {
  font-family: "Open Sans", sans-serif;
  text-align: center;
}

.cta-bg .text h3 {
  color: var(--color-principal);
  text-align: center;
  font-weight: 700;
  margin: 20px 0;
  font-size: 2rem;
}

.cta-bg .text p {
  color: var(--color-principal);
  font-weight: 700;
  text-align: center;
  font-size: 1.5rem;
}

.cta-bg .text button {
  color: var(--color-secundario);
  font-weight: bold;
  text-transform: uppercase;
  width: max-content;
  margin: 20px auto;
  padding: 10px 30px;
  border: 3px solid var(--color-secundario);
  transition: ease all 0.3s;
  border-radius: 30px;
  font-size: 1.3rem;
}

.cta-bg .text button:hover {
  cursor: pointer;
}

.cta-bg .moving-img {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
}

.cta-bg .moving-img img {
  width: 75%;
}

div.tarjeta-home:hover .content-home h3 {
  color: white;
}

div.tarjeta-home .content-home h3 {
  color: var(--color-principal);
}

.porque-cdo .grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 40px;
}
.tarjeta-home {
  background: white;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.14);
  min-height: 300px;
  display: flex;
  border-left: 6px solid var(--color-secundario);
  padding: 30px;
  align-items: flex-start;
  justify-content: space-between;
}

.tarjeta-home:hover {
  background: var(--color-secundario);
  cursor: pointer;
  transition: ease all 0.3s;
}

.tarjeta-home:hover p {
  color: white;
}

.tarjeta-home svg {
  flex: 1;
  font-size: 2rem;
  color: var(--color-principal);
  margin-right: 20px;
}

.tarjeta-home:hover svg,
.tarjeta-home:hover h3 {
  color: white;
}
.tarjeta-home .content {
  padding-left: 20px;
  flex: 10;
}

.tarjeta-home .content h3 {
  font-family: "Roboto Slab", serif;
  font-weight: 700;
  font-size: 1.6rem;
}

.tarjeta-home .content p {
  text-align: justify;
  margin-top: 20px;
}

.counters {
  width: 100%;
  height: 632px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: 50px 0;
}

.container-items {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 20px;
  row-gap: 20px;
  align-items: center;
  height: 100%;
}

.counter-items {
  height: 280px;
  color: white;
  text-align: center;
}
.counter-items svg {
  margin-top: 20px;
  font-size: 4rem;
}
.counter-items h3 {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 3rem;
  font-family: "Roboto Slab", sans-serif;
  color: #fff;
}
.counter-items h4 {
  font-family: "Roboto Slab", sans-serif;
  font-size: 1.8rem;
  color: white;
}

.docentes-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 20px;
}

.docentes-section h2 {
  font-family: "Roboto Slab", sans-serif;
  font-size: 1.8rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
}
.docente {
  text-align: center;
}
.docente h3 {
  margin: 20px 0;
  font-family: "Roboto Slab", sans-serif;
}
.docente span {
  color: #999;
  display: block;
  margin-bottom: 20px;
}

.docentes-list img {
  width: 100%;
}

.garantia {
  margin-top: 50px;
  background: #1091d5;
  padding-top: 50px;
  color: white;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.garantia h2 {
  font-size: 3rem;
  font-family: "Roboto Slab", sans-serif;
  color: white;
  margin-bottom: 60px;
}

.garantia ul {
  list-style: none;
  flex: 1;
}
.garantia .img-holder {
  width: 30%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.garantia .img-holder img {
  width: 100%;
  margin: 0;
  padding: 0;
}
.garantia ul li {
  margin-top: 50px;
  font-size: 1.3rem;
}

.garantia .container-garantia {
  display: flex;
  flex-direction: row;
  height: 100%;
  gap: 40px;
  padding: 0 40px;
}

.garantia-column-one,
.garantia-column-two {
  padding: 0 10px;
  margin-bottom: 30px;
}

div.countdown div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 426px) and (min-width: 320px) {
  .cta-home {
    flex-direction: column;
    top: 90px;
    height: 210px;
    padding: 20px 15px;
  }

  .cta-home h2 {
    font-size: 1.1rem;
  }

  .cta-home .countdown {
    height: 100%;
    align-items: flex-end;
  }

  .cta-home .countdown h2 {
    font-size: 2rem;
  }

  section.first-section {
    margin-top: 100px;
    flex-direction: column;
    padding-bottom: 0;
  }

  section.first-section .content-home {
    width: 100%;
    padding: 0;
  }

  .first-section .circle {
    padding-left: 0;
    margin: 35px auto 50px;
    width: 100%;
  }

  .first-section .circle:before {
    width: 240px;
    height: 240px;
    right: 50px;
    left: 0;
  }

  section.first-section .content-home p,
  section.first-section .content-home h3 {
    margin-bottom: 15px;
  }

  .cta-bg .moving-img img {
    display: none;
  }

  section.porque-cdo {
    width: 100%;
    padding: 0 10px;
  }

  .porque-cdo .grid {
    grid-template-columns: 1fr;
    padding: 0;
    max-width: 100%;
  }

  div.tarjeta-home .svg-inline--fa.fa-w-20 {
    width: 3rem;
    height: 20%;
    margin: 0 0 20px 0;
  }

  div.content-home {
    height: 80%;
  }

  .tarjeta-home {
    flex-direction: column;
    padding: 20px;
    align-items: center;
  }

  div.tarjeta-home .content-home h3 {
    font-size: 1.5rem;
  }

  div.content-home h3,
  div.content-home p {
    font-size: 0.8rem;
  }

  .docente {
    margin-bottom: 20px;
  }

  .container-items {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }

  .counter-items {
    height: max-content;
  }

  .counter-items svg {
    font-size: 2rem;
  }

  .counter-items h3 {
    font-size: 2rem;
    margin-top: 0;
    margin-bottom: 0;
  }

  .counter-items h4 {
    font-size: 1rem;
  }

  .docentes-list {
    grid-template-columns: 1fr;
  }

  .docente h3 {
    font-size: 1.5rem;
    margin-bottom: 5px;
  }

  div.docente p {
    font-size: 0.8rem;
  }

  .cta-bg {
    padding-top: 0;
  }

  .cta-bg .flex {
    max-width: 100%;
    margin: 0;
    padding: 0 10px;
  }

  .cta-bg .text {
    width: 100%;
    flex: none;
  }

  .garantia {
    padding: 50px 0;
  }

  .garantia .container-garantia {
    display: flex;
    flex-direction: column;
    padding: 0 30px;
    align-items: center;
  }

  .garantia-column-one,
  .garantia-column-two {
    padding: 0;
  }

  .garantia .img-holder {
    display: none;
  }

  .garantia ul li {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 768px) and (min-width: 427px) {
  .counter-items h4 {
    font-size: 0.8rem;
  }

  section.first-section .content-home p,
  section.first-section .content-home h3 {
    font-size: 1rem;
    margin-bottom: 10px;
  }

  .first-section .circle:before {
    display: block;
    width: 250px;
    height: 250px;
    background: #c9efff;
    border-radius: 50%;
    content: " ";
    position: absolute;
    z-index: -1;
    left: 6%;
    top: 20px;
  }

  .cta-home .countdown span {
    font-size: 4vw;
  }

  section.first-section {
    margin-top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .first-section .content p {
    font-size: 1.3rem;
  }

  .first-section .content h3 {
    font-size: 1.6rem;
  }

  .first-section .circle {
    padding: 50px 0 0 25px;
    margin-bottom: 50px;
  }

  section.cta-bg {
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .tarjeta-home {
    padding: 30px 15px;
  }

  .tarjeta-home .content p {
    font-size: 1rem;
  }

  .container-items {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    row-gap: 20px;
  }

  .counter-items {
    height: max-content;
  }

  section.counters svg:not(:root).svg-inline--fa {
    margin-top: 0;
    width: 10vw;
    height: max-content;
    margin-bottom: 5px;
    color: white;
  }

  .counter-items h3 {
    margin: 5px 0;
  }

  section.docentes-section {
    width: 100%;
    margin: 0;
  }

  .docente h3 {
    font-size: 6vw;
    margin: 10px 0;
  }

  div.docente p {
    font-size: 1.5rem;
  }

  .garantia h2 {
    font-size: 2rem;
  }

  .cta-bg .text h3 {
    font-size: 1.4rem;
  }

  .cta-bg .text p {
    font-size: 1rem;
  }

  .garantia .container-garantia {
    /* display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    height: max-content;
    padding: 0 20px 0 20px;
    grid-gap: 10px;
    margin: 0; */
    display: flex;
    flex-direction: column;
    padding: 0 100px;
    align-items: center;
  }

  .docentes-list {
    display: block;
    padding: 0 20px;
  }

  .counter-items h3 {
    font-size: 1.5rem;
  }
  .counter-items h4 {
    font-size: 1.2rem;
  }

  .porque-cdo .grid {
    grid-template-columns: 1fr;
  }

  .cta-bg .flex {
    display: block;
  }

  .cta-home {
    display: block;
    padding: 20px 10px;
    margin: 0;
    bottom: 2.5rem;
  }

  .cta-home h2 {
    padding: 0 20px;
    font-size: 1.6rem;
  }

  .cta-home .countdown h2 {
    padding: 0;
    color: white;
    font-size: 3rem;
  }

  .cta-home .countdown span {
    font-weight: 500;
  }

  .cta-home .countdown-holder {
    padding: 0 20px;
    margin-top: 20px;
  }

  .cta-home .countdown {
    justify-content: space-evenly;
    margin-bottom: 20px;
  }

  .cta-home .countdown-holder a {
    margin: 20px 0;
  }

  .first-section {
    display: block;
  }

  .first-section .circle:before {
    display: block;
    width: 300px;
    height: 300px;
    background: #c9efff;
    border-radius: 50%;
    content: " ";
    position: absolute;
    z-index: -1;
    left: 0;
    top: 20px;
  }

  .first-section .circle span {
    color: var(--color-principal);
  }

  .docente h3 {
    font-size: 3vw;
  }

  div.docente {
    margin-bottom: 40px;
  }

  section.garantia {
    height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 50px;
  }

  ul.garantia-column-one {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  ul.garantia-column-two {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  ul.garantia-column-one,
  ul.garantia-column-two {
    margin-bottom: 0;
  }

  .garantia .img-holder {
    width: 100%;
    grid-column-start: 1;
    grid-column-end: 1;
    display: flex;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  .garantia .img-holder img {
    /* width: 100%;
    margin: 0 auto;
    padding: 0; */
    display: none;
  }

  footer .blog .entry .img {
    flex: none;
  }

  .garantia ul li {
    margin-top: 20px;
    font-size: 1.5rem;
  }

  .docentes-list img {
    width: 80%;
  }
}

@media only screen and (min-width: 769px) {
  .cta-home {
    top: 40px;
  }
}
