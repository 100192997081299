.post-title {
  margin-bottom: 30px;
}

.main-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
}

.container-publicaciones {
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 70px;
  margin: 60px 0;
  padding: 0 100px;
}

.blog-container {
  display: grid;
  grid-template-columns: 1fr 280px;
  column-gap: 50px;
  margin-top: 50px;
}

.blog-container main h2 {
  text-transform: uppercase;
}

.post-content p,
.post-content h2 {
  margin: 30px 0;
}

.post-meta {
  border-top: 1px solid #eaeaea;
  padding: 12px 0;
  font-size: 0.8rem;
  font-family: "Roboto Slab", serif;
}

.post-meta svg {
  margin-right: 5px;
  color: var(--color-principal);
}

.post-content {
  margin: 30px 0;
  font-family: "Open Sans", sans-serif;
}
.post-content p {
  text-align: justify;
}
.post-content h1,
.post-content h2,
.post-content h3,
.post-content h4,
.post-content h5,
.post-content h6 {
  margin: 20px 0;
  text-transform: none !important;
}

.img-squared {
  width: 83px;
  height: 50px;
  position: relative;
}
.img-squared img {
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 60px;
  object-fit: cover;
  z-index: 2;
}

.sidebar-decorator {
  height: 3px;
  width: 100%;
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(0, 152, 215, 1) 35%,
    rgba(255, 255, 255, 1) 35%,
    rgba(255, 255, 255, 1) 41%,
    rgba(0, 0, 0, 1) 41%,
    rgba(0, 0, 0, 1) 100%
  );

  margin-bottom: 30px;
}

.card-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 10px;
}

.card-container::after {
  width: 50px;
  height: 3px;
  background-color: var(--color-secundario);
  content: " ";
  margin-top: 30px;
}

.card-container::before {
  width: 700px;
  height: 1px;
  background-color: #dcdcdc;
  position: absolute;
  bottom: left;
  margin-top: 10px;
}

.post-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  width: 100%;
}

.post-item img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.sidebar-post-item {
  margin-top: 25px;
  display: grid;
  grid-template-columns: 1fr 3fr;
}

.sidebar-post-item:hover h2.title {
  color: var(--color-principal);
  transition: ease all 0.3s;
}
.sidebar-post-item h2.title {
  font-size: 0.9rem;
  color: black;
  text-align: left;
}

.title-blog {
  font-size: 1.5rem;
}

.fecha {
  width: 60px;
  height: 60px;
  border: 2px solid var(--color-secundario);
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: content-box;
  padding-bottom: 8px;
  margin-right: 10px;
}

.fecha__numero {
  color: var(--color-secundario);
  width: max-content;
  height: 35px;
  margin: 0 auto;
  font-size: 2rem;
}

.fecha__iniciales {
  color: #8498df;
  width: max-content;
  height: max-content;
  margin: 0 auto;
}

.content-container {
  display: grid;
  grid-template-columns: 1fr 5fr;
}

.content {
  display: flex;
  flex-direction: column;
}

.separador {
  width: 31px;
  height: 3px;
  background-color: var(--color-principal);
  margin-top: 30px;
}

.sidebar-section {
  margin: 60px 0;
}

.first-section-sidebar {
  margin-top: 0;
}

.sidebar-search {
  margin-top: 25px;
  background-color: #f0f2f5;
  height: 78px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
}

.sidebar-search input {
  border: none;
  height: 38px;
  width: 85%;
  padding-left: 20px;
}

.sidebar-search input:focus {
  outline: none;
}

.sidebar-search button {
  border: none;
  height: 38px;
  width: 10%;
  padding-right: 20px;
  padding-left: 5px;
  background-color: #fff;
}

.search-container {
  width: 70%;
  background-color: #fff;
}

.fa-search {
  color: var(--color-principal);
}

.blog {
  margin-left: 30px;
}

.font-black {
  margin-top: 20px;
  color: black;
  font-size: 1rem;
}

/*

    Pagination

*/

.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
}

.pagination li {
  margin-right: 10px;
}

.pagination > .active > a {
  background-color: #47ccde;
  border-color: #47ccde;
  color: #fff;
}
.pagination > li > a {
  border: 2px solid #47ccde;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #47ccde;
  border-color: #47ccde;
  outline: none;
}
.pagination > li > a,
.pagination > li > span {
  color: #47ccde;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}

@media only screen and (max-width: 425px) {
  .container-publicaciones {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 60px auto;
  }

  .main-section {
    grid-template-columns: 1fr;
  }

  .card-container {
    width: 85%;
    margin: 0 auto;
  }

  .title-blog {
    font-size: 1rem;
  }

  .fecha {
    width: 30px;
    height: 30px;
  }

  .fecha__numero {
    font-size: 1.2rem;
  }

  .fecha__iniciales {
    font-size: 0.8rem;
  }

  .font-black-blog {
    margin-top: 10px;
    font-size: 0.8rem;
  }
}

@media only screen and (max-width: 424px) and (min-width: 1px) {
  .blog-container {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 768px) and (min-width: 425px) {
  .blog-container {
    display: block;
  }

  .sidebar-section {
    display: none;
  }

  .main-section {
    grid-template-columns: 1fr;
    grid-gap: 50px 0;
  }

  .container-publicaciones {
    grid-template-columns: 1fr;
    padding: 0;
  }

  .pagination {
    justify-content: center;
  }

  ul.pagination li.previus a {
    font-size: 3vw;
  }

  .card-container {
    width: 85%;
    margin: 0 auto;
  }

  div div.content h2.title {
    font-size: 1.3rem;
  }
}
