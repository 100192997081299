@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

.primera-seccion-landing-excel {
  background-image: url(../../../images/Fondo-Banner-Principal.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
  width: 100%;
  height: 600px;
  display: flex;
  padding: 0 100px;
}

.primera-seccion-landing-excel img {
  width: 100%;
  height: 80%;
  align-self: flex-end;
}

.primera-seccion-landing-excel__texto {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.primera-seccion-landing-excel__texto span {
  font-family: Montserrat, "Helvetica Neue", Helvetica, Arial;
  font-weight: 700;
  font-size: 5rem;
  line-height: 90px;
  height: max-content;
  width: max-content;
  user-select: none;
}

.primera-seccion-landing-excel__texto--microsoft {
  margin-bottom: 20px;
}

.primera-seccion-landing-excel__texto p {
  font-family: Montserrat, "Helvetica Neue", Helvetica, Arial;
  font-size: 1.2rem;
  margin-bottom: 20px;
  user-select: none;
}

.segunda-seccion-landing-excel {
  display: flex;
  width: 100%;
  height: 350px;
  align-items: center;
  padding: 0 40px;
}

.tercera-seccion-landing-excel {
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ebebeb;
  padding: 50px 10vw;
}

.tercera-seccion-landing-excel a {
  margin-bottom: 30px;
  font-size: 2rem;
  font-weight: bold;
  font-family: Montserrat, "Helvetica Neue", Helvetica;
  transition: 0.2s;
}

.tercera-seccion-landing-excel a:hover {
  color: var(--color-principal);
  transition: 0.2s;
}

.beneficios-contenedor {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0 50px;
  height: max-content;
}

.beneficios-primera-parte {
  grid-column-start: 1;
  grid-column-end: 2;
}

.beneficios-segunda-parte {
  grid-column-start: 2;
  grid-column-end: 3;
}

.beneficios-tercera-parte {
  grid-column-start: 3;
  grid-column-end: 4;
}

.beneficios-segunda-parte img {
  width: 100%;
}

.beneficios-primera-parte ul li,
.beneficios-tercera-parte ul li {
  margin-bottom: 10px;
}

.beneficios-tercera-parte {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.tercera-seccion-landing-excel .beneficios-tercera-parte a {
  margin-bottom: 20px;
}

.beneficios-tercera-parte a button {
  text-transform: uppercase;
  width: 50%;
  height: 50px;
  color: white;
  background-color: var(--color-principal);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: Montserrat, "Helvetica Neue", Helvetica;
  font-weight: 500;
  transition: 0.2s;
}

.beneficios-tercera-parte a button:hover {
  filter: saturate(65%);
  transition: 0.2s;
}

.cuarta-seccion-landing-excel {
  width: 100%;
  height: 450px;
  background-image: url(../../../images/Banner-02-solo.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  grid-template-columns: 1fr 3fr 3fr 1fr 3fr 1fr;
  grid-template-rows: 1fr;
}

.cuarta-seccion__logos-cursos,
.cuarta-seccion__texto {
  display: flex;
}

.cuarta-seccion__logos-cursos {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row: 1/2;
  flex-wrap: wrap;
  align-content: center;
}

.cuarta-seccion__logos-cursos img {
  width: 25%;
  margin: 10px;
  position: relative;
  z-index: 1;
}

.cuarta-seccion__foto {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row: 1/2;
  display: flex;
  align-items: flex-end;
  height: inherit;
}

.cuarta-seccion__foto img {
  width: 115%;
  position: relative;
  z-index: 0;
  right: 70px;
}

.cuarta-seccion__texto {
  grid-column-start: 4;
  grid-column-end: 6;
  grid-row: 1/2;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.cuarta-seccion__texto span {
  font-family: "Roboto", sans-serif;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
  text-align: right;
  font-size: 1.1rem;
  user-select: none;
}

.cuarta-seccion__texto span:nth-child(1) {
  font-size: 2rem;
  font-weight: 700;
  text-align: right;
  text-transform: uppercase;
  padding-left: 50px;
}

.cuarta-seccion__texto span:nth-child(2) {
  font-weight: bold;
  text-align: right;
  font-size: 1.1rem;
  padding-left: 120px;
}

.cuarta-seccion__texto button {
  width: 60%;
  height: 50px;
  background: linear-gradient(rgb(255, 219, 76) 0%, rgb(255, 205, 2) 100%);
  border: none;
  box-shadow: blur(0px) grayscale(0%) brightness(100%);
  border-radius: 5px;
  text-transform: uppercase;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 1.3rem;
  font-weight: 500;
  -webkit-box-shadow: -10px 15px 60px -15px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -10px 15px 60px -15px rgba(0, 0, 0, 0.75);
  box-shadow: -10px 15px 60px -15px rgba(0, 0, 0, 0.75);
  transition: 1s;
}

.cuarta-seccion__texto button:hover {
  cursor: pointer;
  filter: brightness(1.1);
  transition: 0.5s;
}

.quinta-seccion-landing-excel {
  width: 100%;
  height: max-content;
  margin-bottom: 50px;
}

.quinta-seccion-landing-excel .carousel-parent h2 {
  margin-top: 0;
}

.sexta-seccion-landing-excel {
  display: grid;
  grid-template-columns: 0.8fr 2fr 3fr 0.8fr;
  background-color: #f1f6fb;
  height: 600px;
  padding: 50px 0;
}

.sexta-seccion-landing-excel img {
  grid-column: 2 / 3;
  width: 100%;
}

.sexta-seccion-landing-excel__titulos {
  display: flex;
  flex-direction: column;
}

.sexta-seccion-landing-excel__titulos span:nth-child(1) {
  font-family: Roboto, sans-serif;
  font-size: 2.5rem;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 20px;
}

.sexta-seccion-landing-excel__titulos span:nth-child(1):not(.texto-verde) {
  padding-right: 160px;
}

.texto-verde {
  color: #01c47e;
}

.sexta-seccion-landing-excel__titulos span:nth-child(2),
.sexta-seccion-landing-excel__titulos span:nth-child(3) {
  font-family: Roboto, sans-serif;
  font-size: 1.5rem;
}

.sexta-seccion-landing-excel__titulos span:nth-child(2) {
  text-decoration: line-through;
  color: #ff1000;
}

.sexta-seccion-landing-excel__titulos span:nth-child(3) {
  font-weight: 500;
  margin-bottom: 20px;
}

.sexta-seccion-landing-excel__texto {
  grid-column: 3 / 4;
  display: flex;
  flex-direction: column;
}

.sexta-seccion-landing-excel__beneficios {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.sexta-seccion-landing-excel__beneficios--primera-seccion {
  grid-column: 1 / 2;
  margin-right: 10px;
}

.sexta-seccion-landing-excel__beneficios--segunda-seccion {
  grid-column: 2 / 3;
}

.sexta-seccion-landing-excel__beneficios--primera-seccion,
.sexta-seccion-landing-excel__beneficios--segunda-seccion {
  display: flex;
  flex-direction: column;
}

.sexta-seccion-landing-excel__beneficios--primera-seccion span,
.sexta-seccion-landing-excel__beneficios--segunda-seccion span {
  font-family: Roboto, sans-serif;
  letter-spacing: 0.2em;
  font-size: 0.8rem;
  font-weight: 500;
  margin-bottom: 10px;
}

svg.svg-inline--fa.fa-circle.fa-w-16.icono-excel-landing {
  background-color: black;
  width: 12px;
  height: 12px;
  margin-right: 5px;
}

svg.svg-inline--fa.fa-circle.fa-w-16.icono-excel-landing path {
  color: black;
  width: 15px;
  height: 15px;
}

.sexta-seccion-landing-excel__beneficios button {
  width: 80%;
  height: 50px;
  background-color: #01c47e;
  border: none;
  border-radius: 5px;
  transition: 1s;
}

.sexta-seccion-landing-excel__beneficios button:hover {
  cursor: pointer;
  filter: brightness(1.2);
  transition: 0.5s;
}

.sexta-seccion-landing-excel__beneficios button a {
  text-transform: uppercase;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  color: #fff;
}

.septima-seccion-landing-excel {
  display: grid;
  grid-template-columns: 0.5fr 4fr 4fr 0.5fr;
  height: max-content;
}

.septima-seccion-landing-excel div {
  grid-column: 2 / 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.septima-seccion-landing-excel div span,
.septima-seccion-landing-excel button a {
  font-family: Roboto, sans-serif;
}

.septima-seccion-landing-excel div span:nth-child(1) {
  font-size: 3.5rem;
  font-weight: bold;
  line-height: 50px;
  margin-bottom: 30px;
}

.septima-seccion-landing-excel div span:nth-child(2) {
  font-size: 1.5rem;
  font-weight: 500;
}

.septima-seccion-landing-excel img {
  grid-column: 3 / 4;
  width: 100%;
  align-self: end;
}

div.septima-seccion-landing-excel__seccion-boton {
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
}

div.septima-seccion-landing-excel__seccion-boton a {
  width: 55%;
  height: 50px;
}

div.septima-seccion-landing-excel__seccion-boton a button {
  width: 100%;
  height: 100%;
  font-family: Roboto, sans-serif;
  font-size: 1.8rem;
  font-weight: 500;
  text-transform: uppercase;
  background-color: transparent;
  border: 1px solid black;
}

div.septima-seccion-landing-excel__seccion-boton a button:hover {
  cursor: pointer;
}

@media only screen and (max-width: 425px) {
  .primera-seccion-landing-excel,
  .segunda-seccion-landing-excel,
  .tercera-seccion-landing-excel,
  .cuarta-seccion-landing-excel,
  .quinta-seccion-landing-excel,
  .sexta-seccion-landing-excel {
    width: 100%;
    height: max-content;
    padding: 25px 30px;
  }

  .primera-seccion-landing-excel {
    padding: 10px;
  }

  .primera-seccion-landing-excel__texto p {
    font-size: 1rem;
    font-weight: 500;
  }

  .primera-seccion-landing-excel img {
    display: none;
  }

  .primera-seccion-landing-excel__texto {
    display: block;
    height: max-content;
  }

  .primera-seccion-landing-excel__texto span {
    font-size: 2rem;
    display: flex;
    width: 100%;
    height: max-content;
    line-height: 40px;
  }

  .segunda-seccion-landing-excel {
    flex-direction: column;
  }

  .segunda-seccion-landing-excel div {
    margin: 15px 0;
  }

  .tercera-seccion-landing-excel a {
    font-size: 1.5rem;
    text-align: center;
  }

  .beneficios-contenedor {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, max-content);
  }

  .beneficios-primera-parte {
    grid-column: 1 / 1;
    grid-row: 1 / 2;
  }

  .beneficios-segunda-parte {
    grid-column: 1 / 1;
    grid-row: 3 / 4;
  }

  .beneficios-tercera-parte {
    grid-column: 1 / 1;
    grid-row: 2 / 3;
  }

  .tercera-seccion-landing-excel .beneficios-tercera-parte a {
    margin-top: 20px;
  }

  .cuarta-seccion-landing-excel {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, max-content);
  }

  .cuarta-seccion__texto {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  .cuarta-seccion__foto {
    display: none;
  }

  .cuarta-seccion__logos-cursos {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    justify-content: center;
  }

  div.cuarta-seccion__texto span,
  .cuarta-seccion__texto span:nth-child(1),
  .cuarta-seccion__texto span:nth-child(2) {
    padding: 0;
    text-align: center;
  }

  .cuarta-seccion__texto span:nth-child(1) {
    font-size: 1.5rem;
  }

  .cuarta-seccion__texto span {
    font-size: 1rem;
  }

  .cuarta-seccion__texto button {
    font-size: 1rem;
    margin: 0 auto 10px;
  }

  .quinta-seccion-landing-excel .carousel-parent h2 {
    font-size: 1.5rem;
  }

  .sexta-seccion-landing-excel {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, max-content);
  }

  .sexta-seccion-landing-excel img,
  .sexta-seccion-landing-excel__texto {
    grid-column: 1 / 2;
  }

  .sexta-seccion-landing-excel img {
    display: none;
  }

  .sexta-seccion-landing-excel__texto {
    grid-row: 1 / 2;
  }

  .sexta-seccion-landing-excel__titulos {
    text-align: center;
  }

  .sexta-seccion-landing-excel__titulos span:nth-child(1):not(.texto-verde) {
    padding: 0;
  }

  .sexta-seccion-landing-excel__titulos span:nth-child(1) {
    font-size: 1.5rem;
  }

  .sexta-seccion-landing-excel__titulos span:nth-child(2),
  .sexta-seccion-landing-excel__titulos span:nth-child(3) {
    font-size: 1.2rem;
  }

  .sexta-seccion-landing-excel__beneficios {
    display: flex;
    flex-direction: column;
  }

  .sexta-seccion-landing-excel__beneficios button {
    margin: 10px auto;
  }

  .sexta-seccion-landing-excel__beneficios button a {
    font-size: 1rem;
  }

  .septima-seccion-landing-excel {
    padding-top: 30px;
    padding-bottom: 0;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr max-content;
  }

  .septima-seccion-landing-excel div {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  .septima-seccion-landing-excel div span:nth-child(1) {
    font-size: 1.5rem;
    line-height: 30px;
    text-align: center;
  }

  .septima-seccion-landing-excel div span:nth-child(2) {
    font-size: 1.2rem;
    text-align: center;
  }

  .septima-seccion-landing-excel img {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }

  div.septima-seccion-landing-excel__seccion-boton a button {
    font-size: 1rem;
  }
}

@media only screen and (min-width: 426px) and (max-width: 768px) {
  .primera-seccion-landing-excel,
  .segunda-seccion-landing-excel,
  .tercera-seccion-landing-excel,
  .cuarta-seccion-landing-excel,
  .sexta-seccion-landing-excel,
  .septima-seccion-landing-excel {
    width: 100%;
    height: max-content;
  }

  .primera-seccion-landing-excel {
    padding: 0 20px;
    display: flex;
    height: 100%;
  }

  .primera-seccion-landing-excel img {
    display: none;
  }

  .primera-seccion-landing-excel__texto {
    width: 100%;
  }

  .primera-seccion-landing-excel__texto span {
    font-size: 3rem;
  }

  .segunda-seccion-landing-excel {
    flex-direction: column;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .tercera-seccion-landing-excel a {
    text-align: center;
  }

  .beneficios-primera-parte ul li,
  .beneficios-tercera-parte ul li {
    font-size: 1.2rem;
  }

  .beneficios-contenedor {
    display: block;
    height: max-content;
  }

  .beneficios-segunda-parte img {
    display: none;
  }

  .tercera-seccion-landing-excel .beneficios-tercera-parte a {
    margin-top: 10px;
  }

  .beneficios-tercera-parte a button {
    font-size: 1.2rem;
  }

  .cuarta-seccion-landing-excel {
    padding: 50px 35px;
  }

  .cuarta-seccion-landing-excel {
    display: flex;
    flex-direction: column;
  }

  .cuarta-seccion__foto img {
    display: none;
  }

  .cuarta-seccion__logos-cursos {
    justify-content: center;
  }

  .cuarta-seccion__logos-cursos img {
    width: 10%;
    position: static;
  }

  .cuarta-seccion__texto span:nth-child(1),
  .cuarta-seccion__texto span:nth-child(2) {
    padding: 0;
    margin: 10px auto;
  }

  .cuarta-seccion__texto span {
    text-align: center;
  }

  .cuarta-seccion__texto button {
    margin: 0 auto;
  }

  .sexta-seccion-landing-excel {
    display: flex;
    flex-direction: column;
    padding: 50px 35px;
  }

  .sexta-seccion-landing-excel__titulos span:nth-child(1) {
    margin-bottom: 0;
  }

  .sexta-seccion-landing-excel img {
    display: none;
  }

  .sexta-seccion-landing-excel__beneficios {
    display: flex;
    flex-direction: column;
  }

  .sexta-seccion-landing-excel__beneficios button {
    margin: 10px auto;
  }

  .septima-seccion-landing-excel {
    display: flex;
    flex-direction: column;
    padding: 50px 35px 0;
  }

  .septima-seccion-landing-excel img {
    width: 50%;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .primera-seccion-landing-excel {
    padding: 0 35px;
    height: 500px;
  }

  .primera-seccion-landing-excel__texto p {
    font-size: 1rem;
  }

  .primera-seccion-landing-excel img {
    width: 75%;
    height: 75%;
  }

  .primera-seccion-landing-excel__texto {
    justify-content: center;
  }

  .primera-seccion-landing-excel__texto span {
    font-size: 3.5rem;
    margin-bottom: 0;
    line-height: 60px;
  }

  .beneficios-primera-parte ul li,
  .beneficios-tercera-parte ul li {
    font-size: 1rem;
  }

  .cuarta-seccion__texto span:nth-child(1) {
    font-size: 1.5rem;
  }

  .cuarta-seccion__texto span:nth-child(2),
  .cuarta-seccion__texto span {
    font-size: 1rem;
  }

  .cuarta-seccion__texto button {
    font-size: 1rem;
  }

  .sexta-seccion-landing-excel__titulos span:nth-child(1):not(.texto-verde) {
    padding-right: 100px;
  }

  .sexta-seccion-landing-excel__titulos span:nth-child(1) {
    font-size: 1.8rem;
  }

  .sexta-seccion-landing-excel__titulos span:nth-child(2),
  .sexta-seccion-landing-excel__titulos span:nth-child(3) {
    font-size: 1.2rem;
  }

  .sexta-seccion-landing-excel__beneficios button a {
    font-size: 1rem;
  }

  div.septima-seccion-landing-excel__seccion-boton a button {
    font-size: 1.2rem;
  }

  .septima-seccion-landing-excel {
    padding-top: 50px;
  }
}
