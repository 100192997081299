footer {
  width: 100%;
  height: max-content;
  background: var(--color-principal);
  padding: 50px 0;
  color: white;
  position: relative;
  z-index: 2;
}
footer .container {
  margin-top: 30px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 80%;
}

footer h3 {
  color: white;
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 20px;
}

.acerca {
  margin-right: 30px;
}

.paginas {
  margin: 0 30px;
}

footer .paginas {
  flex: 1;
}

footer .paginas ul {
  padding-left: 20px;
}
footer .paginas ul li {
  margin: 5px 0;
  font-family: "Open Sans", sans-serif;
  font-size: 1.4rem;
}

footer .paginas ul li a {
  color: white;
  transition: ease all 0.3s;
  font-size: 1.2rem;
}

footer .paginas ul li a:hover {
  color: goldenrod;
}

footer .blog {
  flex: 2;
}

footer .blog .entries {
  display: flex;
  flex-direction: column;
}

footer .blog .entry {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
}

footer .blog .entry .img {
  margin-right: 15px;
}

footer .blog .entry .img img {
  max-width: 80px;
}

footer .blog .entry .title {
  width: 200px;
}

/*
Para las direcciones
*/

.ul_dir {
  list-style: none;
  text-align: center;
}

footer .container h3 {
  font-family: "Roboto Slab", serif;
}

.contacto a {
  color: white;
}

.numeros {
  margin: 15px 0;
}

@media only screen and (max-width: 768px) {
  footer {
    height: max-content;
    padding: 30px 40px;
  }

  footer .blog .entries {
    grid-template-columns: 1fr;
    row-gap: 15px;
  }

  footer .container {
    flex-direction: column;
    padding: 0;
    margin: 0;
    width: 100%;
  }

  footer .container h3 {
    width: max-content;
    margin: 0 auto 20px;
  }

  footer .blog {
    margin-left: 0;
    margin-top: 40px;
  }

  footer .blog,
  footer .paginas {
    width: 100%;
  }

  footer .blog .entry .img {
    flex: none;
  }

  footer .paginas ul li a {
    font-size: 1rem;
  }
}
