@import "Caras.css";
@import "Dinamic-css.css";
@import "Elementor-frontend-inline-css.css";
@import "Wp-custom-css.css";
/*@import "rs6.css";*/
@import "Caras.css";
/* 
*{
	overflow-x: hidden !important;
}
 */
.cursos-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;
  column-gap: 20px;
}

.cursos-list img {
  width: 100%;
}

.cursos-list .curso {
  text-align: center;
}

.cursos-landing-section {
  margin-top: 2em;
}

.excel-info {
  width: 100%;
  background-image: url(/src/images/Banner-02-solo.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: grid;
  grid-template-columns: 1fr 3fr 3fr;
  grid-template-areas: "cursos personaje texto";
}

.cursos-logo {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-area: cursos;
}

.personaje-excel {
  grid-area: personaje;
}

.texto-excel {
  grid-area: "texto";
}

/*
================
Utilizar todo rs
================
*/
.rs-fullwidth-wrap {
  z-index: 0;
}

.rs-fullwidth-wrap {
  position: relative;
  width: 100%;
  height: auto;
  display: block;
  overflow: visible;
  max-width: none !important;
}

.rs-layer-wrap,
.rs-mask,
.rs-module-wrap,
.rs-module .rs-layer,
.rs-module img {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.rs-module-wrap {
  visibility: hidden;
}
.rs-module-wrap,
.rs-module-wrap * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rs-module-wrap {
  position: relative;
  z-index: 1;
  width: 100%;
  display: block;
}

.rs-fw-forcer {
  display: block;
  width: 100%;
  pointer-events: none;
}
.rs-module {
  position: relative;
  overflow: hidden;
  display: block;
}

.rs-loader.spinner0 {
  width: 40px;
  height: 40px;
  background-color: #fff;
  /*background-image: url(../assets/loader.gif);*/
  background-repeat: no-repeat;
  background-position: center center;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  margin-top: -20px;
  margin-left: -20px;
  animation: tp-rotateplane 1.2s infinite ease-in-out;
  border-radius: 3px;
}

.rs-loader {
  top: 50%;
  left: 50%;
  z-index: 10000;
  position: absolute;
}

.rs-progress {
  visibility: hidden;
  position: absolute;
  z-index: 200;
  width: 100%;
  height: 100%;
}
.rs-slides,
.rs-slide,
.rs-slide::before {
  position: absolute;
  text-indent: 0em;
  top: 0px;
  left: 0px;
}

rs-progress-bar,
.rs-progress-bar {
  display: block;
  z-index: 20;
  box-sizing: border-box;
  background-clip: content-box;
  position: absolute;
  /* vertical-align: top; */
  line-height: 0px;
  width: 100%;
  height: 100%;
}

/*

Clases e Ids personalizadas para landing

*/

.landing_excel_1 {
  background: transparent none repeat scroll 0% 0%;
  padding: 0px;
  margin: 0px auto;
  height: 600px;
  visibility: visible;
  position: absolute;
  overflow: visible;
  width: 1263px;
  left: -47px;
}

.landing_excel_2 {
  height: 600px;
  max-height: 600px;
  margin-bottom: 0px;
  margin-top: 0px;
}

.landing_excel_3 {
  background: rgba(255, 255, 255, 0.5) none repeat scroll 0% 0%;
}

.landing_excel_4 {
  visibility: visible;
  max-height: none;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.landing_excel_5 {
  overflow: hidden;
  height: 100%;
  width: 100%;
  z-index: 20;
  opacity: 1;
  visibility: inherit;
}

.landing_excel_6 {
  transform-origin: 50% 50% 0px;
  visibility: inherit;
  opacity: 1;
  transform: perspective(600px);
}

.landing_excel_7 {
  background-color: transparent;
  background-repeat: no-repeat;
  /* background-image: url("file:///wp-content/uploads/2020/09/Fondo-Banner-Principal-1536x568.jpg"); */
  background-size: cover;
  background-position: 50% 50%;
  width: 100%;
  height: 100%;
  opacity: 1;
  transform: translate(0px);
  visibility: inherit;
  z-index: 20;
}

.landing_excel_8 {
  position: absolute;
  display: block;
  pointer-events: auto;
  left: 15.5px;
  top: 75px;
  z-index: 5;
}

.landing_excel_9 {
  position: absolute;
  display: block;
  overflow: visible;
}

.landing_excel_10 {
  z-index: 5;
  color: rgb(255, 255, 255);
  width: 690px;
  height: 523px;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  border-color: rgb(34, 34, 34);
  transform-origin: 50% 50% 0px;
  opacity: 1;
  transform: translate(0px);
  visibility: visible;
}

.landing_excel_10.imagen {
  position: relative;
  height: 100%;
  width: 100%;
}

.landing_excel_11 {
  position: absolute;
  display: block;
  pointer-events: auto;
  left: 699.5px;
  top: 325px;
  z-index: 6;
}

.landing_excel_12 {
  z-index: 6;
  font-family: "Montserrat";
  height: auto;
  color: rgb(59, 59, 61);
  text-decoration: none;
  white-space: normal;
  width: 429px;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  text-align: left;
  line-height: 37px;
  letter-spacing: 0px;
  font-weight: 400;
  font-size: 18px;
  border-color: rgb(34, 34, 34);
  transform-origin: 50% 50% 0px;
  opacity: 1;
  transform: translate(0px);
  visibility: visible;
}

.landing_excel_13 {
  position: absolute;
  display: block;
  pointer-events: auto;
  left: 696.5px;
  top: 162px;
  z-index: 7;
}

.landing_excel_14 {
  z-index: 7;
  font-family: Montserrat;
  width: auto;
  color: rgb(0, 0, 0);
  text-decoration: none;
  white-space: nowrap;
  height: 128px;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  text-align: left;
  line-height: 38px;
  letter-spacing: 0px;
  font-weight: 700;
  font-size: 72px;
  border-color: rgb(34, 34, 34);
  transform-origin: 50% 50% 0px;
  opacity: 1;
  transform: translate(0px);
  visibility: visible;
}

.landing_excel_15 {
  position: absolute;
  display: block;
  pointer-events: auto;
  left: 702.5px;
  top: 372px;
  z-index: 6;
}

.landing_excel_16 {
  z-index: 6;
  font-family: Montserrat;
  height: auto;
  color: rgb(59, 59, 61);
  text-decoration: none;
  white-space: normal;
  width: 486px;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  text-align: left;
  line-height: 25px;
  letter-spacing: 0px;
  font-weight: 400;
  font-size: 18px;
  border-color: rgb(34, 34, 34);
  transform-origin: 50% 50% 0px;
  opacity: 1;
  transform: translate(0px);
  visibility: visible;
}

.landing_excel_17 {
  position: relative;
  left: -31.5px;
  box-sizing: border-box;
  width: 1263px;
  padding-left: 31.5px;
  padding-right: 31.5px;
}

.landing_excel_18 {
  font-size: 20px;
  color: #0a0a0a;
  line-height: 1px;
  text-align: center;
  font-family: Abel;
  font-weight: 400;
  font-style: normal;
}

.landing_excel_19 {
  font-size: 20px;
  color: #000000;
  line-height: 1px;
  text-align: center;
  font-family: Abel;
  font-weight: 400;
  font-style: normal;
}

.landing_excel_20 {
  font-size: 20px;
  color: #000000;
  line-height: 1px;
  text-align: center;
  font-family: Abel;
  font-weight: 400;
  font-style: normal;
}

.landing_excel_21 {
  position: relative;
  left: -31.5px;
  box-sizing: border-box;
  width: 1263px;
  padding-left: 31.5px;
  padding-right: 31.5px;
}

.landing_excel_22 {
  font-size: 35px;
  color: #000000;
  text-align: center;
  font-family: Montserrat;
  font-weight: 700;
  font-style: normal;
}

.landing_excel_23 {
  position: relative;
  left: -31.5px;
  box-sizing: border-box;
  width: 1263px;
  padding-left: 31.5px;
  padding-right: 31.5px;
}

.landing_excel_24 {
  background: transparent none repeat scroll 0% 0%;
  padding: 0px;
  margin: 0px auto;
  height: 450px;
  visibility: visible;
  position: absolute;
  overflow: visible;
  width: 1263px;
  left: -47px;
}

.landing_excel_25 {
  height: 450px;
  max-height: 450px;
  margin-bottom: 0px;
  margin-top: 0px;
}

.landing_excel_26 {
  background: rgba(255, 255, 255, 0.5) none repeat scroll 0% 0%;
}

.landing_excel_27 {
  visibility: visible;
  max-height: none;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.landing_excel_28 {
	overflow: hidden;
	height: 100%;
	width: 100%;
	z-index: 20;
	opacity: 1;
	visibility: inherit;
	background-image: url('http://wp2.cursosdeofimatica.com/wp-content/uploads/2020/09/Banner-02-solo.jpg');
}

.landing_excel_29 {
  transform-origin: 50% 50% 0px;
  visibility: inherit;
  opacity: 1;
  transform: perspective(600px);
}

.landing_excel_30 {
  background-color: transparent;
  background-repeat: no-repeat; /*  background-image: url('file:///wp-content/uploads/2020/09/Banner-02-solo.jpg'); */
  background-size: cover;
  background-position: 50% 50%;
  width: 100%;
  height: 100%;
  opacity: 1;
  transform: translate(0px);
  visibility: inherit;
  z-index: 20;
}

.landing_excel_31 {
  position: absolute;
  display: block;
  pointer-events: auto;
  left: 32.5px;
  top: 68px;
  z-index: 8;
}

.landing_excel_32 {
  z-index: 8;
  color: rgb(255, 255, 255);
  width: 103px;
  height: 97px;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  border-color: rgb(34, 34, 34);
  transform-origin: 50% 50% 0px;
  opacity: 1;
  transform: translate(0px);
  visibility: visible;
}

.landing_excel_33 {
  position: absolute;
  display: block;
  pointer-events: auto;
  left: 161.5px;
  top: 68px;
  z-index: 9;
}

.landing_excel_34 {
  z-index: 9;
  color: rgb(255, 255, 255);
  width: 108px;
  height: 102px;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  border-color: rgb(34, 34, 34);
  transform-origin: 50% 50% 0px;
  opacity: 1;
  transform: translate(0px);
  visibility: visible;
}

.landing_excel_35 {
  position: absolute;
  display: block;
  pointer-events: auto;
  left: 293.5px;
  top: 68px;
  z-index: 10;
}

.landing_excel_35_5 {
  z-index: 10;
  color: rgb(255, 255, 255);
  width: 106px;
  height: 100px;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  border-color: rgb(34, 34, 34);
  transform-origin: 50% 50% 0px;
  opacity: 1;
  transform: translate(0px);
  visibility: visible;
}

.landing_excel_36 {
  position: absolute;
  display: block;
  pointer-events: auto;
  left: 32.5px;
  top: 196px;
  z-index: 11;
}

.landing_excel_37 {
  z-index: 11;
  color: rgb(255, 255, 255);
  width: 109px;
  height: 103px;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  border-color: rgb(34, 34, 34);
  transform-origin: 50% 50% 0px;
  opacity: 1;
  transform: translate(0px);
  visibility: visible;
}

.landing_excel_38 {
  position: absolute;
  display: block;
  pointer-events: auto;
  left: 162.5px;
  top: 196px;
  z-index: 12;
}

.landing_excel_39 {
  z-index: 12;
  color: rgb(255, 255, 255);
  width: 107px;
  height: 101px;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  border-color: rgb(34, 34, 34);
  transform-origin: 50% 50% 0px;
  opacity: 1;
  transform: translate(0px);
  visibility: visible;
}

.landing_excel_40 {
  position: absolute;
  display: block;
  pointer-events: auto;
  left: 231.5px;
  top: -15px;
  z-index: 13;
}

.landing_excel_41 {
  z-index: 13;
  color: rgb(255, 255, 255);
  width: 588px;
  height: 466px;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  border-color: rgb(34, 34, 34);
  transform-origin: 50% 50% 0px;
  opacity: 1;
  transform: translate(0px);
  visibility: visible;
}

.landing_excel_42 {
  z-index: 14;
  font-family: Roboto;
  height: auto;
  width: auto;
  color: rgb(0, 0, 0);
  text-decoration: none;
  white-space: nowrap;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  text-align: right;
  line-height: 34px;
  letter-spacing: 0px;
  font-weight: 700;
  font-size: 30px;
  border-color: rgb(34, 34, 34);
  transform-origin: 50% 50% 0px;
  opacity: 1;
  transform: translate(0px);
  visibility: visible;
}

.landing_excel_43 {
  position: absolute;
  display: block;
  pointer-events: auto;
  left: 885.5px;
  top: 152px;
  z-index: 15;
}

.landing_excel_44 {
  z-index: 15;
  font-family: Roboto;
  height: auto;
  width: auto;
  color: rgb(0, 0, 0);
  text-decoration: none;
  white-space: nowrap;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  text-align: right;
  line-height: 20px;
  letter-spacing: 0px;
  font-weight: 500;
  font-size: 18px;
  border-color: rgb(34, 34, 34);
  transform-origin: 50% 50% 0px;
  opacity: 1;
  transform: translate(0px);
  visibility: visible;
}

.landing_excel_45 {
  position: absolute;
  display: block;
  pointer-events: auto;
  left: 669.5px;
  top: 212px;
  z-index: 16;
}

.landing_excel_46 {
  z-index: 16;
  font-family: Roboto;
  color: rgb(0, 0, 0);
  text-decoration: none;
  white-space: normal;
  width: 539px;
  height: 159px;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  text-align: right;
  line-height: 20px;
  letter-spacing: 0px;
  font-weight: 400;
  font-size: 16px;
  border-color: rgb(34, 34, 34);
  transform-origin: 50% 50% 0px;
  opacity: 1;
  transform: translate(0px);
  visibility: visible;
}

.landing_excel_47 {
  position: absolute;
  display: block;
  pointer-events: auto;
  left: 912.5px;
  top: 371px;
  z-index: 17;
}

.landing_excel_48 {
  z-index: 17;
  background: rgba(0, 0, 0, 0)
    linear-gradient(rgb(255, 219, 76) 0%, rgb(255, 205, 2) 100%) repeat scroll
    0% 0%;
  font-family: Roboto;
  cursor: pointer;
  color: rgb(0, 0, 0);
  text-decoration: none;
  white-space: normal;
  width: 293px;
  height: 51px;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  text-align: center;
  line-height: 50px;
  letter-spacing: 0px;
  font-weight: 500;
  font-size: 20px;
  border-color: transparent;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 10px 20px 0px;
  padding-left: 40px;
  border-radius: 5px;
  padding-right: 40px;
  filter: blur(0px) grayscale(0%) brightness(100%);
  transform-origin: 50% 50% 0px;
  opacity: 1;
  transform: translate(0px);
  visibility: visible;
}

.landing_excel_49 {
  position: relative;
  left: -31.5px;
  box-sizing: border-box;
  width: 1263px;
  padding-left: 31.5px;
  padding-right: 31.5px;
}

.landing_excel_50 {
  transform: translate3d(0px, 0px, 0px);
  transition: all 0s ease 0s;
  /*width: 3510px;*/
}

.landing_excel_51 {
  position: relative;
  left: -31.5px;
  box-sizing: border-box;
  width: 1263px;
  padding-left: 31.5px;
  padding-right: 31.5px;
}

.landing_excel_52 {
  background: transparent none repeat scroll 0% 0%;
  padding: 0px;
  margin: 0px auto;
  height: 500px;
  visibility: visible;
  position: absolute;
  overflow: visible;
  width: 1263px;
  left: -47px;
}

.landing_excel_53 {
  height: 500px;
  max-height: 500px;
  margin-bottom: 0px;
  margin-top: 0px;
}

.landing_excel_54 {
  background-color: rgb(241, 246, 251);
  background-repeat: no-repeat;
  /*background-image: url("file:///wp-content/plugins/revslider/public/assets/assets/transparent.png");*/
  background-size: cover;
  background-position: 50% 50%;
  width: 100%;
  height: 100%;
  opacity: 1;
  transform: translate(0px);
  visibility: inherit;
  z-index: 20;
}

.landing_excel_55 {
  position: absolute;
  display: block;
  pointer-events: auto;
  left: -2.5px;
  top: 33px;
  z-index: 5;
}

.landing_excel_56 {
  z-index: 5;
  color: rgb(255, 255, 255);
  width: 470px;
  height: 570px;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  border-color: rgb(34, 34, 34);
  transform-origin: 50% 50% 0px;
  opacity: 1;
  transform: translate(0px);
  visibility: visible;
}

.landing_excel_57 {
  position: absolute;
  display: block;
  pointer-events: auto;
  left: 450.5px;
  top: 15px;
  z-index: 6;
}

.landing_excel_58 {
  z-index: 6;
  font-family: Roboto;
  height: auto;
  color: rgb(0, 0, 0);
  text-decoration: none;
  white-space: normal;
  width: 647px;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  text-align: left;
  line-height: 44px;
  letter-spacing: 0px;
  font-weight: 500;
  font-size: 40px;
  border-color: rgb(34, 34, 34);
  transform-origin: 50% 50% 0px;
  opacity: 1;
  transform: translate(0px);
  visibility: visible;
}

.landing_excel_59 {
  color: rgb(1, 196, 126);
  text-align: left;
  line-height: 44px;
  letter-spacing: 0px;
  font-weight: 500;
  font-size: 40px;
  border-color: rgb(1, 196, 126);
}

.landing_excel_60 {
  text-align: left;
  line-height: 44px;
  letter-spacing: 0px;
  font-weight: 500;
  font-size: 40px;
  border-color: rgb(1, 196, 126);
}

.landing_excel_61 {
  position: absolute;
  display: block;
  pointer-events: auto;
  left: 453.5px;
  top: 162px;
  z-index: 7;
}

.landing_excel_62 {
  z-index: 7;
  font-family: Roboto;
  height: auto;
  width: auto;
  color: rgb(255, 16, 0);
  text-decoration: line-through;
  white-space: nowrap;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  text-align: left;
  line-height: 24px;
  letter-spacing: 2px;
  font-weight: 400;
  font-size: 22px;
  border-color: rgb(34, 34, 34);
  transform-origin: 50% 50% 0px;
  opacity: 1;
  transform: translate(0px);
  visibility: visible;
}

.landing_excel_63 {
  position: absolute;
  display: block;
  pointer-events: auto;
  left: 455.5px;
  top: 194px;
  z-index: 7;
}

.landing_excel_64 {
  z-index: 7;
  font-family: Roboto;
  height: auto;
  width: auto;
  color: rgb(0, 0, 0);
  text-decoration: none;
  white-space: nowrap;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  text-align: left;
  line-height: 24px;
  letter-spacing: 2px;
  font-weight: 500;
  font-size: 22px;
  border-color: rgb(34, 34, 34);
  transform-origin: 50% 50% 0px;
  opacity: 1;
  transform: translate(0px);
  visibility: visible;
}

.landing_excel_65 {
  position: absolute;
  display: block;
  pointer-events: auto;
  left: 461.5px;
  top: 246px;
  z-index: 8;
}

.landing_excel_66 {
  position: absolute;
  display: block;
  pointer-events: auto;
  left: 460.5px;
  top: 307px;
  z-index: 8;
}

.landing_excel_67 {
  position: absolute;
  display: block;
  pointer-events: auto;
  left: 459.5px;
  top: 373px;
  z-index: 8;
}

.landing_excel_68 {
  z-index: 8;
  font-family: Roboto;
  color: rgb(0, 0, 0);
  text-decoration: none;
  white-space: normal;
  width: 374px;
  height: 45px;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  text-align: left;
  line-height: 18px;
  letter-spacing: 2px;
  font-weight: 400;
  font-size: 14px;
  border-color: rgb(34, 34, 34);
  transform-origin: 50% 50% 0px;
  opacity: 1;
  transform: translate(0px);
  visibility: visible;
}

.landing_excel_69 {
  position: absolute;
  display: block;
  pointer-events: auto;
  left: 852.5px;
  top: 245px;
  z-index: 8;
}

.landing_excel_70 {
  position: absolute;
  display: block;
  pointer-events: auto;
  left: 850.5px;
  top: 305px;
  z-index: 8;
}

.landing_excel_71 {
  z-index: 8;
  font-family: Roboto;
  color: rgb(0, 0, 0);
  text-decoration: none;
  white-space: normal;
  width: 393px;
  height: 33px;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  text-align: left;
  line-height: 18px;
  letter-spacing: 2px;
  font-weight: 400;
  font-size: 14px;
  border-color: rgb(34, 34, 34);
  transform-origin: 50% 50% 0px;
  opacity: 1;
  transform: translate(0px);
  visibility: visible;
}

.landing_excel_72 {
  position: absolute;
  display: block;
  pointer-events: auto;
  left: 850.5px;
  top: 360px;
  z-index: 8;
}

.landing_excel_73 {
  z-index: 8;
  font-family: Roboto;
  color: rgb(0, 0, 0);
  text-decoration: none;
  white-space: normal;
  width: 393px;
  height: 32px;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  text-align: left;
  line-height: 18px;
  letter-spacing: 2px;
  font-weight: 400;
  font-size: 14px;
  border-color: rgb(34, 34, 34);
  transform-origin: 50% 50% 0px;
  opacity: 1;
  transform: translate(0px);
  visibility: visible;
}

.landing_excel_74 {
  position: absolute;
  display: block;
  pointer-events: auto;
  left: 461.5px;
  top: 439px;
  z-index: 9;
}

.landing_excel_75 {
  z-index: 9;
  background-color: rgb(1, 196, 126);
  font-family: Roboto;
  height: auto;
  width: auto;
  color: rgb(255, 255, 255);
  text-decoration: none;
  white-space: nowrap;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  text-align: left;
  line-height: 50px;
  letter-spacing: 0px;
  font-weight: 500;
  font-size: 18px;
  border-color: transparent;
  padding-left: 40px;
  border-radius: 3px;
  padding-right: 40px;
  filter: blur(0px) grayscale(0%) brightness(100%);
  transform-origin: 50% 50% 0px;
  opacity: 1;
  transform: translate(0px);
  visibility: visible;
}

.landing_excel_76 {
  position: absolute;
  display: block;
  pointer-events: auto;
  left: 850.5px;
  top: 408px;
  z-index: 8;
}

.landing_excel_77 {
  z-index: 8;
  font-family: Verdana, Geneva, sans-serif;
  color: rgb(0, 0, 0);
  text-decoration: none;
  white-space: normal;
  width: 394px;
  height: 38px;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  text-align: left;
  line-height: 15px;
  letter-spacing: 2px;
  font-weight: 400;
  font-size: 13px;
  border-color: rgb(34, 34, 34);
  transform-origin: 50% 50% 0px;
  opacity: 1;
  transform: translate(0px);
  visibility: visible;
}

.landing_excel_78 {
  position: relative;
  left: -31.5px;
  box-sizing: border-box;
  width: 1263px;
  padding-left: 31.5px;
  padding-right: 31.5px;
}

.landing_excel_79 {
  background: transparent none repeat scroll 0% 0%;
  padding: 0px;
  margin: 0px auto;
  height: 600px;
  visibility: visible;
  position: absolute;
  overflow: visible;
  width: 1263px;
  left: -47px;
}

.landing_excel_80 {
  background-color: transparent;
  background-repeat: no-repeat; /* background-image: url("file:///wp-content/uploads/2020/09/Fondo-Banner-Principal-1536x568.jpg"); */
  background-size: cover;
  background-position: 50% 50%;
  width: 100%;
  height: 100%;
  opacity: 1;
  transform: translate(0px);
  visibility: inherit;
  z-index: 20;
}

.landing_excel_81 {
  z-index: 5;
  color: rgb(255, 255, 255);
  width: 690px;
  height: 523px;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  border-color: rgb(34, 34, 34);
  transform-origin: 50% 50% 0px;
  opacity: 1;
  transform: translate(0px);
  visibility: visible;
}

.landing_excel_82 {
  position: absolute;
  display: block;
  pointer-events: auto;
  left: 699.5px;
  top: 325px;
  z-index: 6;
}

.landing_excel_83 {
  z-index: 6;
  font-family: Montserrat;
  height: auto;
  color: rgb(59, 59, 61);
  text-decoration: none;
  white-space: normal;
  width: 429px;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  text-align: left;
  line-height: 37px;
  letter-spacing: 0px;
  font-weight: 400;
  font-size: 18px;
  border-color: rgb(34, 34, 34);
  transform-origin: 50% 50% 0px;
  opacity: 1;
  transform: translate(0px);
  visibility: visible;
}

.landing_excel_84 {
  position: absolute;
  display: block;
  pointer-events: auto;
  left: 696.5px;
  top: 162px;
  z-index: 7;
}

.landing_excel_85 {
  z-index: 7;
  font-family: Montserrat;
  width: auto;
  color: rgb(0, 0, 0);
  text-decoration: none;
  white-space: nowrap;
  height: 128px;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  text-align: left;
  line-height: 38px;
  letter-spacing: 0px;
  font-weight: 700;
  font-size: 72px;
  border-color: rgb(34, 34, 34);
  transform-origin: 50% 50% 0px;
  opacity: 1;
  transform: translate(0px);
  visibility: visible;
}

.landing_excel_86 {
  position: absolute;
  display: block;
  pointer-events: auto;
  left: 702.5px;
  top: 372px;
  z-index: 6;
}

.landing_excel_87 {
}

.landing_excel_69_5 {
  z-index: 8;
  font-family: Roboto;
  height: auto;
  color: rgb(0, 0, 0);
  text-decoration: none;
  white-space: normal;
  width: 399px;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  text-align: left;
  line-height: 18px;
  letter-spacing: 2px;
  font-weight: 400;
  font-size: 14px;
  border-color: rgb(34, 34, 34);
  transform-origin: 50% 50% 0px;
  opacity: 1;
  transform: translate(0px);
  visibility: visible;
}

.landing_excel_80_5 {
  position: absolute;
  display: block;
  pointer-events: auto;
  left: 15.5px;
  top: 75px;
  z-index: 5;
}

.landing_excel_66_5 {
  z-index: 8;
  font-family: Roboto;
  height: auto;
  color: rgb(0, 0, 0);
  text-decoration: none;
  white-space: normal;
  width: 373px;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  text-align: left;
  line-height: 18px;
  letter-spacing: 3px;
  font-weight: 400;
  font-size: 14px;
  border-color: rgb(34, 34, 34);
  transform-origin: 50% 50% 0px;
  opacity: 1;
  transform: translate(0px);
  visibility: visible;
}

.landing_excel_64_5 {
  z-index: 8;
  font-family: Roboto;
  height: auto;
  color: rgb(0, 0, 0);
  text-decoration: none;
  white-space: normal;
  width: 374px;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  text-align: left;
  line-height: 18px;
  letter-spacing: 2px;
  font-weight: 400;
  font-size: 14px;
  border-color: rgb(34, 34, 34);
  transform-origin: 50% 50% 0px;
  opacity: 1;
  transform: translate(0px);
  visibility: visible;
}

/*
.landing_excel_lin_2249 {
  z-index: 6;
  font-family: Montserrat;
  height: auto;
  color: rgb(59, 59, 61);
  text-decoration: none;
  white-space: normal;
  width: 486px;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  text-align: left;
  line-height: 25px;
  letter-spacing: 0px;
  font-weight: 400;
  font-size: 18px;
  border-color: rgb(34, 34, 34);
  transform-origin: 50% 50% 0px;
  opacity: 1;
  transform: translate(0px);
  visibility: visible;
}
*/
